import CegeljarasInput from '../../form/CegeljarasInput';
import FilenameInput from '../../form/FilenameInput';
import ModalWrapper from '../ModalWrapper';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import UserProfileError from '../../UserProfileError';
import authService from '../../../services/authService';
import {Button, Form, notification, Radio} from 'antd';
import {getActiveLanguage, getTranslate} from 'react-localize-redux';
import {useDispatch, useSelector} from 'react-redux';
import {createContainer, updateSelectedContainerList} from '../../../actions/container';
import {removeModal} from '../../../actions/modal';
import {createNotification, createNotificationShort} from '../../../helper';

function CreateContainerModal(props) {
    const { form } = props;

    const dispatch = useDispatch();

    const translate = useSelector((state) => getTranslate(state.locale));

    const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false);
    const [type, setType] = useState('edossier');

    const currentLanguage = useSelector((state) => getActiveLanguage(state.locale).code);

    function isCegeljarasSelected() {
        return type === 'cegeljaras';
    }

    function submit(e) {
        e.preventDefault();
        form.validateFieldsAndScroll((error, values) => {
            if (!error) {
                setIsCreateButtonDisabled(true);
                if (isCegeljarasSelected()) {
                    createNewCegeljaras(values);
                } else {
                    createNewContainer(values);
                }
            }
        });
    }

    function createNewCegeljaras(values) {
        let json = {
            cegNev: values.companyName,
            eCegeljaras: values.requestType
        };

        if (values.requestType === 'BEJEGYZESI_KERELEM') {
            Object.assign(json, {
                cegForma: values.companyForm,
                cegSzekhely: values.companySeat
            });
        } else {
            Object.assign(json, {
                cegJegyzekszam: values.cegjegyzekszam,
                cegSzekhely: values.companySeat
            });
        }

        dispatch(createContainer(json, true))
            .then((response) => {
                dispatch(updateSelectedContainerList([response]));
                dispatch(removeModal());
                createNotificationShort(translate, {
                    message: translate('notifications.createContainer.success'),
                    type: 'success'
                });
            })
            .catch((error) => {
                if (error.response.data.code === 'USER_INVALID_PARAMETERS') {
                    createNotification(translate, error.response.data.code, {
                        description: translate('notifications.filename.tooLong.description'),
                        message: translate('notifications.filename.tooLong.message'),
                        type: 'error'
                    });
                } else {
                    createNotification(translate, error.response.data.code, {
                        message: translate('notifications.createContainer.failure'),
                        type: 'error'
                    });
                }
            })
            .finally(() => {
                setIsCreateButtonDisabled(false);
            });
    }

    function createNewContainer(values) {
        dispatch(
            createContainer(
                {
                    fileBaseName: values.filename,
                    type: type,
                    title: values.filename
                },
                false
            )
        )
            .then((response) => {
                dispatch(updateSelectedContainerList([response]));
                dispatch(removeModal());
                notification.open( {
                    message: translate('notifications.createContainer.success'),
                    type: 'success',
                    duration: 10
                });
                if (values.filename !== response.data.title) {
                    notification.open({
                        message: translate('notifications.createContainer.fileNameChanged'),
                        type: 'info',
                        duration: 10
                    });
                }
            })
            .catch((error) => {
                if (error.response.data.code === 'USER_INVALID_PARAMETERS') {
                    createNotification(translate, error.response.data.code, {
                        description: translate('notifications.filename.tooLong.description'),
                        message: translate('notifications.filename.tooLong.message'),
                        type: 'error'
                    });
                } else {
                    createNotification(translate, error.response.data.code, {
                        message: translate('notifications.createContainer.failure'),
                        type: 'error'
                    });
                }
            })
            .finally(() => {
                setIsCreateButtonDisabled(false);
            });
    }

    return (
        <ModalWrapper
            {...props}
            title={translate('modals.createContainer.title')}>
            <Form onSubmit={submit}>
                <Form.Item>
                    <Radio.Group
                        buttonStyle={'solid'}
                        onChange={(e) => setType(e.target.value)}
                        style={{ width: '100%' }}
                        value={type}>
                        <Radio.Button
                            style={{ textAlign: 'center', width: currentLanguage === 'hu' ? '33.3%' : '50%' }}
                            value={'edossier'}>
                            {translate('modals.createContainer.type.edossier')}
                        </Radio.Button>
                        { currentLanguage === 'hu' && (
                        <Radio.Button
                            style={{ textAlign: 'center', width: '33.3%' }}
                            value={'cegeljaras'}>
                            {translate('modals.createContainer.type.cegeljaras')}
                        </Radio.Button>
                            )}
                        <Radio.Button
                            style={{ textAlign: 'center', width: currentLanguage === 'hu' ? '33.3%' : '50%' }}
                            value={'asic'}>
                            {translate('modals.createContainer.type.asic')}
                        </Radio.Button>
                    </Radio.Group>
                </Form.Item>
                {isCegeljarasSelected() ? (
                    authService.canCreateCegeljaras() ? (
                        <CegeljarasInput form={form} />
                    ) : (
                        <UserProfileError
                            icon={'user'}
                            size={64}
                        />
                    )
                ) : (
                    <FilenameInput form={form} />
                )}
                <Form.Item style={{ marginTop: '4px' }}>
                    <Button
                        block={true}
                        className={'remove-focus-from-button'}
                        disabled={isCreateButtonDisabled}
                        htmlType={'submit'}
                        type={'primary'}>
                        {translate('modals.createContainer.button.text')}
                    </Button>
                </Form.Item>
            </Form>
        </ModalWrapper>
    );
}

CreateContainerModal.propTypes = {
    form: PropTypes.object.isRequired
};

export default Form.create({ name: 'create-container' })(CreateContainerModal);

import React from 'react';
import theme from '../../theme';
import { Button } from 'antd';

export default function ActionLink(props) {
    const { children } = props;

    return (
        <Button
            style={{
                background: 'transparent',
                border: 'none',
                boxShadow: 'none',
                color: props.color ? props.color : theme.WARNING,
                height: 'fit-content',
                margin: 0,
                padding: 0
            }}
            {...props}>
            {children}
        </Button>
    );
}

ActionLink.propTypes = {
};

import {Button, Col, Form, Icon, Row} from "antd";
import React, {useState} from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {getTranslate} from "react-localize-redux";
import SigningWorkflowMetadata from "./SigningWorkflowMetadata";

export default function SigningWorkflowMetadataList(props) {
    const {form, onMetadataChange, originalMetadataList} = props;
    const [metadataList, setMetadataList] = useState(originalMetadataList);
    const translate = useSelector((state) => getTranslate(state.locale));

    const metadataFormItems = metadataList.map((metadata, index) => (
        <SigningWorkflowMetadata
            key={ index }
            index={ index }
            form={ form }
            metadata={ metadata }
            removeMetadata={removeMetadata}
            handleMetadataKeyChanged={handleMetaDataKeyChanged}
            handleMetadataValueChanged={handleMetaDataValueChanged}
            handleMetadataRequiredChanged={handleMetaDataRequiredChanged}
        />
    ));

    function removeMetadata(index) {
        let reducedList = [...metadataList];
        reducedList.splice(index, 1);
        setMetadataList(reducedList);
        onMetadataChange(reducedList);
    }

    function handleMetaDataKeyChanged(index, newKey) {
        let updatedMetaDataList = [...metadataList];
        updatedMetaDataList[index].key = newKey;
        setMetadataList(updatedMetaDataList);
        onMetadataChange(updatedMetaDataList);
    }

    function handleMetaDataValueChanged(index, value) {
        let updatedMetaDataList = [...metadataList];
        updatedMetaDataList[index].value = value;
        setMetadataList(updatedMetaDataList);
        onMetadataChange(updatedMetaDataList);
    }

    function handleMetaDataRequiredChanged(index, newMandatoryValue) {
        let updatedMetaDataList = [...metadataList];
        updatedMetaDataList[index].mandatory = newMandatoryValue;
        setMetadataList(updatedMetaDataList);
        onMetadataChange(updatedMetaDataList);
    }

    function addMetadata() {
        setMetadataList((metadataList) => [...metadataList, { key: '', value: '', mandatory: false }]);
        onMetadataChange(metadataList);
    }

    return (
        <Form.Item>
            <Row type='flex'>
                <Col span={8}>
                    <b>{translate('modals.sendForSignature.metadata.title')}</b>
                </Col>
                <Col span={10} offset={6}>
                    <Button block type='dashed' onClick={addMetadata}>
                        <Icon type='plus' />{translate('modals.sendForSignature.metadata.new')}</Button>
                </Col>
            </Row>
            <Row>
                {metadataFormItems}
            </Row>
        </Form.Item>
    );
}

SigningWorkflowMetadataList.propTypes = {
    form: PropTypes.object.isRequired,
    onMetadataChange: PropTypes.func.isRequired,
    originalMetadataList: PropTypes.array.isRequired
}

import CustomAlert from './CustomAlert';
import FormItem from './form/FormItem';
import InvisibleCaptcha from './InvisibleCaptcha';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Checkbox, Form, Icon, Input } from 'antd';
import { getDocuments, validatePassword } from '../helper';
import { loadReCaptcha } from 'react-recaptcha-google';

class Registration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            serverError: null,
            recaptchaSiteKey: window.config.REACT_APP_RECAPTCHA_SITE_KEY,
            captchaVerified: false,
            confirmDirty: false,
            shouldResetCaptcha: false
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.state.recaptchaSiteKey && loadReCaptcha();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate() {
        if (this.state.shouldResetCaptcha) {
            /* Reset this to avoid multiple captcha reloading */
            this._isMounted && this.setState({ shouldResetCaptcha: false });
        }
    }

    captchaVerified = (verificationCode) => {
        const { form } = this.props;
        form.setFields({ captcha: { errors: [] } });
        this._isMounted && this.setState({ captchaVerified: verificationCode });
    };

    handleConfirmBlur = (e) => {
        const value = e.target.value;
        this._isMounted && this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };

    validateToNextPassword = (rule, value, callback) => {
        const { form, translate } = this.props;
        const { confirmDirty } = this.state;

        validatePassword(translate, value, callback, () => {}, form, confirmDirty);
    };

    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback(this.props.translate('form.password.different'));
        } else {
            callback();
        }
    };

    isChecked = (rule, value, callback) => {
        if (!value) {
            callback(this.props.translate('form.required'));
        } else {
            callback();
        }
    };

    handleSubmit = (event) => {
        const { form } = this.props;

        event.preventDefault();

        form.validateFieldsAndScroll((clientErr, values) => {
            const { currentLanguage, submitFunction } = this.props;
            const { captchaVerified } = this.state;

            if (!clientErr) {
                submitFunction(
                    Object.assign(
                        {
                            apiKey: '',
                            captchaResponse: captchaVerified,
                            language: currentLanguage
                        },
                        values))
                    .catch((errorResponse) => {
                        this._isMounted && this.setState({
                            serverError: errorResponse.isHandled ? undefined : errorResponse,
                            shouldResetCaptcha: true
                        });
                    });
            }
        });
    };

    render() {
        const { translate, currentLanguage, form } = this.props;
        const { serverError, shouldResetCaptcha, recaptchaSiteKey } = this.state;
        const termsAndConditions = getDocuments('terms_and_conditions', currentLanguage);
        const privacyPolicy = getDocuments('privacy_policy', currentLanguage);

        return (
            <Form onSubmit={this.handleSubmit}>
                {recaptchaSiteKey && (
                    <InvisibleCaptcha
                        shouldReset={shouldResetCaptcha}
                        verifyCallback={this.captchaVerified}
                    />
                )}
                <FormItem>{serverError && <CustomAlert error={serverError} />}</FormItem>
                <FormItem>
                    {form.getFieldDecorator('email', {
                        initialValue: this.props.defaultEmailAddress,
                        rules: [
                            { message: translate('form.required'), required: true },
                            { type: 'email', message: translate('login.validateEmail') }
                        ],
                        normalize: (input) => (input !== undefined ? input.toLowerCase() : ''),
                    })(
                        <Input
                            placeholder={translate('registration.emailInput')}
                            prefix={<Icon type="mail" />}
                        />
                    )}
                </FormItem>
                <FormItem>
                    {form.getFieldDecorator('password', {
                        rules: [
                            { message: translate('form.required'), required: true },
                            { validator: this.validateToNextPassword }
                        ]
                    })(
                        <Input
                            prefix={<Icon type="lock" />}
                            placeholder={translate('login.passwordInput')}
                            type={'password'}
                        />
                    )}
                </FormItem>
                <FormItem>
                    {form.getFieldDecorator('rePassword', {
                        rules: [
                            { message: translate('form.required'), required: true },
                            { validator: this.compareToFirstPassword }
                        ]
                    })(
                        <Input
                            prefix={<Icon type="lock" />}
                            placeholder={translate('registration.rePasswordInput')}
                            type={'password'}
                            onBlur={this.handleConfirmBlur}
                        />
                    )}
                </FormItem>
                {termsAndConditions && (
                    <FormItem formItemProps={{ hasFeedback: false }}>
                        {form.getFieldDecorator('termsAndConditions', {
                            valuePropName: 'checked',
                            rules: [{ validator: this.isChecked }]
                        })(
                            <Checkbox style={{ textAlign: 'left' }}>
                                {translate('registration.aszf')}
                                <a
                                    href={termsAndConditions}
                                    target={'_blank'}
                                    rel={'noopener noreferrer'}>
                                    {' '}
                                    {translate('registration.termsAndConditions')}
                                </a>
                            </Checkbox>
                        )}
                    </FormItem>
                )}
                {privacyPolicy && (
                    <FormItem formItemProps={{ hasFeedback: false, labelCol: { span: 1, offset: 2 } }}>
                        {form.getFieldDecorator('privacyPolicy', {
                            valuePropName: 'checked',
                            rules: [{ validator: this.isChecked }]
                        })(
                            <Checkbox style={{ lineHeight: '1.7em' }}>
                                {translate('registration.adatTajekoztato')}{' '}
                                <a
                                    href={privacyPolicy}
                                    target={'_blank'}
                                    rel={'noopener noreferrer'}>
                                    {translate('registration.privacyPolicy')}
                                </a>
                            </Checkbox>
                        )}
                    </FormItem>
                )}
                <FormItem>
                    <Button
                        id="registerButton"
                        type="primary"
                        block
                        htmlType="submit">
                        {translate('registration.regButton')}
                    </Button>
                </FormItem>
            </Form>
        );
    }
}

Registration.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
    defaultEmailAddress: PropTypes.string,
    form: PropTypes.object.isRequired,
    submitFunction: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired
};

export default Form.create()(Registration);

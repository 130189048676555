/* eslint-disable react-hooks/exhaustive-deps */
import ContainerIcon from './ContainerIcon';
import Dropzone from 'react-dropzone';
import Highlighter from 'react-highlight-words';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import theme from '../../theme';
import {TAB_KEY_CONTAINERS_IN_SIGNATURE_PROCESS} from '../../constants/strings';
import {
    TABLE_PAGE_SIZE_CONTAINER_LIST_DEFAULT,
    TIMEOUT_MILLISECONDS
} from '../../constants/config';
import {Button, Icon, Input, Table, Tooltip} from 'antd';
import {
    ajax,
    createNotificationShort,
    formatDate,
    getContainerTypeFromFilename,
    notificationBadgeOffset,
    validateFileSize
} from '../../helper';
import {getTranslate} from 'react-localize-redux';
import {shorten} from '../../utilities/stringUtilities';
import {addSelectedContainers, removeSelectedContainers, setSelectedContainers, uploadContainer} from '../../actions/container';
import {useDispatch, useSelector} from 'react-redux';
import 'react-loading-skeleton/dist/skeleton.css'
import SkeletonTable from '../widget/SkeletonTable';
import {useDeepCompareEffect} from "react-use";
import authService from "../../services/authService";
import {selectedId} from "../pages/HomePage.js"
import {useHistory} from "react-router-dom";

export default function ContainerList(props) {
    const { containerList, containerTotalElements, containerRequestOptions, isTableLoading, setIsTableLoading, setContainerRequestOptions, filter, page } = props;

    const dispatch = useDispatch();
    const history = useHistory();

    const [isMounted, setIsMounted] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);

    const currentTab = useSelector((state) => state.tabs.current);
    const selectedContainers = useSelector((state) => state.containerList.selectedContainers);
    const translate = useSelector((state) => getTranslate(state.locale));

    useEffect(() => {
        setIsMounted(true);
        return () => setIsMounted(false);
    }, []);

    useEffect(() => {
        if (selectedContainers && selectedContainers.length === 0) {
            selectContainer();
        }
    }, [containerList]);

    useEffect(() => {
        selectContainer();
    }, [currentTab]);

    useDeepCompareEffect(() => {
        setPageNumber(page);
    }, [selectedContainers])

    function selectContainer() {
        if (containerList.length > 0) {
            let selectedContainer;
            if (selectedId) {
                let selectedContainerList = containerList.filter(c => c.data.id === selectedId);
                if (selectedContainerList.length === 0) {
                    history.push({
                        pathname: '/info', state:
                            {
                                image: 'img/Document.gif',
                                title: translate('containerNotFound.title'),
                                message: translate('containerNotFound.message')
                            }
                    });
                    return;
                }
                selectedContainer = selectedContainerList[0];
            } else {
                selectedContainer = containerList[0];
            }
            dispatch(setSelectedContainers([selectedContainer]));
            timeout();
        }
    }

    function timeout() {
        isMounted && setIsTableLoading(true);
        isMounted && setTimeout(() => setIsTableLoading(false), TIMEOUT_MILLISECONDS);
    }

    function onRowClick(record) {
        isMounted && !isTableLoading && dispatch(setSelectedContainers([{ data: record.container }]));
        localStorage.removeItem('refuseSignatureReason');
    }

    function onRowSelect(record, selected) {
        if (selected) {
            isMounted &&
                !isTableLoading &&
                dispatch(
                    setSelectedContainers(
                        selectedContainers.concat(containerList.find((container) => container.data.id === record.id))
                    )
                );
        } else {
            isMounted &&
                !isTableLoading &&
                dispatch(
                    setSelectedContainers(selectedContainers.filter((container) => container.data.id !== record.id))
                );
        }
    }

    function onSelectAllRows(selected) {
        if (selected) {
            isMounted && !isTableLoading && dispatch(addSelectedContainers(containerList));
        } else {
            isMounted && !isTableLoading && dispatch(removeSelectedContainers(containerList));
        }
    }

    function onPageChange(page, pageSize) {
        if (pageSize || page > 1) {
            setContainerRequestOptions({offset: containerRequestOptions.limit * (page - 1)});
        }
        setPageNumber(page);
    }

    function onPageSizeChange(current, size) {
        setPageNumber(1);
        setContainerRequestOptions({ limit: size, offset: 0 });
        authService.setContainerPageSize(size);
        saveContainerPageSize(size);
    }

    function saveContainerPageSize(size) {
            ajax()
                .put(`/changeContainerPageSize/${size}`)
                .then(() => {})
                .catch(() => {
                    createNotificationShort(translate,{
                        message: translate('notifications.changeContainerPageSize.failure'),
                        type: 'warning',
                    });
                });
    }

    function onContainerUpload(files) {
        let documentFocus = 1;

        files.forEach((file) => {
            try {
                validateFileSize(file);

                const formData = new FormData();
                formData.append('file', file);

                const containerType = getContainerTypeFromFilename(file.name);
                if (!containerType) {
                    createNotificationShort(translate, {
                        description: translate('notifications.uploadUnknownTypeFailure.description', {
                            fileName: file.name
                        }),
                        message: translate('notifications.uploadUnknownTypeFailure.message'),
                        type: 'error'
                    });
                }

                dispatch(uploadContainer(formData))
                    .then((res) => {
                        if (documentFocus-- === 1) {
                            dispatch(setSelectedContainers([res]));
                        }
                        createNotificationShort(translate, {
                            description: translate('notifications.uploadOK.description', {
                                fileName: file.name
                            }),
                            message: translate('notifications.uploadOK.message'),
                            type: 'success'
                        });
                    })
                    .catch((error) => {
                        createNotificationShort(translate, {
                            description: translate(`errorCodes.${error.response.data.code}`),
                            message: translate('notifications.uploadFailure.message'),
                            type: 'error'
                        });
                    });
            } catch (error) {
                if (error.message === 'USER_MAX_UPLOAD_SIZE_ERROR') {
                    createNotificationShort(translate, {
                        description: translate(`errorCodes.${error.message}`),
                        message: translate('notifications.uploadFailure.message'),
                        type: 'error'
                    });
                }
            }
        });
    }

    function onChangeSortMethod(pagination, filters, sorter) {
        if (!sorter.order) {
            sorter = { columnKey: 'date', order: 'descend' };
        } else {
            sorter = { columnKey: sorter.columnKey, order: sorter.order };
        }

        let filter = filters.name ? filters.name[0] : '';
        filter = filter ? filter : '';
        if (filter !== containerRequestOptions.filter) {
            setContainerRequestOptions({offset: 0});
        }
        setContainerRequestOptions({
            filter: filter,
            order: sorter.order === 'ascend' ? 'ASC' : 'DESC',
            orderBy: sorter.columnKey ? sorter.columnKey : 'created',
         });
    }

    const getColumnSearchProps = () => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => confirm()}
                    style={{ display: 'block', marginBottom: 8, width: 188 }}
                    value={selectedKeys.length > 0 ? selectedKeys[0] : null}
                />
                <Button
                    icon={'search'}
                    onClick={() => confirm()}
                    size={'small'}
                    style={{ marginRight: 8, width: 90 }}
                    type={'primary'}>
                    {translate('buttons.search')}
                </Button>
                <Button
                    onClick={() => clearFilters()}
                    size={'small'}
                    style={{ width: 90 }}
                    type={'default'}>
                    {translate('buttons.reset')}
                </Button>
            </div>
        ),
        defaultFilteredValue: filter === "" ? undefined : [filter],
        filterIcon: (filtered) => (
            <Icon
                style={{ color: filtered ? theme.BRAND : undefined }}
                title={translate('buttons.searchIcon')}
                type={'search'}
            />
        ),
        onFilter: () => true
    });

    let columns = [
        {
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            title: translate('home.title'),
            ...getColumnSearchProps('name'),
            render: (text, record) => {
                return (
                  <div
                    style={{
                      display: 'table',
                      wordBreak: 'break-all',
                      overflowWrap: 'break-word',
                    }}
                  >
                    <div>
                      <ContainerIcon
                        currentTab={currentTab}
                        container={record}
                        offset={notificationBadgeOffset(record.type)}
                        translate={translate}
                      />
                    </div>
                    <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                      <Tooltip title={record.fullName}>
                          <span>
                              <Highlighter
                                  highlightStyle={{ backgroundColor: theme.BRAND_PRESSED, padding: 0 }}
                                  searchWords={[filter]}
                                  autoEscape
                                  textToHighlight={text.toString()}
                              />
                          </span>
                      </Tooltip>
                    </div>
                  </div>
                );
              },
        },
        {
            align: 'center',
            dataIndex: 'date',
            defaultSortOrder: 'descend',
            key: 'date',
            render: (text) => {
                return formatDate(text, translate('language'));
            },
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            title: translate('attributes.creationDate'),
            width: currentTab === TAB_KEY_CONTAINERS_IN_SIGNATURE_PROCESS ? '15%' : '25%'
        },
        {
            title: translate('workflow.details.table.status'),
            dataIndex: 'waitingToSign',
            key: 'waitingToSign',
            render: (waitingToSign, record) => {
                return translate('workflow.details.status.' + record.signedFromBulkSigning);
            },
            sortDirections: ['descend', 'ascend'],
            width: '15%',
            align: 'center'
        }
    ];

    if (currentTab === TAB_KEY_CONTAINERS_IN_SIGNATURE_PROCESS) {
        columns.splice(1, 0, {
            align: 'center',
            dataIndex: 'createdBy',
            key: 'createdBy',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            title: translate('attributes.createdBy'),
            width: '30%'
        });
    }

    function hasFilteredData() {
        return containerList.length > 0 || (filter && filter !== "");
    }

    return (
        <Dropzone
            noClick={true}
            onDrop={onContainerUpload}>
            {({ isDragActive, getRootProps }) => {
                return (
                    <div
                        {...getRootProps()}
                        style={isDragActive ? theme.dropZoneStyle : {}}>
                        {props.useSkeleton ?
                            (<SkeletonTable rowCount={10}/>)
                        :
                            (<Table
                            columns={columns}
                            dataSource={containerList.map((container) => ({
                                action: container.data.action,
                                container: container.data,
                                createdBy: container.data.processStarter,
                                date: container.data.creationDate,
                                error: container.data.error,
                                id: container.data.id,
                                key: container.data.id,
                                name: shorten(container.data.fileBaseName, 80),
                                fullName: container.data.fileBaseName,
                                signedFromBulkSigning: container.data.signedFromBulkSigning,
                                type: container.data.type,
                                wasOpened: container.data.wasOpened,
                                workflowGuid: container.data.workflowGuid
                            }))}
                            loading={isTableLoading}
                            locale={{
                                emptyText: translate(hasFilteredData() ? `noData` : `container.list.${currentTab}.emptyList`)
                            }}
                            onChange={onChangeSortMethod}
                            onRow={(record) => ({
                                onClick: () => onRowClick(record)
                            })}
                            pagination={{
                                current: pageNumber,
                                defaultPageSize: authService.getContainerPageSize() || TABLE_PAGE_SIZE_CONTAINER_LIST_DEFAULT,
                                disabled: containerList.length === 0,
                                locale: { items_per_page: '' },
                                onChange: (page, pageSize) => onPageChange(page, pageSize),
                                onShowSizeChange: (current, size) => onPageSizeChange(current, size),
                                pageSizeOptions: ['10', '25', '50', '100'],
                                showSizeChanger: true,
                                total: containerTotalElements
                            }}
                            rowClassName={(record) => {
                                return record.id ===
                                    (selectedContainers.length > 0 ? selectedContainers[0].data.id : '')
                                    ? 'table-select'
                                    : selectedContainers.length > 0 && record.error !== undefined
                                        ? 'disabled-row'
                                        : record.wasOpened
                                            ? ''
                                            : 'table-unseen-row';
                            }}
                            rowSelection={{
                                onSelect: onRowSelect,
                                onSelectAll: onSelectAllRows,
                                selectedRowKeys:
                                    selectedContainers.length > 0
                                        ? selectedContainers.map((containers) => containers.data.id)
                                        : []
                            }}
                            showHeader={hasFilteredData()}
                            size={'small'}
                        />)}
                    </div>
                );
            }}
        </Dropzone>
    );
}

ContainerList.propTypes = {
    containerList: PropTypes.array.isRequired,
    containerTotalElements: PropTypes.number.isRequired,
    containerRequestOptions: PropTypes.object.isRequired,
    isTableLoading: PropTypes.bool.isRequired,
    setContainerRequestOptions: PropTypes.func.isRequired,
    setIsTableLoading: PropTypes.func.isRequired
};

import FilenameInput from '../../form/FilenameInput';
import ModalWrapper from '../ModalWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Form, notification} from 'antd';
import {createNotification, createNotificationShort, removeFilenameCounterPostfix} from '../../../helper';
import { getTranslate } from 'react-localize-redux';
import { removeModal } from '../../../actions/modal';
import { renameDocument } from '../../../actions/document';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { makeGetActiveTabContainerById } from '../../../selectors';
function RenameDocumentModal(props) {
    const { form, modalData } = props;
    const dispatch = useDispatch();
    const [isRenameButtonDisabled, setIsRenameButtonDisabled] = useState(false);
    const getActiveTabContainer = makeGetActiveTabContainerById();
    const currentContainer = useSelector((state) => getActiveTabContainer(state, props));
    const translate = useSelector((state) => getTranslate(state.locale));
    function wasFilenameModified(originalFilename, response) {
        const documentsInFolder = response.details.documents;
        if (documentsInFolder.length > 0) {
            for (let i = 0; i < documentsInFolder.length; ++i) {
                const document = documentsInFolder[i];
                const currentId = document.id;

                if (currentId === modalData.documentId) {
                    if (removeFilenameCounterPostfix(originalFilename) !== removeFilenameCounterPostfix(document.title)) {
                        return true;
                    }
                }
            }
        } else {
            if (removeFilenameCounterPostfix(originalFilename) !== removeFilenameCounterPostfix(response.title)) {
                return true;
            }
        }
    }
    function submit(e) {
        e.preventDefault();
        form.validateFieldsAndScroll((error, values) => {
            if (!error) {
                setIsRenameButtonDisabled(true);
                dispatch(
                    renameDocument(
                        currentContainer.data.type,
                        currentContainer.data.id,
                        modalData.documentId,
                        values.filename
                    )
                )
                    .then((res) => {
                        createNotificationShort(translate, {
                            message: translate('notifications.renameDocument.success'),
                            type: 'success'
                        });
                        dispatch(removeModal());

                        const response = res.data;

                        if (wasFilenameModified(values.filename, response)) {
                            notification.open({
                                message: translate('notifications.uploadOK.fileNameChanged'),
                                type: 'info',
                                duration: 10
                            });
                        }
                    })
                    .catch((error) => {
                        if (error.response.data.code === 'USER_INVALID_PARAMETERS') {
                            createNotification(translate, error.response.data.code, {
                                description: translate('notifications.filename.tooLong.description'),
                                message: translate('notifications.filename.tooLong.message'),
                                type: 'error'
                            });
                        } else {
                            createNotification(translate, error.response.data.code, {
                                message: translate('notifications.renameDocument.failure'),
                                type: 'error'
                            });
                        }
                    })
                    .finally(() => {
                        setIsRenameButtonDisabled(false);
                    });
            }
        });
    }
    return (
        <ModalWrapper
            {...props}
            title={translate('modals.renameContainer.title')}>
            <Form onSubmit={submit}>
                <FilenameInput
                    defaultFilename={modalData.documentTitle}
                    form={form}
                />
                <Form.Item style={{ marginTop: '4px' }}>
                    <Button
                        block={true}
                        className={'remove-focus-from-button'}
                        disabled={isRenameButtonDisabled}
                        htmlType={'submit'}
                        type={'primary'}>
                        {translate('modals.renameContainer.button.text')}
                    </Button>
                </Form.Item>
            </Form>
        </ModalWrapper>
    );
}
RenameDocumentModal.propTypes = {
    form: PropTypes.object.isRequired,
    modalData: PropTypes.object
};
export default Form.create({ name: 'rename-document' })(RenameDocumentModal);


import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.tsa, action) {
    switch (action.type) {
        case types.CHECK_TSP_SUCCESS:
            return { ...state, requireTspCredentials: false };
        case types.CHECK_TSP_FAILURE:
            return { ...state, requireTspCredentials: undefined };
        case types.TSP_CLEARED:
            return { ...state, requireTspCredentials: true };
        default:
            return state;
    }
}

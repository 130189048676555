import ModalWrapper from '../ModalWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'antd';
import { createNotification, createNotificationShort } from '../../../helper';
import { getTranslate } from 'react-localize-redux';
import {
    deleteContainers
} from '../../../actions/container';
import { removeModal } from '../../../actions/modal';
import { useDispatch, useSelector } from 'react-redux';

function DeleteContainerModal(props) {
    const { onFinished, selectedContainerIDs } = props.modalData;

    const translate = useSelector((state) => getTranslate(state.locale));

    const singleDelete = selectedContainerIDs.length === 1;

    const title = singleDelete
        ? <b>{translate('modals.deleteContainer.title')}</b>
        : <b>{translate('modals.deleteContainer.titleMultiple')}</b>;
    const genericFailureMessage = singleDelete
        ? translate('notifications.deleteContainer.genericFailure')
        : translate('notifications.deleteContainer.genericFailureMultiple');

    const dispatch = useDispatch();
    return (
        <ModalWrapper
            {...props}
            title={<b>{title}</b>}>
            <p>{translate('modals.deleteContainer.description')}</p>
            <Button
                block={true}
                id={'refuse-signature-button'}
                onClick={() => {
                    dispatch(deleteContainers(selectedContainerIDs))
                        .then((result) => {
                            onFinished && onFinished();
                            if (result.numberOfDeletedContainers > 1) {
                                createNotificationShort(translate, {
                                    message: translate('notifications.deleteContainer.successMultiple', {number: result.numberOfDeletedContainers}),
                                    type: 'success'
                                });
                            } else if (result.numberOfDeletedContainers === 1) {
                                createNotificationShort(translate, {
                                    message: translate('notifications.deleteContainer.success', {number: result.numberOfDeletedContainers}),
                                    type: 'success'
                                });
                            }
                            if (result.failedToDeleteContainers && result.failedToDeleteContainers.length > 1) {
                                createNotification(translate, null, {
                                    message: translate('notifications.deleteContainer.failureMultiple', {number: result.failedToDeleteContainers.length}),
                                    type: 'error'
                                });
                            } else if (result.failedToDeleteContainers && result.failedToDeleteContainers.length === 1) {
                                createNotification(translate, null, {
                                    message: translate('notifications.deleteContainer.genericFailure', {number: result.failedToDeleteContainers.length}),
                                    type: 'error'
                                });
                            }
                            dispatch(removeModal());
                        })
                        .catch((e) => {
                            createNotification(translate, e.response.data.code, {
                                message: genericFailureMessage,
                                type: 'error'
                            });
                        });
                }}
                type={'primary'}>
                {translate('modals.deleteContainer.button')}
            </Button>
        </ModalWrapper>
    );
}

DeleteContainerModal.propTypes = {
    modalData: PropTypes.shape({
        onFinished: PropTypes.func,
        selectedContainerIDs: PropTypes.array.isRequired
    })
};

export default DeleteContainerModal;

import PropTypes from "prop-types";
import {Button, Col, Form, Icon, Row} from "antd";
import theme from "../../../theme";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getTranslate} from "react-localize-redux";
import SigningWorkflowParticipant from "./SigningWorkflowParticipant";
import {WORKFLOW_ROLE_SIGNER} from "../../../constants/strings";

export default function SigningWorkflowParticipantList(props) {
    const {form, onParticipantChange, originalParticipantList} = props;
    const [participantList, setParticipantList] = useState(originalParticipantList);
    const [showDeleteButton, setShowDeleteButton] = useState(false);
    const [frequentlyUsedEmailAddresses, setFrequentlyUsedEmailAddresses] = useState([]);
    const translate = useSelector((state) => getTranslate(state.locale));

    useEffect(() => {
        setShowDeleteButton(participantList.length > 1);
    }, [participantList]);

    const participantFormItems = participantList.map((participant, index) => (
        <SigningWorkflowParticipant
            key={index}
            index={index}
            form={form}
            participant={participant}
            removeParticipant={removeParticipant}
            showDeleteButton={showDeleteButton}
            frequentlyUsedEmailAddresses={frequentlyUsedEmailAddresses}
            handleParticipantChanged={handleParticipantChanged}
            onFrequentlyUsedEmailAddressUpdate={(newFrequentlyUsedEmailAddresses) => {
                setFrequentlyUsedEmailAddresses(newFrequentlyUsedEmailAddresses);
            }}
        />
    ));

    function addParticipant() {
        setParticipantList((participantList) => [...participantList, {email: '', role: WORKFLOW_ROLE_SIGNER}]);
    }

    function removeParticipant(index) {
        if (participantList.length === 1) {
            return;
        }
        let reducedList = [...participantList];
        reducedList.splice(index, 1);
        setParticipantList(reducedList);
        onParticipantChange(reducedList);
    }

    function handleParticipantChanged(index, email, role) {
        let updatedParticipantList = [...participantList];
        participantList[index].email = email;
        participantList[index].role = role;
        setParticipantList(updatedParticipantList);
        onParticipantChange(updatedParticipantList);
    }

    return (
        <Form.Item>
            <Row type='flex'>
                <Col span={8}>
                    <b>{translate('modals.sendForSignature.email.title')}</b>
                </Col>
                <Col span={8} offset={8}>
                    <Button block type='dashed' onClick={addParticipant}>
                        <Icon type='plus'/>
                        {translate('modals.sendForSignature.email.new')}</Button>
                </Col>
            </Row>
            <Row>
                <span>
                    <b style={{color: theme.BRAND}}>{translate('modals.sendForSignature.roles.signer')}</b>
                    {window.config.REACT_APP_WORKFLOW_ROLES_ENABLED && <span>
                        <b> / </b>
                        <b style={{color: theme.DEFAULT}}>{translate('modals.sendForSignature.roles.approver')}</b>
                    </span>}
                </span>
            </Row>
            <Row>
                {participantFormItems}
            </Row>
        </Form.Item>
    );
}

SigningWorkflowParticipantList.propTypes = {
    form: PropTypes.object.isRequired,
    onParticipantChange: PropTypes.func.isRequired,
    originalParticipantList: PropTypes.array.isRequired
}

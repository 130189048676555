export const CEGJEGYZEKSZAM_REGULAR_EXPRESSION = /^[0-2][0-9]-[0-2][0-9]-[0-9]{6}$/m;

export const EMAIL_SEARCH_LIST_SIZE = 10;
export const EMAIL_SEARCH_MIN_LENGTH = 3;

export const FILENAME_MAX_LENGTH = 255;
export const FILENAME_MIN_LENGTH = 1;

export const FONT_SIZE_LINK = 20;

export const PLACEHOLDER_CEGJEGYZEKSZAM = '11-11-111111';
export const PLACEHOLDER_EMAIL = 'pelda.peter@pelda.hu';

export const SIGNATURE_PROFILE_NAME_MAX_LENGTH = 100;

export const TABLE_PAGE_SIZE_CONTAINER_LIST_DEFAULT = 10;
export const TABLE_PAGE_SIZE_WORKFLOW_DETAILS = 10;
export const TABLE_PAGE_SIZE_WORKFLOW_LIST = 10;

export const TIMEOUT_MILLISECONDS = 750;

export const WORKFLOW_MESSAGE_MAX_LENGTH = 1000;
export const WORKFLOW_METADATA_KEY_MAX_LENGTH = 64;
export const WORKFLOW_METADATA_VALUE_MAX_LENGTH = 256;

export const WORKFLOW_TEMPLATE_MAX_LENGTH = 255;

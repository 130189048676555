import ModalWrapper from '../modals/ModalWrapper';
import PdfPreviewDeprecated from './PdfPreviewDeprecated';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux/lib/index';

class PdfPreviewModal extends React.Component {
    render() {
        const { modalData, translate } = this.props;
        return (
            <ModalWrapper
                {...this.props}
                title={translate('buttons.docPreview')}
                width={645}>
                <PdfPreviewDeprecated
                    url={modalData.documentUrl}
                    translate={translate}
                />
            </ModalWrapper>
        );
    }
}

PdfPreviewModal.propTypes = {
    modalData: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    translate: getTranslate(state.locale)
});

export default connect(mapStateToProps)(PdfPreviewModal);

export const ADMIN_EDIT_DEPUTY = 'ADMIN_EDIT_DEPUTY';
export const ATTRIBUTE_CERTIFICATES = 'ATTRIBUTE_CERTIFICATES';
export const ATTRIBUTE_ROLES = 'ATTRIBUTE_ROLES';
export const AUTHENTICATION = 'AUTHENTICATION';
export const CLOSURE = 'CLOSURE';
export const COMPANY_REGISTRATION = 'COMPANY_REGISTRATION';
export const CREATE_FREQUENTLY_USED_EMAIL_ADDRESS = 'CREATE_FREQUENTLY_USED_EMAIL_ADDRESS';
export const CREATE_NEW_USER = 'CREATE_NEW_USER';
export const CREATE_SIGNATURE_SCHEMA = 'CREATE_SIGNATURE_SCHEMA';
export const FORGOTTEN_PASSWORD = 'FORGOTTEN_PASSWORD';
export const META_DATA = 'META_DATA';
export const MOBILE_AUTHENTICATION = 'MOBILE_AUTHENTICATION';
export const MOBILE_LOGIN = 'MOBILE_LOGIN';
export const MOBILE_QR_CODE_IDENTIFICATION = 'MOBILE_QR_CODE_IDENTIFICATION';
export const NEW_CONTAINER = 'NEW_CONTAINER';
export const NOTIFY_USER = 'NOTIFY_USER';
export const NOTIFY_USER_REGISTERED = 'NOTIFY_USER_REGISTERED';
export const PDF_EMBEDDED_FILES = 'PDF_EMBEDDED_FILES';
export const PDF_PREVIEW_DEPRECATED = 'PDF_PREVIEW_DEPRECATED';
export const PDF_WITH_FORM_NOTIFICATION = 'PDF_WITH_FORM_NOTIFICATION';
export const REFUSE_SIGNATURE = 'REFUSE_SIGNATURE';
export const REMOVE_CONTAINER = 'REMOVE_CONTAINER';
export const REMOVE_DOCUMENT = 'REMOVE_DOCUMENT';
export const REMOVE_SIGNATURE = 'REMOVE_SIGNATURE';
export const RENAME_CONTAINER = 'RENAME_CONTAINER';
export const RENAME_DOCUMENT = 'RENAME_DOCUMENT';
export const SECOND_FACTOR = 'SECOND_FACTOR';
export const SELECT_CERTIFICATE = 'SELECT_CERTIFICATE';
export const SEND_FOR_SIGNATURE = 'SEND_FOR_SIGNATURE';
export const SHARE_CONTAINER = 'SHARE_CONTAINER';
export const SIGN = 'SIGN';
export const SIGNATURE_POSITION = 'SIGNATURE_POSITION';
export const TSA = 'TSA';
export const UPDATE_FREQUENTLY_USED_EMAIL_ADDRESS = 'UPDATE_FREQUENTLY_USED_EMAIL_ADDRESS';
export const UPDATE_SIGNATURE_SCHEMA = 'UPDATE_SIGNATURE_SCHEMA';
export const UPLOAD_CEGELJARAS = 'UPLOAD_DOCUMENTS_CEGELJARAS';
export const USER_PROFILE_ERROR = 'USER_PROFILE_ERROR';
export const WORKFLOW_TEMPLATE = "WORKFLOW_TEMPLATE"

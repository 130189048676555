import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import ReactMarkdown from "react-markdown";

export default function TextFileReader(props) {
    const {file} = props;

    const [text, setText] = useState('');
    const [isMarkdown, setIsMarkdown] = useState(false);

    useEffect(() => {
        readTextFile(file);
    }, [file]);

    function readTextFile(file) {
        if (file.toLowerCase().endsWith('.md')) {
            setIsMarkdown(true);
        } else {
            setIsMarkdown(false);
        }
        var rawFile = new XMLHttpRequest();
        rawFile.open('GET', file, false);
        rawFile.onreadystatechange = () => {
            if (rawFile.readyState === 4) {
                if (rawFile.status === 200 || rawFile.status === 0) {
                    setText(rawFile.responseText);
                }
            }
        };
        rawFile.send(null);
    }

    return (
        <pre style={{fontFamily: 'inherit', margin: '16px 0px 0px 0px', whiteSpace: 'pre-wrap', lineHeight: 1.1}}>
            {isMarkdown
                ? <ReactMarkdown children={text}/>
                : text
            }
            <br/>
        </pre>
    );
}

TextFileReader.propTypes = {
    file: PropTypes.string.isRequired
};

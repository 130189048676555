import * as types from './actionTypes';
import {ajax} from '../helper';
import {getContainerDocuments} from './document';

export function resetContainerList() {
    return {
        type: types.RESET_CONTAINER_LIST,
    };
}

const ACTION_NONE = 'none';
export const ACTION_SIGN = 'sign';
export const ACTION_APPROVE = 'approve';

const FILE_ATTACHMENT_DESCRIPTOR_FIELD_NAME = 'descriptor';
const FILE_ATTACHMENT_FIELD_NAME = 'attachment';
const APPLICATION_JSON = 'application/json';

export function isContainerInSigningWorkflow(container) {
    return container.action === ACTION_SIGN || container.action === ACTION_APPROVE;
}
function isSignerInBulkSigning(signedFromBulkSigning) {
    return signedFromBulkSigning === 'NOT_SIGNED';
}

function isApproverInBulkSigning(signedFromBulkSigning) {
    return signedFromBulkSigning === 'NOT_APPROVED';
}

function signOrApproveAction(signedFromBulkSigning, action) {
    if (action === ACTION_NONE) {
        return ACTION_NONE;
    } else if (isSignerInBulkSigning(signedFromBulkSigning)) {
        return ACTION_SIGN;
    } else if (isApproverInBulkSigning(signedFromBulkSigning)) {
        return ACTION_APPROVE;
    } else {
        return undefined;
    }
}

function parseContainerResponse(responseData, action) {
    let parsedResponse = {};
    let containers = [];
    responseData.containers.forEach((c) => {
        containers.push({
            data: {
                creationDate: c.creationDate,
                fileBaseName: c.title,
                id: c.id,
                signedFromBulkSigning: c.signedFromBulkSigning,
                waitingToSignByOthers: c.waitingToSignByOthers,
                waitingToSign: c.waitingToSign,
                type: c.type,
                esNamespace: c.esNamespace,
                wasOpened: c.wasOpened,
                size: c.size ? c.size.toString() : -1,
                mimeType: c.mimeType,
                pages: c.pages,
                pdfEmbeddedFiles: c.pdfEmbeddedFiles,
                error: c.error,
                pdfFormFields: c.pdfFormFields,
                processStarter: c.processStarter,
                workflowGuid: c.workflowGuid,
                isCounterSignable: c.isCounterSignable,
                isUploadEnabled: c.isUploadEnabled,
                action: signOrApproveAction(c.signedFromBulkSigning, action)
            }
        });
    });
    parsedResponse.containers = containers;
    parsedResponse.count = responseData.count;
    parsedResponse.totalReceivedCount = responseData.totalReceivedCount;
    parsedResponse.limit = responseData.limit;
    parsedResponse.offset = responseData.offset;
    return parsedResponse;
}

export function parseOneContainerResponse(responseData, action) {
    let container;
    container = {
        data: {
            creationDate: responseData.creationDate,
            fileBaseName: responseData.title,
            id: responseData.id,
            signedFromBulkSigning: responseData.signedFromBulkSigning,
            waitingToSignByOthers: responseData.waitingToSignByOthers,
            waitingToSign: responseData.waitingToSign,
            type: responseData.type,
            esNamespace: responseData.esNamespace,
            wasOpened: responseData.wasOpened,
            size: responseData.size ? responseData.size.toString() : '',
            mimeType: responseData.mimeType,
            pages: responseData.pages,
            pdfEmbeddedFiles: responseData.pdfEmbeddedFiles,
            error: responseData.error,
            pdfFormFields: responseData.pdfFormFields,
            processStarter: responseData.processStarter,
            workflowGuid: responseData.workflowGuid,
            isCounterSignable: responseData.isCounterSignable,
            isUploadEnabled: responseData.isUploadEnabled,
            action: signOrApproveAction(responseData.signedFromBulkSigning, action)
        }
    };
    return container;
}

function parseContainerFilterParams(requestOptions, statuses) {
    if (!statuses) {
        statuses = ['CREATED', 'SENT', 'SIGNED', 'REFUSED', 'FINISHED', 'CANCELED'];
    }

    let title = requestOptions?.filter;
    let ordering = requestOptions?.order ? requestOptions.order : 'DESC';
    let sortType = 'DATE'

    if (requestOptions && requestOptions.orderBy) {
        switch (requestOptions.orderBy) {
            case 'name':
                sortType = 'FILENAME'
                break;
            case 'waitingToSign':
                sortType = 'STATUS'
                break;
            case 'createdBy':
                sortType = 'PROCESS_STARTER'
                break;
            case 'date':
            default:
                sortType = 'DATE'
                break;
        }
    }

    let limit = requestOptions?.limit ? requestOptions.limit : 10;
    let offset = requestOptions?.offset ? requestOptions.offset : 0;
    let selectedId = requestOptions?.selectedId;

    return {
        filter: {
            statuses: statuses,
            title: {
                value: title
            }
        },
        sorts: [
            {
                sortType: sortType,
                ordering: ordering
            }
        ],
        offset: offset,
        limit: limit,
        selectedId: selectedId
    }
}

export function getContainersRequest(requestOptions, statuses) {
    return ajax()
        .post(`/containers/search`, parseContainerFilterParams(requestOptions, statuses))
}

export function getContainers(requestOptions, statuses) {
    return function (dispatch) {
        return getContainersRequest(requestOptions, statuses)
            .then((response) => {
                const parsedResponse = parseContainerResponse(response.data, 'none');
                parsedResponse.filter = requestOptions && requestOptions.filter ? requestOptions.filter : "";
                if ((parsedResponse.count === 0) || (parsedResponse.count > parsedResponse.offset)) {
                    dispatch(getContainersSuccess(parsedResponse));
                } else {
                    requestOptions.offset = Math.floor((parsedResponse.count - 1) / requestOptions.limit) * requestOptions.limit;
                    dispatch(getContainers(requestOptions));
                }
                return parsedResponse;
            })
            .catch((error) => {
                dispatch(getContainersFailure());
                throw error;
            });
    };
}

export function getContainersInSignatureProcess(requestOptions) {
    return function (dispatch) {
        return getContainersRequest(requestOptions, ['RECEIVED'])
            .then((response) => {
                const data = response.data;
                const parsedResponse = parseContainerResponse(data);
                parsedResponse.filter = requestOptions && requestOptions.filter ? requestOptions.filter : "";
                if ((parsedResponse.count === 0) || (parsedResponse.count > parsedResponse.offset)) {
                    dispatch(getContainersInSignatureProcessSuccess(parsedResponse));
                } else {
                    requestOptions.offset = Math.floor((parsedResponse.count - 1) / requestOptions.limit) * requestOptions.limit;
                    dispatch(getContainersInSignatureProcess(requestOptions));
                }
                return parsedResponse;
            })
            .catch((error) => {
                dispatch(getContainersInSignatureProcessFailure());
                throw error;
            });
    };
}

export function getContainersSuccess(parsedResponse) {
    return {
        type: types.GET_CONTAINERS_SUCCESS,
        payload: parsedResponse,
    };
}

export function getContainersFailure() {
    return {
        type: types.GET_CONTAINERS_FAILURE,
    };
}

export const storeFileId = (id) => {
    return {
        type: types.STORE_FILE_ID,
        payload: id,
    };
};

export const deleteStoredFileId = () => {
    return {
        type: types.DELETE_STORED_FILE_ID,
    };
};

export function getContainerSignaturesSuccess(signatures) {
    return {
        type: types.GET_CONTAINER_SIGNATURES_SUCCESS,
        payload: signatures,
    };
}

export function updateContainer(containerData) {
    return  {
        type: types.UPDATE_CONTAINER,
        payload: containerData,
    }
}

export function getContainerSignaturesFailure() {
    return {
        type: types.GET_CONTAINER_SIGNATURES_FAILURE,
    };
}

export function getContainerSignatures(uriType, id) {
    return function (dispatch) {
        return ajax()
            .get(`/containers/${id}`)
            .then((response) => {
                let signaturesData =
                    response.data.details != null
                        ? response.data.details.signatures != null
                            ? response.data.details.signatures
                            : []
                        : [];
                if (response.data.type && response.data.type === 'asic') {
                    dispatch(getContainerDocuments(response.data.details.documents, id));
                }
                dispatch(getContainerSignaturesSuccess(signaturesData));
                return response;
            })
            .catch((error) => {
                dispatch(getContainerSignaturesFailure());
                throw error;
            });
    };
}

export function deleteContainerSignature(signatureId) {
    return {
        type: types.DELETE_SIGNATURE_SUCCESS,
        payload: signatureId,
    };
}

export function deleteContainers(ids) {
    return function (dispatch) {
        return ajax()
            .post(`/containers/delete`, {
                    containerIds: ids
                })
            .then((response) => {
                const deletedContainerIds = response.data.filter((record) => (record.code === 'OK')).map((record) => record.id);
                const failedToDeleteContainers = response.data.filter((record) => (record.code !== 'OK')).map((record) => {
                    return {
                        containerId: record.id,
                        errorCode: record.code
                    }
                });

                if (deletedContainerIds && deletedContainerIds.length !== 0) {
                    dispatch(deleteMultipleContainersSuccess(deletedContainerIds));
                }
                if (failedToDeleteContainers && failedToDeleteContainers.length !== 0) {
                    console.warn("Failed to delete containerIds: " + failedToDeleteContainers.map((record) => record.containerId));
                }
                return {
                    numberOfDeletedContainers: deletedContainerIds ? deletedContainerIds.length : 0,
                    failedToDeleteContainers: failedToDeleteContainers
                };
            });
    }
}

export function deleteMultipleContainersSuccess(containerIds) {
    return {
        type: types.DELETE_MULTIPLE_CONTAINERS_SUCCESS,
        payload: containerIds,
    };
}

export const newContainerSuccess = (data) => {
    return {
        type: types.NEW_CONTAINER_SUCCESS,
        payload: data,
    };
};

export const uploadContainerSuccess = (data) => {
    return {
        type: types.UPLOAD_CONTAINER_SUCCESS,
        payload: data,
    };
};

export const containerRenameSuccess = (index, newTitle) => {
    return {
        type: types.RENAME_CONTAINER_SUCCESS,
        index: index,
        title: newTitle,
    };
};

export const validateSignatureFailure = (selectedSignature) => {
    return {
        type: types.VALIDATE_SIGNATURE_FAILURE,
        payload: {
            sig: selectedSignature,
        },
    };
};

export const validateSignatureSuccess = (selectedSignature) => {
    return {
        type: types.VALIDATE_SIGNATURE_SUCCESS,
        payload: selectedSignature,
    };
};

export const setArchiveSignatureSuccess = (newSignature) => {
    return {
        type: types.ARCHIVE_SIGNATURE_SUCCESS,
        payload: newSignature,
    };
};

export const sendInEmail = (id, email, lang) => {
    return function () {
        return ajax()
            .post(`/containers/${id}/send`, { targetEmails: [`${email}`], language: lang });
    };
};

export const SendForSignature = (ids, targets, ack, comment, metadata, fileAttachments) => {
    return function () {
        const form = new FormData();
        const descriptorJson = JSON.stringify({
            targets: targets,
            acknowledgementIsRequired: ack,
            containerIds: ids,
            comment: comment,
            metadata: metadata,
        });
        const descriptorFormData = new Blob([descriptorJson], {
            type: APPLICATION_JSON
        });
        form.append(FILE_ATTACHMENT_DESCRIPTOR_FIELD_NAME, descriptorFormData);
        fileAttachments.forEach((attachment) => {
            form.append(FILE_ATTACHMENT_FIELD_NAME, attachment.originFileObj);
        });
        return ajax()
            .post(`/containers/bulkSigningProcesses`, form);
    };
};

export function searchEmails(emailPiece) {
    return function () {
        return ajax()
            .get(`/emailAddress/search?emailPiece=${emailPiece}`);
    };
}

export const renameContainer = (id, title) => {
    return function (dispatch) {
        return ajax()
            .post(`/containers/${id}/rename`, { title: title })
            .then((response) => {
                dispatch(containerRenameSuccess(id, title));
                return response;
            });
    };
};

export function validateSignature(containerId, signature, refContainer) {
    return function (dispatch) {
        if (signature === null) {
            return ajax()
                .post(`/containers/${containerId}/signatures/nop/validate`, { referenceContainerId: refContainer })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.error(error);
                    throw error;
                });
        } else if (refContainer === null) {
            return ajax()
                .post(`/containers/${containerId}/signatures/${signature.id}/validate`, { referenceContainerId: '' })
                .then((response) => {
                    dispatch(validateSignatureSuccess(signature));
                    return response;
                })
                .catch((error) => {
                    dispatch(validateSignatureFailure(signature));
                    throw error;
                });
        }
    };
}

export function createArchiveSignature(containerId, signatureId) {
    return function (dispatch) {
        return ajax()
            .post(`/containers/${containerId}/signatures/${signatureId}/addArchiveTimestamp`, {
                referenceContainerId: '',
            })
            .then(function (response) {
                let signaturesData =
                    response.data.details != null
                        ? response.data.details.signatures != null
                            ? response.data.details.signatures
                            : []
                        : [];
                dispatch(getContainerSignaturesSuccess(signaturesData));
                return response;
            });
    };
}

export function createContainer(json, isCegeljaras) {
    return function (dispatch) {
        let url = '/containers';
        if (isCegeljaras) {
            url = '/containers/ecegeljaras';
        }
        return ajax()
            .post(url, json)
            .then((response) => {
                const data = response.data;
                const parsedResponse = parseOneContainerResponse(data, 'none');
                dispatch(newContainerSuccess(parsedResponse));
                return response;
            });
    };
}

export function uploadContainer(formData) {
    return function (dispatch) {
        return ajax()
            .post(`/containers/upload`, formData)
            .then((response) => {
                const data = response.data;
                const parsedResponse = parseOneContainerResponse(data, 'none');
                dispatch(uploadContainerSuccess(parsedResponse));
                return response;
            });
    };
}

export function deleteSignature(contId, signatureId) {
    return function (dispatch) {
        return ajax()
            .delete(`/containers/${contId}/signatures/${signatureId}`)
            .then((response) => {
                const data = response.data;
                let signaturesData =
                    data.details != null ? (data.details.signatures != null ? data.details.signatures : []) : [];
                dispatch(getContainerSignaturesSuccess(signaturesData));
                return response;
            });
    };
}

export function validateAcknowledgement(id, param) {
    return function () {
        return ajax()
            .post(`/eacknowledgement/${id}/validate${param}`);
    };
}

export const newContainer = (id) => {
    return function (dispatch) {
        return ajax()
            .get(`/containers/${id}`)
            .then((response) => {
                dispatch(
                    newContainerSuccess({
                        data: {
                            creationDate: response.data.creationDate,
                            fileBaseName: response.data.title,
                            id: response.data.id,
                            signedFromBulkSigning: response.data.signedFromBulkSigning,
                            waitingToSign: response.data.waitingToSign,
                            type: response.data.type,
                            wasOpened: response.data.wasOpened,
                            size: response.data.size.toString(),
                            mimeType: response.data.mimeType,
                            pages: response.data.pages,
                            pdfEmbeddedFiles: response.data.pdfEmbeddedFiles,
                            error: response.data.error,
                            pdfFormFields: response.data.pdfFormFields,
                            processStarter: response.processStarter,
                            workflowGuid: response.workflowGuid,
                            isCounterSignable: response.data.isCounterSignable,
                            isUploadEnabled: response.data.isUploadEnabled,
                            action: signOrApproveAction(response.data.signedFromBulkSigning)
                        },
                    })
                );
                dispatch(updateSelectedContainerList([response]));
            });
    };
};

export const getContainerData = (id) => {
    return function () {
        return ajax()
            .get(`/containers/${id}`)
            .then((response) => {
                return {
                    data: {
                        creationDate: response.data.creationDate,
                        fileBaseName: response.data.title,
                        id: response.data.id,
                        signedFromBulkSigning: response.data.signedFromBulkSigning,
                        waitingToSign: response.data.waitingToSign,
                        type: response.data.type,
                        esNamespace: response.data.esNamespace,
                        wasOpened: response.data.wasOpened,
                        size: response.data.size.toString(),
                        mimeType: response.data.mimeType,
                        pages: response.data.pages,
                        pdfEmbeddedFiles: response.data.pdfEmbeddedFiles,
                        error: response.data.error,
                        pdfFormFields: response.data.pdfFormFields,
                        processStarter: response.processStarter,
                        workflowGuid: response.workflowGuid,
                        isCounterSignable: response.data.isCounterSignable,
                        isUploadEnabled: response.data.isUploadEnabled,
                        action: signOrApproveAction(response.data.signedFromBulkSigning)
                    }
                };
            });
    };
};

let lastUpdateContainerId = null;

export const updateContainerData = (id, noAction) => {
    lastUpdateContainerId = id;
    return function (dispatch) {
        if (!id) {
            return Promise.resolve(null);
        }
        return ajax()
            .get(`/containers/${id}`)
            .then((response) => {
                if (lastUpdateContainerId !== id) {
                    return;
                }
                response.data.fileBaseName = response.data.title;
                response.data.size = response.data.size.toString();
                response.data.action = signOrApproveAction(response.data.signedFromBulkSigning)
                return response.data;
            })
            .then((containerResponse) => {
                if (containerResponse) {
                    const documentType = containerResponse.type;
                    if (['edossier', 'asic', 'eacknowledgement'].includes(documentType)) {
                        dispatch(getContainerDocuments(containerResponse.details.documents, id));
                    }
                    return containerResponse;
                }
            })
            .then((containerResponse) => {
                if (containerResponse) {
                    let signaturesData = containerResponse.details != null
                        ? containerResponse.details.signatures != null
                            ? containerResponse.details.signatures
                            : []
                        : [];
                    dispatch(getContainerSignaturesSuccess(signaturesData));
                    dispatch(updateContainer({data: containerResponse}));
                    return noAction
                        ? parseOneContainerResponse(containerResponse, 'none').data
                        : parseOneContainerResponse(containerResponse).data;
                }
            })
            .catch((error) => {
                dispatch(getContainerSignaturesFailure());
                throw error;
            });
    };
};

export const updateSelectedContainerList = (containerList) => {
    return {
        type: types.UPDATE_SELECTED_CONTAINER_IDS,
        payload: containerList
    };
};

export const setSelectedContainers = (containers) => {
    return {
        type: types.SET_SELECTED_CONTAINERS,
        payload: containers
    };
};

export const addSelectedContainers = (containers) => {
    return {
        type: types.ADD_SELECTED_CONTAINERS,
        payload: containers
    };
};

export const removeSelectedContainers = (containers) => {
    return {
        type: types.REMOVE_SELECTED_CONTAINERS,
        payload: containers
    };
};

export const refuseSigning = (refusedElements) => {
    return function () {
        return ajax()
            .post(`/containers/refuseSigning`, { refusedElements: refusedElements });
    };
};

export const getWaitingToSignByOthers = () => {
    return function (dispatch) {
        return ajax()
            .get('/containers/bulkSigningProcesses')
            .then((response) => {
                dispatch(updateWaitingToSignByOthers(response.data));
                return response;
            });
    };
};

export const getWaitingToSignByOthersByContainer = (guid) => {
    return function (dispatch) {
        return ajax()
            .get(`/containers/bulkSigningProcesses/${guid}`)
            .then((response) => {
                dispatch(updateWaitingToSignByOthersByContainer(response.data));
                return response;
            })
            .catch((error) => {
                console.error(error);
            });
    };
};

export const approveContainers = (containerIds) => {
    return function () {
        return ajax()
            .post('/containers/approve', { containers: containerIds });
    };
};

export function getContainersInSignatureProcessSuccess(parsedResponse) {
    return {
        type: types.GET_CONTAINERS_IN_SIGNATURE_PROCESS_SUCCESS,
        payload: parsedResponse
    };
}

export function getContainersInSignatureProcessFailure() {
    return {
        type: types.GET_CONTAINERS_IN_SIGNATURE_PROCESS_FAILURE
    };
}

export const updateWaitingToSignByOthers = (containers) => {
    return {
        type: types.UPDATE_WAITING_TO_BE_SIGNED_BY_OTHERS,
        payload: containers,
    };
};

export const updateWaitingToSignByOthersByContainer = (container) => {
    return {
        type: types.UPDATE_WAITING_TO_BE_SIGNED_BY_OTHERS_BY_CONTAINER,
        payload: container,
    };
};

export const updateSelectedSignature = (newData) => {
    return {
        type: types.UPDATE_SELECTED_SIGNATURE,
        payload: newData,
    };
};

export const resetSelectedSignature = () => {
    return {
        type: types.RESET_SELECTED_SIGNATURE,
    };
};

export const getContainerWithPages = (id) => {
    return ajax()
        .get(`/containers/${id}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

import ContainerIcon from '../container/ContainerIcon';
import DeleteButton from '../buttons/DeleteButton';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Col, Divider, Icon, Input, Row, Spin, Switch, Table, Typography } from 'antd';
import {
    formatDate,
    notificationBadgeOffset,
    formatBytes,
    filterData,
    sorter,
    ajax,
    downloadContainer,
    createNotification,
    createNotificationShort,
    fileSizeInBytes,
} from '../../helper';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { getUserContainers as _getUserContainers } from '../../actions/admin';
import { confirmationModal } from '../modals/ConfirmationModal';

const FILTER_FIELDS = ['title', 'type', 'creationDate'];

class UserFileList extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
    }

    state = {
        loading: true,
        containers: [],
        selectedContainerList: [],
        searchText: '',
        rowSelection: undefined,
    };

    componentDidMount() {
        this._isMounted = true;
        this.props
            .getUserContainers(this.props.email)
            .then((response) => {
                if (response.data) {
                    const containers = response.data.map((container) => {
                        container.size = formatBytes(container.size);
                        container.creationDate = formatDate(container.creationDate, this.props.translate('language'));
                        return container;
                    });
                    this._isMounted && this.setState({ containers, loading: false });
                }
            })
            .catch((e) => {
                createNotification(this.props.translate, e.response.data.code, {
                    message: this.props.translate('notifications.getData.failure'),
                    type: 'error',
                });
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onSearch = (value) => {
        this._isMounted && this.setState({ searchText: value });
    };

    resetFilterIfSearchFieldIsEmpty = (event) => {
        if (event.target.value === '' && this.state.searchText !== '') {
            this._isMounted && this.setState({ searchText: '' });
        }
    };

    handleRowSelect = (selectedRowKeys, selectedRows) => {
        this._isMounted && this.setState({ selectedContainerList: selectedRows });
    };

    handleRowSelectionChange = (enable) => {
        this._isMounted &&
            this.setState({
                rowSelection: enable ? { onChange: this.handleRowSelect } : undefined,
                selectedContainerList: [],
            });
    };

    handleDeleteSelectedContainers = (containerList) => {
        containerList.forEach((container) => this.handleDeleteContainer(container.id));
    };

    handleDeleteContainer = (containerId) => {
        return ajax()
            .delete(`/admin/containers/${containerId}`)
            .then(() => {
                createNotificationShort(this.props.translate, {
                    message: this.props.translate('messages.success.deleteContainer'),
                    type: 'success',
                });
                this._isMounted &&
                    this.setState((prev) => ({
                        containers: prev.containers.filter((container) => container.id !== containerId),
                    }));
            })
            .catch((error) => {
                createNotification(this.props.translate, error.response.data.code, {
                    message: this.props.translate(`errorCodes.${error.response.data.code}`),
                    type: 'error',
                });
            });
    };

    render() {
        const { containers, loading, searchText, rowSelection, selectedContainerList } = this.state;
        const { stepBackToUserList, translate, email } = this.props;

        const filteredContainers = filterData(containers, searchText, FILTER_FIELDS);
        const columns = getColumns(translate);
        addActionsColumnIfNecessary(columns, translate, this.handleDeleteContainer);

        return (
            <div>
                <Row type="flex" align="middle">
                    <Col span={12}>
                        <Button onClick={stepBackToUserList} type="link">
                            <Icon type="left-circle" style={{ fontSize: 20 }} />
                            <span style={{ fontSize: 20 }}> {translate('backToAdminUserList')}</span>
                        </Button>
                    </Col>
                    <Col style={{ textAlign: 'right', paddingRight: '1rem', fontSize: '1rem' }} span={12}>
                        <span style={{ marginRight: '1rem' }}>{translate('adminPage.selectedUser')}</span>
                        <Typography.Text underline strong style={{ display: 'inline-block' }}>
                            {email}
                        </Typography.Text>
                    </Col>
                </Row>
                <Divider />
                <div>
                    <Row type="flex" align="middle" gutter={[24, 0]} style={{ marginBottom: '1rem' }}>
                        <Col sm={10} md={10} lg={8}>
                            <Input.Search
                                allowClear={true}
                                onSearch={this.onSearch}
                                onChange={this.resetFilterIfSearchFieldIsEmpty}
                                placeholder={translate('adminPage.common.searchPlaceholder')}
                            />
                        </Col>
                        {window.config.REACT_APP_ADMIN_FILE_DELETE_FUNCTION_AVAILABLE === true && (
                            <Col>
                                {this.props.translate('adminPage.users.selectMultiple')}
                                <Switch
                                    style={{ marginLeft: '10px' }}
                                    checked={!!rowSelection}
                                    onChange={this.handleRowSelectionChange}
                                />
                            </Col>
                        )}
                        <Col>
                            {!!rowSelection && (
                                <DeleteButton
                                    disabled={!selectedContainerList.length}
                                    key={'delete'}
                                    onClick={() => this.handleDeleteSelectedContainers(selectedContainerList)}
                                    title={translate('buttons.delete.container')}
                                />
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Spin spinning={loading}>
                                <Table
                                    style={{ overflow: 'auto' }}
                                    columns={columns}
                                    rowSelection={rowSelection}
                                    dataSource={filteredContainers.map((container) => ({
                                        key: container.id,
                                        ...container,
                                    }))}
                                    locale={{ emptyText: translate('noData') }}
                                />
                            </Spin>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

UserFileList.propTypes = {
    email: PropTypes.string.isRequired,
    getUserContainers: PropTypes.func.isRequired,
    stepBackToUserList: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    translate: getTranslate(state.locale),
});

const mapDispatchToProps = (dispatch) => ({
    getUserContainers: (email) => {
        return dispatch(_getUserContainers(email));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserFileList);

const getColumns = (translate) => [
    {
        title: translate('home.title'),
        dataIndex: 'title',
        key: 'title',
        sortDirections: ['descend', 'ascend'],
        sorter: sorter('title'),
        // eslint-disable-next-line react/display-name
        render: (text, record) => (
            <div
                style={{
                    display: 'table',
                    wordWrap: 'break-word',
                    wordBreak: 'break-all',
                    backGroundColor: 'grey',
                }}
            >
                <ContainerIcon offset={notificationBadgeOffset(record.type)} container={record} translate={translate} />
                <div
                    style={{
                        display: 'table-cell',
                        verticalAlign: 'middle',
                        marginRight: 100,
                    }}
                >
                    {record.title}
                </div>
            </div>
        ),
    },
    {
        title: translate('adminPage.files.type'),
        align: 'center',
        dataIndex: 'type',
        key: 'type',
        sortDirections: ['descend', 'ascend'],
        sorter: sorter('type'),
    },
    {
        title: translate('adminPage.files.size'),
        align: 'center',
        dataIndex: 'size',
        key: 'size',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => fileSizeInBytes(a.size) - fileSizeInBytes(b.size),
    },
    {
        title: translate('adminPage.files.created'),
        dataIndex: 'creationDate',
        key: 'date',
        align: 'center',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => moment(a.creationDate).unix() - moment(b.creationDate).unix(),
        render: (text) => {
            return formatDate(text, translate('language'));
        },
    },
];

const addActionsColumnIfNecessary = (columns, translate, handleDeleteContainer) => {
    if (
        window.config.REACT_APP_ADMIN_FILE_DOWNLOAD_FUNCTION_AVAILABLE === true ||
        window.config.REACT_APP_ADMIN_FILE_DELETE_FUNCTION_AVAILABLE === true
    ) {
        columns.push({
            title: translate('adminPage.common.actions'),
            align: 'center',
            key: 'download_and_delete',
            // eslint-disable-next-line react/display-name
            render: (container) => (
                <Button.Group>
                    {window.config.REACT_APP_ADMIN_FILE_DOWNLOAD_FUNCTION_AVAILABLE === true && (
                        <Button
                            icon="download"
                            onClick={() => downloadContainer(container.id, true)}
                            title={translate('adminPage.files.download')}
                            visible={window.config.REACT_APP_ADMIN_FILE_DOWNLOAD_FUNCTION_AVAILABLE === true}
                        />
                    )}
                    {window.config.REACT_APP_ADMIN_FILE_DELETE_FUNCTION_AVAILABLE === true && (
                        <DeleteButton
                            onClick={() =>
                                confirmationModal(
                                    translate,
                                    () => handleDeleteContainer(container.id),
                                    translate('confirm.fileDeleteConfirm')
                                )
                            }
                            title={translate('adminPage.files.delete')}
                        />
                    )}
                </Button.Group>
            ),
        });
    }
};

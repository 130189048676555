import PropTypes from 'prop-types';
import React from 'react';
import eszignoImg from '../images/eszigno-full.svg';
import microSignerImg from '../images/microsigner.svg';
import { Avatar } from 'antd';
import {renderToString} from "react-dom/server";

class ESignNoClient extends React.Component {
    render() {
        const { translate } = this.props;

        const noMobileApplicationOrFailureOnMobileDevice = this.props.signWithPBM || window.isMobileCheck();
        let content = null;
        if (this.props.visible) {
            let message = '';
            let esignClientDownloadLinks = [];
            if (noMobileApplicationOrFailureOnMobileDevice) {
                message = translate(
                    'modals.noMobileApp.message', {
                        'eSzignoMobileAvatar': renderToString(
                            <Avatar size = { 20 } src = { eszignoImg } style = {{ marginLeft: 6, marginRight: 4 }} />
                        )
                    });

                esignClientDownloadLinks = [
                    {
                        downloadLink: 'https://play.google.com/store/apps/details?id=hu.microsec.eszigno',
                        message: translate('texts.downloadEszignoMobile') + ' (Android)',
                    },
                    {
                        downloadLink: 'https://apps.apple.com/us/app/e-szign%C3%B3/id1481195992',
                        message: translate('texts.downloadEszignoMobile') + ' (iOS, iPadOS)',
                    },
                ];
            } else {
                message = translate('modals.noMicroSigner.message', {
                    'microSignerAvatar': renderToString(
                        <Avatar size = { 20 } src = { microSignerImg } style = {{ marginLeft: 6, marginRight: 4 }} shape="square" />
                    )
                });

                let desktopPlatform = window.navigator.platform;
                if (desktopPlatform.startsWith('Win')) {
                    esignClientDownloadLinks = [
                        {
                            downloadLink: window.config.REACT_APP_MICROSIGNER_WINDOWS,
                            message: translate('texts.downloadMicroSigner'),
                        },
                    ];
                } else if (desktopPlatform.startsWith('Mac')) {
                    esignClientDownloadLinks = [
                        {
                            downloadLink: window.config.REACT_APP_MICROSIGNER_MACOS,
                            message: translate('texts.downloadMicroSigner'),
                        },
                    ];
                } else if (desktopPlatform.startsWith('Linux')) {
                    esignClientDownloadLinks = [
                        {
                            downloadLink: window.config.REACT_APP_MICROSIGNER_LINUX,
                            message: translate('texts.downloadMicroSigner'),
                        },
                    ];
                }
            }
            content = (
                <React.Fragment>
                    { message }
                    {esignClientDownloadLinks.map((obj, i) => {
                        return (
                            <div key={i} style={{ marginTop: 5 }}>
                                <a href={obj.downloadLink} target="_blank" rel="noopener noreferrer">
                                    {obj.message}
                                </a>
                                <br />
                            </div>
                        );
                    })}
                </React.Fragment>
            );
        }
        return content;
    }
}

ESignNoClient.propTypes = {
    translate: PropTypes.func.isRequired,
    signWithPBM: PropTypes.bool.isRequired,
    visible: PropTypes.bool.isRequired,
};

export default ESignNoClient;

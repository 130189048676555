import {InputNumber} from "antd";
import theme from "../../theme";
import React from "react";
import PropTypes from "prop-types";
import PreviewNavigationButton from "../buttons/PreviewNavigationButton";

class MultiPagePreviewNavigation extends React.Component {
    constructor(props) {
        super(props);
        this._inputWidth = this.props.numberOfPages ? (this.props.numberOfPages.toString().length + 2) + "em" : "5em";
    }

    goToFirstPage() {
        this.goToPage(1);
    }

    goToPrevPage = () => {
        const {pageNumber} = this.props;
        this.goToPage(pageNumber - 1);
    }

    goToNextPage = () => {
        const {pageNumber} = this.props;
        this.goToPage(pageNumber + 1);
    }

    goToLastPage = () => {
        const {numberOfPages} = this.props;
        this.goToPage(numberOfPages);
    }

    goToPage = (pageNumber) => {
        const {goToPageCallback, numberOfPages} = this.props;
        if (pageNumber > numberOfPages) {
            goToPageCallback(numberOfPages);
        } else if (pageNumber < 1) {
            goToPageCallback(1);
        } else {
            goToPageCallback(pageNumber);
        }
    }

    render() {
        const {pageNumber, numberOfPages, loading} = this.props;
        const inputWidth = this._inputWidth;
        const isLoading = loading || false;
        return (
            <>
                <PreviewNavigationButton
                    disabled={pageNumber <= 1 || isLoading}
                    onClick={() => this.goToFirstPage()}
                    icon={'vertical-right'}
                    className='firstPageButton'
                />
                <PreviewNavigationButton
                    disabled={pageNumber <= 1 || isLoading}
                    onClick={() => this.goToPrevPage()}
                    icon={'left'}
                    className='previousPageButton'
                />
                <InputNumber
                    min={1}
                    max={numberOfPages}
                    defaultValue={1}
                    onChange={(event) => this.goToPage(event)}
                    value={pageNumber}
                    style={{width: inputWidth}}
                />
                <span style={{color: theme.textColor, paddingLeft: 5}}>/ {numberOfPages}</span>
                <PreviewNavigationButton
                    disabled={numberOfPages <= pageNumber || isLoading}
                    onClick={() => this.goToNextPage()}
                    icon={'right'}
                    className='nextPageButton'
                />
                <PreviewNavigationButton
                    disabled={numberOfPages <= pageNumber || isLoading}
                    onClick={() => this.goToLastPage()}
                    icon={'vertical-left'}
                    className='lastPageButton'
                />
            </>);
    }
}

MultiPagePreviewNavigation.propTypes = {
    numberOfPages: PropTypes.number.isRequired,
    pageNumber: PropTypes.number.isRequired,
    goToPageCallback: PropTypes.func.isRequired,
    loading: PropTypes.bool
};

export default MultiPagePreviewNavigation;

import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {Button, Col, Form, Icon, Row, Upload} from "antd";
import {useSelector} from "react-redux";
import {getTranslate} from "react-localize-redux";
import theme from "../../../theme";

export default function SigningWorkflowFileAttachmentList(props) {
    const {handleFileAttachmentListChanged} = props;
    const [fileList, setFileList] = useState([]);
    const translate = useSelector((state) => getTranslate(state.locale));
    const [uploadEnabled, setUploadEnabled] = useState(false);
    const [fileLimitExceeded, setFileLimitExceeded] = useState(window.config.REACT_APP_SIGNING_WORKFLOW_FILE_ATTACHMENT_LIMIT < fileList.length);

    useEffect(() => {
        setFileLimitExceeded(window.config.REACT_APP_SIGNING_WORKFLOW_FILE_ATTACHMENT_LIMIT <= (fileList.length));
        }, [fileList]);

    function updateFileList(newFile) {
        setFileList((fileList) => [...fileList, newFile]);
    }

    function handleChange(info) {
        setFileList(info.fileList);
        handleFileAttachmentListChanged(info.fileList);
    }

    return (
        <Form.Item>
            <Row type='flex'>
                <Col span={8}>
                    <b>{translate('modals.sendForSignature.attachment.file.title')}</b>
                </Col>
                <Col span={8} offset={8}>
                    <Button block type='dashed' onClick={() => setUploadEnabled(!uploadEnabled)}>
                        <Icon type='plus'/>
                        {translate('modals.sendForSignature.attachment.file.add')}
                    </Button>
                </Col>
            </Row>
            <Row>
                {uploadEnabled && <Upload multiple={false}
                        accept={"*/*"}
                        fileList={fileList}
                        onChange={handleChange}
                        openFileDialogOnClick={!fileLimitExceeded}
                        beforeUpload={(file) => {
                            updateFileList(file);
                            return false;
                        }}
                >
                    <Button icon="upload">{translate('modals.sendForSignature.attachment.file.new')}</Button>
                    { fileLimitExceeded
                        ? <div style={{color: theme.DANGER}}>{translate('modals.sendForSignature.attachment.file.limitExceeded',
                            {limit:window.config.REACT_APP_SIGNING_WORKFLOW_FILE_ATTACHMENT_LIMIT})}</div>
                        : <div >{translate('modals.sendForSignature.attachment.file.limitInfo',
                            {limit:window.config.REACT_APP_SIGNING_WORKFLOW_FILE_ATTACHMENT_LIMIT})}</div>
                    }
                </Upload>}
            </Row>
        </Form.Item>
    );
}

SigningWorkflowFileAttachmentList.propTypes = {
    handleFileAttachmentListChanged: PropTypes.func.isRequired
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import ModalWrapper from '../ModalWrapper';
import { Button, Checkbox, Col, Input, Row, Table, Icon } from 'antd';
import { deleteWorkflowTemplate, updateWorkflowTemplate } from '../../../actions/workflow';
import { isBlank } from '../../../utilities/stringUtilities';
import { removeModal, toggleModal } from '../../../actions/modal';
import { SEND_FOR_SIGNATURE } from '../../../constants/modalNames';
import PropTypes from 'prop-types';

export default function WorkflowTemplateModal(props) {
    const [editMode, setEditMode] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);
    const [editedTemplateTitle, setEditedTemplateTitle] = useState('');
    const translate = useSelector((state) => getTranslate(state.locale));
    const templates = useSelector((state) => state.workflowTemplates);
    const [selectedTemplateGuid, setSelectedTemplateGuid] = useState('');
    const [timeoutId, setTimeoutId] = useState();
    const [checked, setChecked] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
    }, [selectedTemplateGuid]);

    const handleEditOk = (template) => {
        let selectedTemplate = { ...template };
        selectedTemplate.templateTitle = editedTemplateTitle;
        setEditMode(false);
        setEditedTemplateTitle('');
        dispatch(updateWorkflowTemplate(selectedTemplate));
    };

    const handleEditClose = () => {
        setEditMode(false);
        setEditedTemplateTitle('');
    };
    const handleLoadSelectedTemplate = () => {
        dispatch(removeModal());
        setTimeout(function() {
            const selectedTemplateAsArray = templates.filter(template => template.templateGuid === selectedTemplateGuid);

            dispatch(toggleModal(SEND_FOR_SIGNATURE, { template: selectedTemplateAsArray[0] }));
        }, 500);
    };

    const onCheckboxChange = (guid) => {
        setChecked(!checked);
        checked ? setSelectedTemplateGuid('') : setSelectedTemplateGuid(guid);
    };

    function handleDelayedDelete(guid) {
        let id = setTimeout(function() {
            dispatch(deleteWorkflowTemplate(guid)).then(setDeleteMode(false));
        }, 5000);
        setTimeoutId(id);
    }

    function handleUndoDelete() {
        clearTimeout(timeoutId);
        setChecked(false);
        setSelectedTemplateGuid('');
    }

    const columns = [
        {
            key: 'action',
            render: (record) => {
                const isGlobal = record.templateType === 'GLOBAL';
                if (editMode && record.templateGuid === selectedTemplateGuid) {
                    return (
                        <Row>
                            <Col span={17} style={{ alignItems: 'center' }} offset={1}>
                                <Input
                                    value={editedTemplateTitle}
                                    onChange={(e) => {
                                        setEditedTemplateTitle(e.target.value);
                                    }}
                                    placeholder={translate('workflow.template.placeholder')}
                                    maxLength={255}
                                />
                            </Col>
                            <Col span={2} offset={2}>
                                <Button style={{ border: 'none', boxShadow: 'none' }}
                                        type='primary' icon='check'
                                        onClick={() => {
                                            handleEditOk(record);
                                        }} />
                            </Col>
                            <Col span={2}>
                                <Button type='primary' icon='close' ghost
                                        onClick={() => {
                                            handleEditClose();
                                        }} />
                            </Col>
                        </Row>
                    );
                } else if (deleteMode && record.templateGuid === selectedTemplateGuid) {
                    return (
                        <Row type='flex' justify='center' align='middle'>
                            <Col span={15} offset={1}>
                                <Row>
                                    <b>{translate('workflow.template.deleted')}:</b>
                                </Row>
                                <Row>
                                    {record.templateTitle}
                                </Row>
                            </Col>
                            <Col span={4} onClick={() => {
                                setDeleteMode(false);
                                handleUndoDelete();
                            }}>
                                <div>
                                    {translate('workflow.template.undo')}
                                </div>
                            </Col>
                            <Col span={2}>
                                <Button style={{ border: 'none', boxShadow: 'none', color: 'black' }}
                                        ghost
                                        disabled={!checked}
                                        onClick={() => {
                                            setDeleteMode(false);
                                            handleUndoDelete();
                                        }}>
                                    <Icon type='undo' />
                                </Button>
                            </Col>
                        </Row>
                    );
                } else {
                    return (
                        <Row type='flex' justify='center' align='middle' onClick={() => {
                            setChecked(true);
                            setSelectedTemplateGuid(record.templateGuid);
                        }}>
                            <Col span={2}>
                                <Checkbox disabled={checked && selectedTemplateGuid !== record.templateGuid}
                                          checked={checked && selectedTemplateGuid === record.templateGuid}
                                          onChange={() => {
                                              onCheckboxChange(record.templateGuid);
                                          }} />
                            </Col>
                            <Col span={14} style={{ maxWidth: '450px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {selectedTemplateGuid === record.templateGuid ?
                                    <b>
                                        {record.templateTitle}
                                    </b>
                                    : <div>
                                        {record.templateTitle}
                                    </div>}

                            </Col>
                            <Col span={2}>
                                {!isGlobal &&
                                    <Button style={{ border: 'none', boxShadow: 'none', color: 'black' }}
                                    //disabled={selectedTemplateGuid !== record.templateGuid}
                                        ghost
                                        onClick={() => {
                                            setEditMode(true);
                                            setEditedTemplateTitle(record.templateTitle);
                                            setSelectedTemplateGuid(record.templateGuid);
                                        }}>
                                        <Icon type='edit' />
                                    </Button>
                                }
                            </Col>
                            <Col span={2}>
                                {!isGlobal && <Button style={{ border: 'none', boxShadow: 'none', color: 'black' }}
                                    //disabled={selectedTemplateGuid !== record.templateGuid}
                                          ghost
                                          onClick={() => {
                                              setEditMode(false);
                                              setDeleteMode(true);
                                              handleDelayedDelete(record.templateGuid);
                                          }}>
                                        <Icon type='delete' />
                                    </Button>
                                }
                            </Col>
                        </Row>
                    );
                }
            }
        }
    ];

    const buttonTitle = isBlank(selectedTemplateGuid) ? translate('workflow.template.select') : translate('workflow.template.load');
    const modalTitle = (
        <div><Icon type='star' style={{ marginRight: 10 }} />{translate('workflow.template.title')}</div>);

    return (
        <ModalWrapper
            {...props}
            bodyStyle={{ textAlign: 'center', padding: 0 }}
            title={modalTitle}
            width={500}
            footer={
                <Button
                    disabled={editMode || !checked || deleteMode}
                    type='primary'
                    onClick={() => {
                        handleLoadSelectedTemplate();
                    }}
                >
                    {buttonTitle}
                </Button>}>
            <Table
                columns={columns}
                dataSource={templates}
                pagination={false}
                rowKey={'templateGuid'}
                showHeader={false}
                size={'small'}
                rowClassName={record => deleteMode && record.templateGuid !== selectedTemplateGuid && 'disabled-row'}

            />
        </ModalWrapper>
    );
}

WorkflowTemplateModal.propTypes = {
    modalData: PropTypes.object
};

import {createNotificationShort} from "../../helper";

export function validateUploadedImageResolution(img, minimumConstraints, translate) {
    if (img.height < minimumConstraints.height) {
        createNotificationShort(translate, {
            description: translate('accepted.imageSizeHeight', {minHeight: minimumConstraints.height}),
            message: translate('badFileSize'),
            type: 'error'
        })
        return false;
    } else if (img.width < minimumConstraints.width) {
        createNotificationShort(translate, {
            description: translate('accepted.imageSizeWidth', {minWidth: minimumConstraints.width}),
            message: translate('badFileSize'),
            type: 'error'
        })
        return false;
    }
    return true;
}

export function calculateDimension(imageWidth, imageHeight, maximumConstraints) {
    const imageDimension = {width: imageWidth, height: imageHeight}
    if (imageWidth > maximumConstraints.width) {
        imageDimension.width = maximumConstraints.width;
        imageDimension.height = Math.round(imageHeight * (imageDimension.width / imageWidth));
    }
    if (imageDimension.height > maximumConstraints.height) {
        imageDimension.height = maximumConstraints.height;
        imageDimension.width = Math.round(imageWidth * (imageDimension.height / imageHeight));
    }
    return imageDimension;
}

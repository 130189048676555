import {Button, Col, Icon, Row, Spin} from 'antd';
import CustomAlert from './CustomAlert';
import CustomDivider from './CustomDivider';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import LoginForm from './LoginForm';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import ReactGA from 'react-ga';
import ResendConfirmation from './ResendConfirmation';
import fbIcon from '../images/facebookIcon.svg';
import {createNotification, getApiUrl, getFriendlyName, getHistory} from '../helper';
import {getTranslate} from 'react-localize-redux';
import {deleteStoredFileId} from '../actions/container';
import {adminLogin, authWithSocialMedia, cancelNotification, login, startMobileLogin} from '../actions/user';
import {toggleModal} from '../actions/modal';
import {browserName, osName, osVersion} from 'react-device-detect';
import {FORGOTTEN_PASSWORD, MOBILE_LOGIN, NOTIFY_USER, SECOND_FACTOR} from '../constants/modalNames';
import ActionLink from './buttons/ActionLink';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {useDispatch, useSelector} from 'react-redux';
import GoogleAuthButton from './buttons/GoogleAuthButton';

export default function CompleteLoginForm (props) {
    const dispatch = useDispatch();

    const [mediaAuthError, setMediaAuthError] = useState(undefined);
    const [loadingState, setLoadingState] = useState(false);

    const translate = useSelector((state) => getTranslate(state.locale));

    const showNotification = (errorCode, detail) => {
        if (errorCode !== 'USER_INVALID_OPERATION') {
            return;
        }
        if (detail.includes('Facebook')) {
            createNotification(translate, errorCode, {
                message: translate('notifications.login.failure.facebook'),
                type: 'error'
            });
        } else if (detail.includes('Google')) {
            createNotification(translate, errorCode, {
                message: translate('notifications.login.failure.google'),
                type: 'error'
            });
        }
    }

    const redirect = (shouldNotifyUser) => {
        const history = getHistory();
        const params = new URLSearchParams(history.location.search);
        const redirectURL = params.get('redirectURL');
        if (redirectURL) {
            history.push(redirectURL);
        } else {
            history.push('/');
        }
        if (shouldNotifyUser) {
            dispatch(toggleModal(NOTIFY_USER));
        }
        if (props.storedFileId !== '') {
            window.open(getApiUrl() + `/download/${props.storedFileId}`, '_self');
            dispatch(deleteStoredFileId());
        }
    }

    const basicAuth = (e, loginData) => {
        ReactGA.event({
            category: 'User',
            action: 'Basic Login'
        });
        const { currentLanguage } = props;
        setLoadingState(true);
        return dispatch(
            login(
                Object.assign({
                    email: loginData.userName.toLowerCase(),
                    password: loginData.password,
                    accesstoken: '',
                    language: currentLanguage ? currentLanguage : 'en',
                    type: 'password',
                    friendlyName: getFriendlyName()
                }),
                currentLanguage
            ))
            .then((response) => {
                if (response.status === 202) {
                    setLoadingState(false);
                    if (response.data.startIdentificationResponse) {
                        dispatch(toggleModal(MOBILE_LOGIN, Object.assign(response.data.startIdentificationResponse, { isLogin: true, jwt: response.data.jwt })));
                    } else {
                        dispatch(toggleModal(SECOND_FACTOR,
                            Object.assign(response.data, {
                                pollType: 'loginPoll',
                                onCancel: () => dispatch(cancelNotification(response.data.jwt)),
                            })
                        ));
                    }
                } else {
                    localStorage.setItem('language', currentLanguage);
                    redirect(response.data.notifyUser);
                }
            })
            .catch((error) => {
                if (!error.response) {
                    setLoadingState(false);
                    return Promise.reject({ code: 'SERVER_APPLICATION_ERROR' });
                }
                if (error.response.data.code === 'USER_EMAIL_NOT_CONFIRMED') {
                    setLoadingState(false);
                    return Promise.reject({
                        code: error.response.data.code,
                        extraCom: <ResendConfirmation email={loginData.userName} />
                    });
                }
                if (error.response.data.code === 'USER_INVALID_USERNAME_OR_PASSWORD' || error.response.data.code === 'USER_INVALID_USERNAME') {
                    setTimeout(
                        () => setLoadingState(false),
                        window.config.REACT_APP_LOCK_TIME_IN_SEC * 1000
                    );
                } else {
                    setLoadingState(false);
                }
                return Promise.reject(error.response.data);
            });
    };

    const facebookAuth = (resultObject) => {
        ReactGA.event({
            category: 'User',
            action: 'Facebook Login'
        });
        if (resultObject.accessToken) {
            const accessToken = resultObject.accessToken;
            const email = resultObject.email;
            const { currentLanguage } = props;
            dispatch(authWithSocialMedia(
                Object.assign({
                    email: email,
                    password: '',
                    accesstoken: accessToken,
                    language: currentLanguage ? currentLanguage : 'en',
                    type: 'facebook'
                })
            ))
                .then(() => {
                    redirect();
                })
                .catch((err) => {
                    setMediaAuthError(err.response.data);
                    showNotification(err.response.data.code, err.response.data.detail);
                });
        } else {
            showNotification('USER_INVALID_OPERATION', 'Facebook login failed');
        }

    };

    const googleAuth = (resultObject) => {
        ReactGA.event({
            category: 'User',
            action: 'Google Login'
        });
        const { currentLanguage } = props;
        dispatch(authWithSocialMedia(
                Object.assign({
                    email: null,
                    password: null,
                    accesstoken: resultObject,
                    language: currentLanguage ? currentLanguage : 'en',
                    type: 'google'
                })
            ))
            .then(() => {
                redirect();
            })
            .catch((err) => {
                setMediaAuthError(err.response.data);
                showNotification(err.response.data.code, err.response.data.detail);
            });
    };

    const adminAuth = (e, loginData) => {
        ReactGA.event({
            category: 'User',
            action: 'Admin Login'
        });
        const { currentLanguage } = props;
        setLoadingState(false);
        return dispatch(
            adminLogin(
                Object.assign({
                    email: loginData.userName,
                    password: loginData.password,
                    accesstoken: '',
                    language: currentLanguage ? currentLanguage : 'en',
                    type: 'password'
                }),
                currentLanguage
            ))
            .then(() => {
                getHistory().push('/adminPage');
            })
            .catch((error) => {
                if (!error.response) {
                    setLoadingState(false);
                    return Promise.reject({ code: 'SERVER_APPLICATION_ERROR' });
                }
                if (error.response.data.code === 'USER_EMAIL_NOT_CONFIRMED') {
                    setLoadingState(false);
                    return Promise.reject({
                        code: error.response.data.code,
                        extraCom: <ResendConfirmation email={loginData.userName} />
                    });
                }
                if (error.response.data.code === 'USER_INVALID_USERNAME_OR_PASSWORD' || error.response.data.code === 'USER_INVALID_USERNAME') {
                    setTimeout(
                        () => setLoadingState(false),
                        window.config.REACT_APP_LOCK_TIME_IN_SEC * 1000
                    );
                } else {
                    setLoadingState(false);
                }
                return Promise.reject(error.response.data);
            });
    };

    const mobileAuth = () => {
        startMobileLogin(`web-Szigno/${browserName}/${osName}-${osVersion}`)
            .then((response) => {
                dispatch(toggleModal(MOBILE_LOGIN, Object.assign(response.data, { isLogin: true })));
            })
            .catch((err) => {
                setMediaAuthError(err.response.data);
                showNotification(err.response.data.code, err.response.data.detail);
            });
    }
    const socialMediaIconsStyle = {
        float: 'left',
        width: '20px',
        height: '20px'
    };
    const {
        showSocialOptions,
        showForgottenPassword,
        embedded,
        method,
        isAdminLogin,
        registeredFormLoading
    } = props;
    const loading = registeredFormLoading ? registeredFormLoading : loadingState;

    return (
        <div>
            {window.config.REACT_APP_ACTIVE_DIRECTORY_LOGIN_AVAILABLE && (
                <div>
                    <CustomDivider>{translate('divider.adLogin')}</CustomDivider>
                    <div style={{ paddingBottom: '8px' }}>
                        <Button
                            block={true}
                            onClick={() => {
                                getHistory().push('/loginAD');
                            }}
                            type={'primary'}>
                            <Icon type={'windows'} />
                            {translate('login.loginButton')}
                        </Button>
                    </div>
                </div>
            )}
            {window.config.REACT_APP_MOBILE_AUTHENTICATION_ENABLED && (
                <div>
                    <CustomDivider>{translate('divider.mobileLogin')}</CustomDivider>
                    <div style={{ paddingBottom: '8px' }}>
                        <Button
                            type="primary"
                            block
                            icon="mobile"
                            onClick={() => mobileAuth()}>
                            {translate('buttons.mobileLogin')}
                        </Button>
                    </div>
                </div>
            )}
            {showSocialOptions && (
                <div>
                    <CustomDivider>{translate('divider.socialMediaLogin')}</CustomDivider>
                    {mediaAuthError && <CustomAlert error={mediaAuthError} />}
                    {window.config.REACT_APP_FACEBOOK_APP_ID && (
                        <FacebookLogin
                            appId={window.config.REACT_APP_FACEBOOK_APP_ID}
                            callback={(resultObject) => facebookAuth(resultObject)}
                            fields={'email'}
                            render={(renderProps) => (
                                <Button
                                    block={true}
                                    className={'facebook-login'}
                                    disabled={renderProps.isDisabled}
                                    onClick={renderProps.onClick}
                                    style={{ marginBottom: '8px' }}>
                                    <img
                                        alt={'facebook icon'}
                                        src={fbIcon}
                                        style={socialMediaIconsStyle}
                                    />
                                    {translate('buttons.fbLogin')}
                                </Button>
                            )}
                        />
                    )}
                    {window.config.REACT_APP_GOOGLE_CLIENT_ID && (
                        <GoogleOAuthProvider clientId={window.config.REACT_APP_GOOGLE_CLIENT_ID}>
                            <GoogleAuthButton googleAuth={(token) => googleAuth(token)}/>
                        </GoogleOAuthProvider>
                    )}
                </div>
            )}
            {!isAdminLogin && (
                <CustomDivider className={'social-media-title'}>{translate('divider.basicLogin')}</CustomDivider>
            )}
            <Spin spinning={loading}>
                <LoginForm
                    isLoading={loading}
                    submitForm={
                        isAdminLogin
                            ? adminAuth
                            : method === undefined
                                ? basicAuth
                                : (e, data) => method(e, data)
                    }
                    translate={translate}
                />
            </Spin>
            {!embedded && showForgottenPassword && (
                <div style={{ marginTop: '8px' }}>
                    <Row gutter={16}>
                        {showForgottenPassword && (
                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={12}
                                xl={12}>
                                <ActionLink onClick={() => dispatch(toggleModal(FORGOTTEN_PASSWORD))}>
                                    {translate('login.forgottenPassword')}
                                </ActionLink>
                            </Col>
                        )}
                    </Row>
                </div>
            )}
        </div>
    );
}

CompleteLoginForm.propTypes = {
    currentLanguage: PropTypes.string,
    embedded: PropTypes.bool,
    isAdminLogin: PropTypes.bool,
    method: PropTypes.func,
    showForgottenPassword: PropTypes.bool.isRequired,
    showSocialOptions: PropTypes.bool.isRequired,
    storedFileId: PropTypes.string,
    registeredFormLoading: PropTypes.bool
};

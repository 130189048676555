import {TAB_KEY_CONTAINERS_IN_SIGNATURE_PROCESS} from '../constants/strings';

/**
 * TODO: store should only store data from the API, think it as a client side database!!
 */
export default {
    confirm: {
        isPassword: false
    },
    containerList: {
        containers: [],
        totalElements: 0,
        totalElementsSP: 0,
        totalElementsWithoutFilterSP: 0,
        containersChangeCounter: 0,
        containersInSignatureProcess: [],
        containerExist: false,
        containerSPExist: false,
        filter: undefined,
        filterSP: undefined,
        selectedContainers: [],
        selectedSignature: {},
        signatures: [],
        storedFileIdForDownload: '',
        toggleModal: false,
        waitingToSignByOthers: [],
        isLoading: false,
        pagination: { current: 1, pageSize: 10 }
    },
    deputy: {},
    documentList: {
        documents: [],
        signatures: []
    },
    documentTitles: [],
    enabled: {
        archiveButton: true,
        checkbox: false
    },
    frequentlyUsedEmailAddresses: [],
    modal: {
        data: '',
        name: '',
        visible: false
    },
    signatureProfiles: [],
    signatureProfileImages: [],
    tabs: {
        current: TAB_KEY_CONTAINERS_IN_SIGNATURE_PROCESS
    },
    tsa: {
        requireTspCredentials: undefined
    },
    workflowTemplates: [],
};

import {Button} from "antd";
import React from "react";
import PropTypes from "prop-types";

export default function PreviewNavigationButton(props) {
    const {disabled, onClick, icon, children, ...restProps} = props;

    return (
        <Button
        type={'primary'}
        style={{marginLeft: 5, marginRight: 5}}
        disabled={disabled}
        onClick={() => onClick()}
        icon={icon}
        {...restProps}
        >{children}</Button>
    );
}

PreviewNavigationButton.propTypes = {
    disabled: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.string.isRequired
};
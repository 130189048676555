/* eslint-disable react-hooks/exhaustive-deps */
import AuthenticationTabs from './AuthenticationTabs';
import Box from './Box';
import LinkButton from './buttons/LinkButton';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import WebPage from './WebPage';
import illustration from '../images/homepage-illustration.png';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row } from 'antd';
import { checkTspFailure } from '../actions/tsa';
import { createNotificationShort } from '../helper';
import { getActiveLanguage } from 'react-localize-redux';
import { getTranslate } from 'react-localize-redux';
import { removeModal } from '../actions/modal';
import { resetContainerList } from '../actions/container';
import { resetDocumentList, resetDocumentTitles } from '../actions/document';
import { resetSignatureProfile } from '../actions/signatureProfile';
import { resetFrequentlyUsedEmailAddresses } from '../actions/email';
import { resetDeputy } from '../actions/deputy';
import { resetIsPassword } from '../actions/confirm';

function Login(props) {
    const dispatch = useDispatch();
    const [isLoggedIn] = useState(!!localStorage.getItem('JWT'));
    const translate = useSelector((state) => getTranslate(state.locale));
    const currentLanguage = useSelector((state) => getActiveLanguage(state.locale).code);
    const storedFileId = useSelector((state) => state.containerList.storedFileIdForDownload);

    useEffect(() => {
        if (isLoggedIn) {
            history.push('/');
        }
        // After redirection we need to set some default states!
        // Workaround for unnecessary global states for now!
        dispatch(removeModal());
        dispatch(resetContainerList());
        dispatch(resetDocumentList());
        dispatch(checkTspFailure());
        dispatch(resetSignatureProfile());
        dispatch(resetFrequentlyUsedEmailAddresses());
        dispatch(resetDeputy());
        dispatch(resetIsPassword());
        dispatch(resetDocumentTitles());

        if (storedFileId !== '') {
            createNotificationShort(translate, {
                description: translate('please_login_description'),
                message: translate('please_login'),
                type: 'info'
            })
        }
    }, []);

    const isHungarian = () => {
        return currentLanguage === 'hu';
    };

    const { history } = props;

    return (
        <WebPage location={history.location}>
            <Row type={'flex'} justify={'space-around'}>
                {
                    window.config.REACT_APP_CUSTOM_WELCOME_ENABLED ? <iframe id="custom-welcome" src={'custom_welcome.html'} frameBorder="0" title="Custom welcome message"></iframe>
                        :

                        <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={8}>
                            <Row>
                                <Col xs={0} lg={24} style={{ margin: '64px 64px 0px 64px' }}>
                                    <div style={{ fontSize: 30, fontWeight: 'bold', marginBottom: 32 }}>
                                        {translate('login.welcome.title')}
                                    </div>
                                    <div style={{ fontSize: 22, marginBottom: 8 }}>
                                        {translate('login.welcome.subTitle')}
                                    </div>
                                    <ul style={{ fontSize: 18 }}>
                                        {[...Array(4).keys()].map((item) => (
                                            <li key={item}>{translate('login.welcome.list' + item)}</li>
                                        ))}
                                    </ul>
                                </Col>
                            </Row>
                            <Row type={'flex'} justify={'center'}>
                                <Col xs={24}>
                                    {isHungarian() && (
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <LinkButton
                                                style={{ marginRight: 32 }}
                                                text={'e-Szignó igénylés'}
                                                url={
                                                    'https://e-szigno.hu/hitelesites-szolgaltatas/tanusitvanyok/tanusitvany-igenylese.html'
                                                }
                                            />
                                            <LinkButton
                                                text={'Bővebb információ'}
                                                url={'https://e-szigno.hu/uzleti-megoldasok/alairo-megoldasok.html'}
                                            />
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                }
                <Col xs={0} sm={0} md={0} lg={8} xl={8} xxl={6} align={'middle'} style={{ alignItems: 'center' }}>
                    <img
                        alt="web-Szignó"
                        src={illustration}
                        style={{ height: 'auto', maxWidth: '400px', paddingTop: '64px' }}
                    />
                </Col>
                <Col xs={24} sm={24} md={24} lg={20} xl={20} xxl={10}>
                    <Box>
                        <AuthenticationTabs embedded={false}/>
                    </Box>
                </Col>
            </Row>
        </WebPage>
    );
}

Login.propTypes = {
    history: PropTypes.object.isRequired,
};

export default Login;

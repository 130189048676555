import BackToHomePageButton from './buttons/BackToHomePageButton';
import React from 'react';
import TextFileReader from './TextFileReader';
import WebPage from './WebPage';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';

const changelogFileEN = require('../other/changelog_ENG.md');
const changelogFileHU = require('../other/changelog_HUN.md');

export default function Changelog() {
    const translate = useSelector((state) => getTranslate(state.locale));

    let changelog;
    if (translate('language') === 'hu-HU') {
        changelog = changelogFileHU;
    } else {
        changelog = changelogFileEN;
    }

    return (
        <WebPage>
            <BackToHomePageButton />
            <TextFileReader file={changelog} />
        </WebPage>
    );
}

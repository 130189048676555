import { Button, Form, Input, Skeleton } from 'antd';
import {
    addSignatureProfile,
    refreshSignatureProfile,
    toggleDefaultSignatureProfile,
} from '../../actions/signatureProfile';
import PropTypes from 'prop-types';
import React from 'react';
import SignImage from './SignImage';
import TitleOptionsDropdown from './TitleOptionsDropdown';
import {ajax, createNotificationShort, validateFileSize, validateFileSignature} from '../../helper';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { removeModal } from '../../actions/modal';
import { SIGNATURE_PROFILE_NAME_MAX_LENGTH } from '../../constants/config';
import { updateSignatureProfileImage } from '../../actions/signatureProfileImage';
import {eventPath} from "../../utilities/eventUtilities";
import {calculateDimension, validateUploadedImageResolution} from "./CommonSignatureProfileFunctions";

class UpdateSignatureProfile extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this._customImageUploadEnabled = localStorage.getItem('customSignatureImage') === 'true';
        this._visibleSignatureConstraints = props.modalData.visibleSignatureConstraints;
        this._visibleSignatureTextProps = this._visibleSignatureConstraints.signatureTextProps;
        this._allowedExtensions = ['png'];

        this.state = {
            id: this.props.modalData.id,
            deltaPosition: {
                x: this.props.modalData.x,
                y: this.props.modalData.y,
            },
            error: null,
            width: this.props.modalData.width,
            height: this.props.modalData.height,
            backgroundImage: '',
            minConstraints: {
                width: this._visibleSignatureConstraints.minWidth,
                height: this._visibleSignatureConstraints.minHeight
            },
            maxConstraints: {
                width: this._visibleSignatureConstraints.maxWidth,
                height: this._visibleSignatureConstraints.maxHeight
            },
            file: null,
            profileName: this.props.modalData.profileName,
            isImageUploaded: false,
            hasPdfSignTitle: this.props.modalData.hasPdfSignTitle,
            hasPdfSignTitleDate: this.props.modalData.hasPdfSignTitleDate,
            imgDetails: {
                width: 860,
                height: 172,
            },
            imgSrcArray: {},
            isTitleModificationDisabled: !this._customImageUploadEnabled,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        ajax()
            .get(`/signatureImage/${this.state.id}`)
            .then((response) => {
                this._isMounted &&
                    this.setState({
                        backgroundImage: `data:image/png;base64,${response.data.image}`,
                    });
            })
            .catch(() => {
                ajax()
                    .get(`/signatureImage/1`)
                    .then((response) => {
                        this._isMounted &&
                            this.setState({
                                backgroundImage: `data:image/png;base64,${response.data.image}`,
                            });
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onDragStop = (e, d) => {
        this._isMounted &&
            this.setState({
                deltaPosition: {
                    x: d.x,
                    y: d.y,
                },
            });
    };

    onResize = (e, dir, ref) => {
        this._isMounted &&
            this.setState({
                width: +ref.style.width.replace('px', ''),
                height: +ref.style.height.replace('px', ''),
                resize: Math.ceil((this.state.width / this.props.pdfSizeX) * 100),
            });
    };

    saveSchema = (e) => {
        e.preventDefault();
        this.props.form.validateFields(async (err) => {
            if (!err) {
                let requestBody = {
                    name: this.state.profileName,
                    width: this.state.width,
                    height: this.state.height,
                    x: this.state.deltaPosition.x,
                    y: this.state.deltaPosition.y,
                    isDefault: true,
                    hasPdfSignTitle: this.state.isTitleModificationDisabled ? true : this.state.hasPdfSignTitle,
                    hasPdfSignTitleDate: this.state.hasPdfSignTitleDate,
                };

                if (this.state.isImageUploaded) {
                    const base64EncodedImage = await new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(this.state.file);
                        reader.onload = () => resolve(reader.result);
                        reader.onerror = (error) => reject(error);
                    });
                    // MAGIC NUMBER: remove data:image/png;base64, from the string
                    requestBody['signImage'] = await base64EncodedImage.substring(22);
                }

                ajax()
                    .put(`/signatureProfiles/${this.state.id}`, requestBody)
                    .then((response) => {
                        const updatedProfile = response.data.find((profile) => profile.id === this.state.id);
                        createNotificationShort(this.props.translate, {
                            description: `${updatedProfile.profileName} ${this.props.translate(
                                'schema.successfully.saved'
                            )}`,
                            message: this.props.translate('messages.success.schemaUpdate'),
                            type: 'success',
                        });
                        this.props.toggleDefaultSignatureProfile(updatedProfile.id);
                        return response;
                    })
                    .catch((error) => {
                        createNotificationShort(this.props.translate, {
                            message: this.props.translate('messages.error.schemaUpdate'),
                            type: 'error'
                        });
                        console.error(error);
                    });
                this.props.hideModal();
                let changedProfileElements = {};
                changedProfileElements.id = this.state.id;
                changedProfileElements.x = this.state.deltaPosition.x;
                changedProfileElements.y = this.state.deltaPosition.y;
                changedProfileElements.height = this.state.height;
                changedProfileElements.width = this.state.width;
                changedProfileElements.profileName = this.state.profileName;
                changedProfileElements.hasPdfSignTitle = this.state.isTitleModificationDisabled ? true : this.state.hasPdfSignTitle;
                changedProfileElements.hasPdfSignTitleDate = this.state.hasPdfSignTitleDate;
                this.props.refreshSignatureProfile(changedProfileElements);
                this.props.updateSignatureProfileImage({ id: changedProfileElements.id, image: requestBody.signImage });
            }
        });
    };

    loadImage = (file) => {
        let fileExtensionLowerCase = file.name.split('.').pop().toLowerCase();
        if (this._allowedExtensions.includes(fileExtensionLowerCase)) {
            const src = URL.createObjectURL(file);
            const img = new Image();

            img.onload = (e) => {
                if (validateUploadedImageResolution(img, this.state.minConstraints, this.props.translate) === false) {
                    return;
                }
                const loadedImageDimensions = calculateDimension(img.width, img.height, this.state.maxConstraints);
                this._isMounted &&
                this.setState({
                    width: loadedImageDimensions.width,
                    height: loadedImageDimensions.height,
                    file: file,
                    isImageUploaded: true,
                    backgroundImage: `${src}`,
                    imgDetails: {
                        width: eventPath(e)[0].width,
                        height: eventPath(e)[0].height
                    },
                });
            };
            img.src = src;
        } else {
            createNotificationShort(this.props.translate, {
                description: this.props.translate('accepted.imageFormats'),
                message: this.props.translate('badFileFormat'),
                type: 'error',
            });
        }
    };

    handleImageUpload = async (event) => {
        const file = event.target.files[0];
        this.imageUpload.value = null;
        try {
            validateFileSize(file);
            await validateFileSignature(file, 'png').then((result) => {
                if (result.isError === true) {
                    createNotificationShort(this.props.translate, {
                        description: this.props.translate('accepted.imageFormats'),
                        message: this.props.translate('badFileFormat'),
                        type: 'error',
                    });
                }
            });
        } catch (error) {
            // I don't know why it works like this, but at this point I will just accept it
            if (error.isError === false) {
                this.loadImage(file);
            }
            if (error.message === 'USER_MAX_UPLOAD_SIZE_ERROR') {
                createNotificationShort(this.props.translate, {
                    description: this.props.translate(`errorCodes.${error.message}`),
                    message: this.props.translate('notifications.uploadFailure.message'),
                    type: 'error',
                });
            }
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { width, deltaPosition, minConstraints, maxConstraints, backgroundImage, imgDetails } = this.state;
        const { modalData, translate } = this.props;
        const visibleSignatureTextProps = this._visibleSignatureConstraints.signatureTextProps;
        return (
            <div>
                <nav
                    style={{
                        textAlign: 'center',
                        padding: 16,
                        marginBottom: 16,
                        marginTop: 26,
                        backgroundColor: '#FAFAFA',
                        border: '1px solid #e8e8e8',
                    }}
                >
                    <Form onSubmit={this.saveSchema}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Form.Item style={{ marginBottom: '0px' }}>
                                <Button
                                    type="primary"
                                    onClick={() => this.imageUpload.click()}
                                    disabled={localStorage.getItem('customSignatureImage') !== 'true'}
                                >
                                    {translate('image.upload')}
                                    <input
                                        ref={(ref) => (this.imageUpload = ref)}
                                        id="imageUpload"
                                        onChange={this.handleImageUpload}
                                        type="file"
                                        accept="image/png, .png"
                                        multiple
                                        style={{ display: 'none' }}
                                    />
                                </Button>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: '0px' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <TitleOptionsDropdown
                                        changeDate={(e) =>
                                            this._isMounted && this.setState({ hasPdfSignTitleDate: e.target.checked })
                                        }
                                        changeTitle={(e) =>
                                            this._isMounted && this.setState({ hasPdfSignTitle: e.target.checked })
                                        }
                                        hasDate={this.state.hasPdfSignTitleDate}
                                        hasTitle={this.state.hasPdfSignTitle}
                                        isTitleModificationDisabled={this.state.isTitleModificationDisabled}
                                        translate={translate}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: '0px' }}>
                                {getFieldDecorator('schemaname', {
                                    initialValue: modalData.profileName,
                                    rules: [{ required: true, message: translate('fill.empty.field'), max: SIGNATURE_PROFILE_NAME_MAX_LENGTH }],
                                })(
                                    <Input
                                        style={{ width: '250px' }}
                                        placeholder={translate('schemaname')}
                                        maxLength={SIGNATURE_PROFILE_NAME_MAX_LENGTH}
                                        onChange={(e) =>
                                            this._isMounted && this.setState({ profileName: e.target.value })
                                        }
                                    />
                                )}
                            </Form.Item>
                            <Form.Item style={{ marginBottom: '0px' }}>
                                <Button type={'primary'} htmlType="submit">
                                    {translate('button.done')}
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                </nav>
                <div
                    className="signature-profile-blank-page"
                    style={{
                        width: this.props.pdfSizeX,
                        height: this.props.pdfSizeY,
                    }}
                >
                    <SignImage
                        props={{
                            width: this.state.width,
                            height: this.state.height,
                            deltaPosition: deltaPosition,
                            pdfSizeX: this.props.pdfSizeX,
                            minConstraints: minConstraints,
                            maxConstraints: maxConstraints,
                            translate: translate,
                            onDragStop: this.onDragStop,
                            onResize: this.onResize,
                            namePosition: this.state.hasPdfSignTitle,
                            datePosition: this.state.hasPdfSignTitleDate,
                            fontSize: Math.floor((width / imgDetails.width) * visibleSignatureTextProps.fontSizeMultiplier),
                            isResizingAndDraggingDisabled: this.state.isTitleModificationDisabled,
                            signatureTextProps: visibleSignatureTextProps
                        }}
                        backgroundImage={backgroundImage}
                    />
                    <Skeleton title paragraph={true} />
                    <Skeleton paragraph={{ rows: 5 }} />
                    <Skeleton paragraph={true} />
                    <Skeleton paragraph={true} />
                </div>
            </div>
        );
    }
}

UpdateSignatureProfile.propTypes = {
    pdfSizeX: PropTypes.number,
    pdfSizeY: PropTypes.number,
    validateFields: PropTypes.func,
    translate: PropTypes.func,
    onClose: PropTypes.func,
    form: PropTypes.object,
    hideModal: PropTypes.func,
    addSignatureProfile: PropTypes.func,
    toggleDefaultSignatureProfile: PropTypes.func,
    refreshSignatureProfile: PropTypes.func,
    modalData: PropTypes.object.isRequired,
    updateSignatureProfileImage: PropTypes.func,
};

UpdateSignatureProfile.defaultProps = {
    pdfSizeX: 595,
    pdfSizeY: 842,
};

const mapStateToProps = (state) => {
    return {
        translate: getTranslate(state.locale),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        hideModal: () => dispatch(removeModal()),
        addSignatureProfile: (profile) => dispatch(addSignatureProfile(profile)),
        toggleDefaultSignatureProfile: (index) => dispatch(toggleDefaultSignatureProfile(index)),
        refreshSignatureProfile: (profile) => dispatch(refreshSignatureProfile(profile)),
        updateSignatureProfileImage:(profile) => dispatch(updateSignatureProfileImage(profile))
    };
};

const UpdateSignatureSchemaForm = Form.create({ name: 'update_signature_schema' })(UpdateSignatureProfile);

export default connect(mapStateToProps, mapDispatchToProps)(UpdateSignatureSchemaForm);

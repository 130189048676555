import { setSignModeLocalStorageFromRequest } from '../helper';

const authService = {
    /**
     * FIXME: user value cannot be predictable in a secure session!!!
     */
    isRegistered() {
        let user = localStorage.getItem('user');
        return user !== undefined && user !== null && user !== '';
    },
    getUserEmail() {
        return localStorage.getItem('user');
    },
    registeredLogin(email) {
        localStorage.setItem('user', email);
    },
    setRegistrationType(regType) {
        localStorage.setItem('regType', regType);
    },
    getRegistrationType() {
        return localStorage.getItem('regType');
    },
    setUserOid(userOid) {
        localStorage.setItem('userOid', userOid);
    },
    getUserOid() {
        return localStorage.getItem('userOid');
    },
    setNotificationModes(pushNotification, emailNotification) {
        localStorage.setItem('pushNotification', pushNotification);
        localStorage.setItem('emailNotification', emailNotification);
    },
    setSecondFactor(secondFactor) {
        localStorage.setItem('secondFactor', secondFactor);
    },
    getSecondFactor() {
        return 'true' === localStorage.getItem('secondFactor');
    },
    setSignatureSetting(secondFactor) {
        localStorage.setItem('signatureSetting', secondFactor);
    },
    setSigningWithTimestampOnly(signingWithTimestampOnly) {
        localStorage.setItem('signingWithTimestampOnly', signingWithTimestampOnly);
    },
    getSigningWithTimestampOnly() {
        return 'true' === localStorage.getItem('signingWithTimestampOnly');
    },
    logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('admin');
        localStorage.removeItem('JWT');
    },
    setUserProfile(data) {
        localStorage.setItem('createCompanyRegisterEDossier', data.createCompanyRegisterEDossier);
        localStorage.setItem('customSignatureImage', data.customSignatureImage);
        localStorage.setItem('massDocumentSignMaxNumber', data.massDocumentSignMaxNumber);
        localStorage.setItem('microsecCertsOnly', data.microsecCertsOnly);
        localStorage.setItem('mobileScanning', data.mobileScanning);
        localStorage.setItem('participateWorkflow', data.participateWorkflow);
        localStorage.setItem('profileCode', data.profileNames.code);
        localStorage.setItem('rank', data.rank);
        localStorage.setItem('signAsCompanyRepresentative', data.signAsCompanyRepresentative);
        localStorage.setItem('signContainers', data.signContainers);
        localStorage.setItem('signPDF', data.signPDF);
        localStorage.setItem('startWorkflow', data.startWorkflow);
        localStorage.setItem('viewOnly', data.viewOnly);
        this.setIsMicrosecOIDEnabled();
    },
    setSignMode(signMode) {
        setSignModeLocalStorageFromRequest(signMode);
    },
    setIsMicrosecOIDEnabled() {
        //frontend tesztek miatt kell az if
        if (window.config) {
            localStorage.setItem('isMicrosecOIDEnabled', window.config.REACT_APP_USER_SERVICE_LEVEL_MANAGER_AVAILABLE);
        } else {
            localStorage.setItem('isMicrosecOIDEnabled', false);
        }
    },
    getIsMicrosecOIDEnabled() {
        return localStorage.getItem('isMicrosecOIDEnabled') === 'true';
    },
    setPasswordEnabled(setPasswordEnabled) {
        localStorage.setItem('setPasswordEnabled', setPasswordEnabled);
    },
    getPasswordEnabled() {
        return 'true' === localStorage.getItem('setPasswordEnabled');
    },
    setContainerPageSize(containerPageSize) {
        if (containerPageSize) {
            localStorage.setItem('containerPageSize', containerPageSize);
        } else {
            localStorage.removeItem('containerPageSize');
        }

    },
    getContainerPageSize() {
        return parseInt(localStorage.getItem('containerPageSize'));
    },
    adminLogin() {
        localStorage.setItem('admin', 'true');
    },
    isAdmin() {
        return 'true' === localStorage.getItem('admin');
    },
    hasJWT() {
        return typeof localStorage.getItem('JWT') !== 'undefined';
    },
    getPackageName() {
        return localStorage.getItem('profileCode');
    },
    canCreateCegeljaras() {
        return localStorage.getItem('createCompanyRegisterEDossier') === 'true';
    }
};

export default authService;

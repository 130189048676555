/* eslint-disable no-case-declarations */
import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.frequentlyUsedEmailAddresses, action) {
    switch (action.type) {
        case types.REFRESH_FREQUENTLY_USED_EMAIL:
            const elements = action.payload.data.map((value) => value);
            return [...elements];
        case types.RESET_FREQUENTLY_USED_EMAIL:
            return [[]];
        default:
            return state;
    }
}

import Box from './Box';
import CustomAlert from './CustomAlert';
import FormItem from './form/FormItem';
import PropTypes from 'prop-types';
import React from 'react';
import authService from '../services/authService';
import { ajax, createNotificationShort } from '../helper';
import { Button, Form, Icon, Input } from 'antd';

class SetOidForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onError(error) {
        if (error.response) {
            this._isMounted && this.setState({ error: error.response.data });
        } else {
            this._isMounted && this.setState({ error: { code: 'HTTP_NO_RESPONSE' } });
        }
    }

    setOIDAction(oid, password) {
        ajax()
            .post('/microsecOID', {
                oid: oid,
                password: password,
            })
            .then((response) => {
                const { translate, onFinished, setUserProfile } = this.props;
                createNotificationShort(translate, {
                    message: translate('messages.success.setMicrosecOID'),
                    type: 'success',
                });
                const { data } = response;
                setUserProfile(data.userProfile);
                onFinished();
            })
            .catch((error) => {
                this.onError(error);
            });
    }

    handleOidSettings = (e) => {
        e.preventDefault();
        const { form } = this.props;
        form.validateFieldsAndScroll((clientErr, values) => {
            if (!clientErr) {
                this.setOIDAction(values.oidToSet, values.passwordToSet);
            }
        });
    };

    render() {
        const { translate, form } = this.props;
        const { error } = this.state;
        return (
            <Form onSubmit={this.handleOidSettings}>
                <FormItem>{error && <CustomAlert error={error} />}</FormItem>
                <FormItem>
                    {form.getFieldDecorator('oidToSet', {
                        rules: [{ required: true, message: translate('form.required') }],
                    })(
                        <Input
                            ref={this.props.inputRef}
                            type="text"
                            prefix={<Icon type="user" />}
                            placeholder={translate('inputs.oidSettings')}
                        />
                    )}
                </FormItem>
                <FormItem>
                    {form.getFieldDecorator('passwordToSet', {
                        rules: [{ required: true, message: translate('form.required') }],
                    })(
                        <Input
                            type="password"
                            prefix={<Icon type="lock" />}
                            placeholder={translate('inputs.oidPassword')}
                        />
                    )}
                </FormItem>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {translate('buttons.submitOidAndPassword')}
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

SetOidForm.propTypes = {
    form: PropTypes.object.isRequired,
    inputRef: PropTypes.object.isRequired,
    onFinished: PropTypes.func.isRequired,
    setUserProfile: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired
};

const WrappedSetOidForm = Form.create()(SetOidForm);

export default class SettingsOid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            hasOid: false,
            microsecRegistration: '',
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.getMicrosecRegistration();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onError(error) {
        if (error.response) {
            this._isMounted && this.setState({ error: error.response.data.error });
        } else {
            this._isMounted && this.setState({ error: { code: 'HTTP_NO_RESPONSE' } });
        }
    }

    getMicrosecRegistration() {
        const { currentLanguage } = this.props;
        ajax()
            .get(`/microsecRegistrationInformation/${currentLanguage}`)
            .then((response) => {
                const { data } = response;
                this._isMounted &&
                    this.setState({ error: null, microsecRegistration: data.serviceLevel, hasOid: data.hasOid });
            })
            .catch((error) => {
                this.onError(error);
            });
    }

    deleteOIDAction() {
        ajax()
            .delete('/microsecOID')
            .then((response) => {
                const { translate, setUserProfile } = this.props;
                createNotificationShort(translate, {
                    message: translate('messages.success.deleteMicrosecOID'),
                    type: 'success',
                });
                const { data } = response;
                setUserProfile(data.userProfile);
                this._isMounted &&
                    this.setState({ error: null, microsecRegistration: data.serviceLevel, hasOid: data.hasOid });
                this.getMicrosecRegistration();
            })
            .catch((error) => {
                this.onError(error);
            });
    }

    oidViewDialog() {
        const { translate } = this.props;
        const { microsecRegistration } = this.state;
        return (
            <span id={'oidView'}>
                <p>
                    {translate('texts.microsecPackage')}{' '}
                    <span style={{ fontWeight: 'bold' }}>{microsecRegistration}</span>
                </p>
                <Button
                    disabled={authService.getRegistrationType() === 'oid'}
                    type="danger"
                    onClick={() => {
                        this.deleteOIDAction();
                    }}
                >
                    {translate('buttons.clearOid')}
                </Button>
            </span>
        );
    }

    oidSetDialog() {
        const { translate, setUserProfile, inputRef } = this.props;
        return (
            <span id={'oidSet'}>
                <p>{translate('texts.setMicrosecOID')}</p>
                <WrappedSetOidForm
                    inputRef={inputRef}
                    translate={translate}
                    setUserProfile={setUserProfile}
                    onFinished={() => {
                        this.getMicrosecRegistration();
                    }}
                />
            </span>
        );
    }

    render() {
        const { translate } = this.props;
        const { error, hasOid } = this.state;
        return (
            <React.Fragment>
                <h3
                    style={{
                        marginTop: 30,
                    }}
                >
                    {translate('titles.setMicrosecOID')}
                </h3>
                {error && <CustomAlert error={error} />}
                <Box>{hasOid ? this.oidViewDialog() : this.oidSetDialog()}</Box>
            </React.Fragment>
        );
    }
}

SettingsOid.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
    inputRef: PropTypes.object.isRequired,
    setUserProfile: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired
};

import * as containerActions from '../actions/container';
import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { getAttributeUsage } from '../actions/user';
import { removeModal, toggleModal } from '../actions/modal';
import { ajax, createNotificationShort } from '../helper';
import { ATTRIBUTE_ROLES, CLOSURE, SELECT_CERTIFICATE, SIGN, SIGNATURE_POSITION } from '../constants/modalNames';
import {
    SIGNING_TYPE_COUNTER_SIGN, SIGNING_TYPE_CREATE_EACKNOWLEDGEMENT,
    SIGNING_TYPE_SIGN,
    TAB_KEY_DEFAULT_CONTAINERS
} from '../constants/strings';
import {setCurrentTab} from "../actions/currentTab";

class SignHidden extends React.Component {
    start(signingType, withComment) {
        if (withComment) {
            this.props.openClosureModal({
                getComment: (type, message) => this.getCommentData(signingType, type, message)
            });
        } else {
            this.handleSign(signingType, null, null);
        }
    }

    startSign(signingType, selectedRole, certificate, commentType, commentMessage) {
        const isAcknowledgement = signingType === SIGNING_TYPE_CREATE_EACKNOWLEDGEMENT;
        ReactGA.event({
            category: 'User',
            action: isAcknowledgement ? 'Creating acknowledgement' : 'Signing'
        });
        const {
            showSignaturePositioning,
            container,
            showSignModal,
            hideSignModal,
            currentTab,
            imageHeight,
            imageWidth,
            documentId
        } = this.props;

        if (container.type === 'pdf' && !isAcknowledgement) {
            showSignaturePositioning({
                signModalData: [],
                previewContainerList: [],
                container,
                currentTab,
                imageHeight,
                imageWidth,
                certificate: certificate,
                attributeRole: selectedRole
            });
        } else {
            showSignModal({
                signModalData: [{ containerId: container.id, pdfSettings: null, documentId: documentId }],
                signingType: signingType,
                attributeRole: selectedRole,
                certificate: certificate,
                commentType: commentType,
                commentMessage: commentMessage,
                onFinished: (result) => {
                    if (result.succeeded) {
                        setTimeout(() => {
                            hideSignModal();
                        }, 1000);
                        if (isAcknowledgement) {
                            const { updateContainers } = this.props;
                            if (currentTab !== TAB_KEY_DEFAULT_CONTAINERS) {
                                // should not happen
                                createNotificationShort(this.props.translate, {
                                    message: this.props.translate('errorCodes.USER_INVALID_PARAMETERS'),
                                    type: 'error'
                                });
                            }
                            updateContainers();
                        } else {
                            const { updateContainers } = this.props;
                            setCurrentTab(TAB_KEY_DEFAULT_CONTAINERS);
                            updateContainers();
                        }
                    }
                }
            });
        }
    }

    getCommentData(signingType, commentType, commentMessage) {
        this.handleSign(signingType, commentType, commentMessage);
    }

    handleSign(signingType, type, message) {
        if (window.config.REACT_APP_ATTRIBUTE_CERT_USAGE) {
            this.props.getAttributeUsage().then((response) => {
                if (response.data.attributeCertUsage) {
                    ajax()
                        .get('/user/certificate')
                        .then(() => {
                            this.props.openAttributeRolesModal({
                                startSign: (selectedRole) => this.startSign(signingType, selectedRole, null, type, message)
                            });
                        })
                        .catch(() => {
                            this.props.openSelectCertificateModal({
                                startSign: (selectedRole, certificate) =>
                                    this.startSign(signingType, selectedRole, certificate, type, message)
                            });
                        });
                } else {
                    this.startSign(signingType, null, null, type, message);
                }
            });
        } else {
            this.startSign(signingType, null, null, type, message);
        }
    }

    render() {
        return (
            <div style={{ display: 'none' }}>
                <Button
                    id={this.props.idPrefix + '_containerSign'}
                    onClick={() => {
                        this.start(SIGNING_TYPE_SIGN);
                    }}>
                    sign
                </Button>
                <Button
                    id={this.props.idPrefix + '_containerCounterSign'}
                    onClick={() => {
                        this.start(SIGNING_TYPE_COUNTER_SIGN);
                    }}>
                    countersign
                </Button>
                <Button
                    id={this.props.idPrefix + '_containerSignWithCommentdoc'}
                    onClick={() => {
                        this.start(SIGNING_TYPE_SIGN, true);
                    }}>
                    containerSignWithCommentdoc
                </Button>
                <Button
                    id={this.props.idPrefix + '_containerCreateAcknowledgement'}
                    onClick={() => {
                        this.start(SIGNING_TYPE_CREATE_EACKNOWLEDGEMENT);
                    }}>
                    createAcknowledgement
                </Button>
                <Button
                    id={this.props.idPrefix + '_signDocument'}
                    onClick={() => {
                        this.start(SIGNING_TYPE_SIGN)
                    }}>
                    signDocument
                </Button>
            </div>
        );
    }
}

SignHidden.propTypes = {
    addNewContainerToDefaultContainerList: PropTypes.func.isRequired,
    container: PropTypes.object.isRequired,
    currentTab: PropTypes.string,
    getAttributeUsage: PropTypes.func.isRequired,
    hideSignModal: PropTypes.func.isRequired,
    imageHeight: PropTypes.number,
    imageWidth: PropTypes.number,
    openAttributeRolesModal: PropTypes.func.isRequired,
    openSelectCertificateModal: PropTypes.func.isRequired,
    pdfFormSigning: PropTypes.bool.isRequired,
    showSignModal: PropTypes.func.isRequired,
    showSignaturePositioning: PropTypes.func.isRequired,
    updateContainers: PropTypes.func.isRequired,
    updateSelectedContainerSignatures: PropTypes.func.isRequired,
    documentId: PropTypes.string,
    idPrefix: PropTypes.string.isRequired
};

const mapDispatchToProps = (dispatch) => {
    return {
        addNewContainerToDefaultContainerList: (containerId) => {
            dispatch(containerActions.newContainer(containerId));
        },
        getAttributeUsage: () => {
            return dispatch(getAttributeUsage());
        },
        hideSignModal: () => {
            dispatch(removeModal());
        },
        openAttributeRolesModal: (callback) => {
            dispatch(toggleModal(ATTRIBUTE_ROLES, callback));
        },
        openSelectCertificateModal: (callback) => {
            dispatch(toggleModal(SELECT_CERTIFICATE, callback));
        },
        openClosureModal: (callback) => {
            return dispatch(toggleModal(CLOSURE, callback));
        },
        showSignModal: (data) => {
            dispatch(toggleModal(SIGN, data));
        },
        showSignaturePositioning: (data) => {
            dispatch(toggleModal(SIGNATURE_POSITION, data));
        },
        updateContainers: () => {
            let containerToSelect;
            dispatch(containerActions.getContainers())
                .then((containers) => {
                    if (containers.containers.length > 0) {
                        containerToSelect = containers.containers[0];
                    }
                })
                .then(() => {
                    dispatch(containerActions.getContainersInSignatureProcess()).finally(() => {
                        dispatch(containerActions.updateSelectedContainerList([containerToSelect]));
                    });
                });
        },
        updateSelectedContainerSignatures: (container) => {
            dispatch(containerActions.updateContainerData(container.id));
        }
    };
};

const mapStateToProps = (state) => {
    return {
        currentTab: state?.tabs?.current
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignHidden);

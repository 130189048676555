import * as types from './actionTypes';

export const resetCheckbox = () => {
    return { type: types.RESET_CHECKBOX_ENABLE };
};

export const enableArchiveButton = () => {
    return { type: types.ENABLE_ARCHIVE_BUTTON };
};

export const disableArchiveButton = () => {
    return { type: types.DISABLE_ARCHIVE_BUTTON };
};

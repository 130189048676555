import {
    ADD_SIGNATURE_PROFILE_IMAGE,
    DELETE_SIGNATURE_PROFILE_IMAGE,
    UPDATE_SIGNATURE_PROFILE_IMAGE
} from './actionTypes';

export const addSignatureProfileImage = (profile) => {
    return {
        type: ADD_SIGNATURE_PROFILE_IMAGE,
        payload: profile,
    };
};

export const deleteSignatureProfileImage = (profile) => {
    return {
        type: DELETE_SIGNATURE_PROFILE_IMAGE,
        payload: profile,
    };
};

export const updateSignatureProfileImage = (profile) => {
    return {
        type: UPDATE_SIGNATURE_PROFILE_IMAGE,
        payload: profile,
    };
};

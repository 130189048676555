import * as types from '../actions/actionTypes';
import { ajax } from '../helper';

export const getUser = (email) => {
    return function () {
        return ajax()
            .get(`/admin/users/${encodeURIComponent(email)}`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.error(error);
            });
    };
};

export const getUsers = (filter, orderBy, order, userLimit, userOffset, onlyAdmins) => {
    return function () {
        let params = '';
        filter && filter !== '' && (params = params + `filter=${filter}&`);
        params = params + `orderBy=${orderBy}&order=${order}&userLimit=${userLimit}&userOffset=${userOffset}&onlyAdmins=${onlyAdmins}`;

        return ajax()
            .get(`/admin/users?${params}`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.error(error);
            });
    };
};

export const getUserContainers = (email) => async () => {
    return await ajax().get(`/admin/users/${encodeURIComponent(email)}/containers`);
};

export const createUsers = (inputValues) => {
    return function () {
        return ajax()
            .post(`/admin/user`, inputValues)
            .then((response) => {
                return response;
            });
    };
};

export const deleteUser = (email) => async () => {
    return await ajax().delete(`/admin/users/${encodeURIComponent(email)}`);
};

const signingProcessesSortKeyMap = {
    creationDate: 'creationDatetime',
    fileName: 'container_title',
    status: 'participants_state'
}
const signingProcessesFilterStatusMap = {
    'Aláírt': 'FINISHED',
    'Elutasított': 'REFUSED',
    'Folyamatban': 'PENDING'
}

const getQueryParamsForSigningProcesses = (pagination, filters, sorter) => {
    const params = new URLSearchParams();

    // Parse pagination
    params.append('page', pagination?.current >= 0 ? pagination?.current - 1 : 0);
    params.append('size', pagination?.pageSize > 0 ? pagination?.pageSize : 10);

    // Parse filters
    if (filters?.createdBy?.length > 0) {
        params.append('filterOwnerUserEmailAddress', filters.createdBy)
    }
    if (filters?.status?.length > 0) {
        let filterStatusString = filters.status
            .map(status => signingProcessesFilterStatusMap[status])
            .toString();
        
        params.append('filterStatus', filterStatusString)
    }

    // Parse sorter
    if (sorter && sorter.columnKey) {
        let sortOrder = (sorter.order === 'ascend' ? 'asc' : 'desc');
        params.append('sort', signingProcessesSortKeyMap[sorter.columnKey] + ',' + sortOrder);
    }
    else {
        params.append('sort', 'creationDatetime,desc');
    }

    // Return formatted query params
    return params.size > 0 ? '?' + params.toString() : '';
}

export const getSigningProcesses = (pagination, filters, sorter) => {
    return function (dispatch) {
        let query = getQueryParamsForSigningProcesses(pagination, filters, sorter);
        
        dispatch(updateSigningProcessesLoading(true));
        return ajax()
            .get('/admin/containers/signingProcesses' + query)
            .then((response) => {
                dispatch(updateSigningProcesses(response.data));
                return response;
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => dispatch(updateSigningProcessesLoading(false)));
    };
};

export const getSigningProcessesByGuid = (guid) => {
    return function (dispatch) {
        return ajax()
            .get(`/admin/containers/signingProcesses/${guid}`)
            .then((response) => {
                dispatch(updateSigningProcessesByContainer(response.data));
                return response;
            })
            .catch((error) => {
                console.error(error);
            });
    };
};

export const deleteSigningProcess = (guid) => {
    return function (dispatch) {
        return ajax()
            .delete(`/admin/containers/signingProcesses/${guid}`)
            .then((response) => {
                dispatch(updateSigningProcesses(response.data));
                return response;
            })
            .catch((error) => {
                console.error(error);
            });
    };
};

export const removeUserFromSigningProcess = (guid, signingOrder) => {
    return function (dispatch) {
        return ajax()
            .delete(`/admin/containers/signingProcesses/${guid}/remove/${signingOrder}`)
            .then((response) => {
                dispatch(updateSigningProcessesByContainer(response.data));
                return response;
            })
            .catch((error) => {
                console.error(error);
            });
    };
};

export const resendContainerToUser = (guid, signingOrder) => {
    return function (dispatch) {
        return ajax()
            .get(`/admin/containers/signingProcesses/${guid}/resend/${signingOrder}`)
            .then((response) => {
                dispatch(updateSigningProcessesByContainer(response.data));
                return response;
            })
            .catch((error) => {
                console.error(error);
            });
    };
};

export const saveDeputy = (deputyData) => async () => {
    return await ajax().post(`/admin/deputies`, { ...deputyData });
};

export const updateDeputy = (deputyData) => async () => {
    return await ajax().put(`/admin/deputies/${encodeURIComponent(deputyData.userEmail)}`, {
        userEmail: deputyData.userEmail,
        deputyEmail: deputyData.deputyEmail,
        endDate: deputyData.endDate
    });
};

export const deleteDeputyData = (deputyData) => async () => {
    return await ajax().delete(`/admin/deputies/${encodeURIComponent(deputyData.userEmail)}`);
};

export const updateSigningProcessesLoading = (isLoading) => {
    return {
        type: types.UPDATE_WAITING_TO_BE_SIGNED_BY_OTHERS_IS_LOADING,
        payload: isLoading
    };
};

export const updateSigningProcesses = (containersPage) => {
    return {
        type: types.UPDATE_WAITING_TO_BE_SIGNED_BY_OTHERS_PAGEABLE,
        payload: containersPage,
    };
};

export const updateSigningProcessesByContainer = (container) => {
    return {
        type: types.UPDATE_WAITING_TO_BE_SIGNED_BY_OTHERS_BY_CONTAINER,
        payload: container,
    };
};

export const sendGenerationData = (generationData) => {
    return function () {
        return ajax()
            .post(`/admin/generate`, { ...generationData })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.error(error);
        });
    };
};

export const sendCleanUpRequest = (data) => {
    return function () {
        return ajax()
            .post(`/admin/cleanUp`, { ...data })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

import DeleteButton from './buttons/DeleteButton';
import PropTypes from 'prop-types';
import React from 'react';
import Search from 'antd/lib/input/Search';
import theme from '../theme';
import { Button, Col, Divider, Icon, Row, Table, Tooltip } from 'antd';
import { createNotification, createNotificationShort, formatDate, getStatusFilters, translateStatus } from '../helper';
import { connect } from 'react-redux';
import { confirmationModal } from './modals/ConfirmationModal';
import {
    deleteSigningProcess,
    getSigningProcesses,
    getSigningProcessesByGuid,
    removeUserFromSigningProcess,
    resendContainerToUser,
} from '../actions/admin';
import { getTranslate } from 'react-localize-redux/lib/index';
import { toggleModal } from '../actions/modal';
import { getMetadata } from '../actions/workflow';
import { META_DATA } from '../constants/modalNames';

class AdminSigningProcesses extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            searchText: '',
            selectedWorkflow: '',
            filteredInfo: null,
            filters: {},
            sorter: { columnKey: 'creationDate', order: 'descend' }
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.getSigningProcesses();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    workflowSelected(item) {
        const itemId = item.id;
        this.props.getSigningProcessesByGuid(itemId);
        this._isMounted && this.setState({ selectedWorkflow: itemId });
    }

    getContainerDatas(data) {
        let containerDataList = [];
        data.forEach((object) => {
            containerDataList.push({
                id: object.guid,
                fileName: object.fileName,
                creationDate: object.creationDate,
                createdBy: object.createdBy,
                comment: object.comment,
                status: translateStatus(this.props.translate, object.status),
            });
        });
        return containerDataList;
    }

    getProcessDatas(guid) {
        let processDataList = [];
        for (let i = 0; i < this.props.waitingToSignByOthers.length; i++) {
            if (this.props.waitingToSignByOthers[i].guid === guid) {
                this.props.waitingToSignByOthers[i].waitingToSignByOthers.forEach((processInfos) => {
                    processDataList.push({
                        name: processInfos.user,
                        status: processInfos.status,
                        state: this.props.translate('workflow.details.status.' + processInfos.status),
                        message: processInfos.message,
                        signingorder: processInfos.bulkSigningOrder,
                    });
                });
            }
        }
        return processDataList;
    }

    deleteBulkSession(guid) {
        this.props
            .deleteSigningProcess(guid)
            .then(() => {
                createNotificationShort(this.props.translate, {
                    message: this.props.translate('notifications.deleteSigningProcess.success'),
                    type: 'success',
                });
                this._isMounted && this.setState({ selectedWorkflow: '' });
                this.getSigningProcesses();
            })
            .catch((e) => {
                createNotification(this.props.translate, e.response.data.code, {
                    message: this.props.translate('notifications.deleteSigningProcess.failure'),
                    type: 'error',
                });
            });
    }

    removeUserFromSession(guid, signingOrder) {
        this.props
            .removeUserFromSigningProcess(guid, signingOrder)
            .then(() => {
                this.getSigningProcesses();
            })
            .catch((e) => {
                createNotification(this.props.translate, e.response.data.code, {
                    message: this.props.translate('messages.error.clearTsaCredentials'),
                    type: 'error',
                });
            });
    }

    resendToUser(guid, signingOrder) {
        this.props
            .resendContainerToUser(guid, signingOrder)
            .then(() => {
                createNotificationShort(this.props.translate, {
                    message: this.props.translate('notifications.sendInEmail.success'),
                    type: 'success',
                });
            })
            .catch((e) => {
                createNotification(this.props.translate, e.response.data.code, {
                    message: this.props.translate('notifications.sendInEmail.failure'),
                    type: 'error',
                });
            });
    }

    getSigningProcesses (pagination) {
        this.props.getSigningProcesses(
            pagination ? pagination : this.props.pagination,
            this.state.filters,
            this.state.sorter
        )
    }

    onSearch = (text) => {
        let filters = this.state.filters;
        filters['createdBy'] = text;
        this.setState({ filters }, () => this.getSigningProcesses());
    };

    handleInputChange = (e) => {
        const newText = e.target.value;
        this.setState({ searchText: newText });
    };

    handleChange = (pagination, filters, sorter) => {
        this._isMounted && this.setState({ filteredInfo: filters });
        this.setState({ filters, sorter }, () => this.getSigningProcesses(pagination));
    };

    render() {
        let { filteredInfo, selectedWorkflow } = this.state;
        let { translate } = this.props;
        filteredInfo = filteredInfo || {};


        const containerColumns = [
            {
                title: translate('home.title'),
                dataIndex: 'fileName',
                key: 'fileName',
                sorter: true,
                sortDirections: ['descend', 'ascend'],
                render: (text, record) => (
                    <div style={{ display: 'table', wordWrap: 'break-word', wordBreak: 'break-all' }}>
                        <div
                            style={{
                                display: 'table-cell',
                                verticalAlign: 'middle',
                            }}
                        >
                            {record.fileName === 'deleted_file'
                                ? translate('waitingtosignbyothers.deletedContainer')
                                : record.fileName}
                        </div>
                    </div>
                ),
            },
            {
                title: translate('attributes.createdBy'),
                dataIndex: 'createdBy',
                key: 'createdBy',
                render: (text, record) =>
                    record.createdBy === 'deleted_user'
                        ? translate('waitingtosignbyothers.deletedUser')
                        : record.createdBy,
            },
            {
                title: translate('waitingtosignbyothers.details.state'),
                dataIndex: 'status',
                key: 'status',
                filters: getStatusFilters(translate),
                filteredValue: filteredInfo.status || null,
                onFilter: (value, record) => record.status.includes(value),
                sorter: true,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: translate('waitingtosignbyothers.details.comment'),
                dataIndex: 'comment',
                key: 'comment',
                width: 100,
                ellipsis: true,
            },
            {
                title: translate('attributes.signatureProcessStartDate'),
                dataIndex: 'creationDate',
                key: 'creationDate',
                defaultSortOrder: 'descend',
                sorter: true,
                sortDirections: ['descend', 'ascend'],
                align: 'right',
            },
        ];
        const ContainerDatas =
            this.state.data === null
                ? this.getContainerDatas(this.props.waitingToSignByOthers)
                : this.getContainerDatas(this.state.data);
        const detailsColumns = [
            {
                title: translate('inputs.oidSettings'),
                dataIndex: 'name',
                key: 'name',
                sorter: (a, b) => {
                    return a.name.localeCompare(b.name);
                },
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: translate('waitingtosignbyothers.details.state'),
                dataIndex: 'state',
                key: 'state',
                sorter: (a, b) => {
                    return a.state.localeCompare(b.state);
                },
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: translate('waitingtosignbyothers.details.message'),
                dataIndex: 'message',
                key: 'message',
            },
            {
                title: translate('waitingtosignbyothers.details.signingorder'),
                dataIndex: 'signingorder',
                key: 'signingorder',
                sorter: (a, b) => a.signingorder - b.signingorder,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: '',
                key: 'remove',
                render: (text, record) => (
                    <div>
                        <Button
                            type={'primary'}
                            key={'forward'}
                            disabled={record.status !== 'WAITING_TO_SIGN' && record.status !== 'WAITING_TO_APPROVE'}
                            title={translate('buttons.resend.container')}
                            onClick={(e) => {
                                e.preventDefault();
                                this.resendToUser(selectedWorkflow, record.signingorder);
                            }}
                            icon={'reload'}
                        />
                        <Divider type="vertical" />
                        <DeleteButton
                            disabled={record.status !== 'STAND_IN_QUEUE'}
                            key={'delete'}
                            onClick={(e) => {
                                e.preventDefault();
                                this.removeUserFromSession(selectedWorkflow, record.signingorder);
                            }}
                            title={translate('buttons.delete.container')}
                        />
                        {window.config.REACT_APP_META_DATA_ENABLED && (
                            <React.Fragment>
                                <Divider type="vertical" />
                                <Tooltip placement="top" title={translate('tooltip.metadata')}>
                                    <Button
                                        type={'default'}
                                        key={'metadata'}
                                        disabled={record.metadata}
                                        onClick={() => this.props.openMetaDataModal(selectedWorkflow)}
                                        icon={'database'}
                                    />
                                </Tooltip>
                            </React.Fragment>
                        )}
                    </div>
                ),
            },
        ];
        const ProcessDatas = this.getProcessDatas(selectedWorkflow);

        return (
            <div>
                <Row>
                    <Col xs={24} xl={8} style={{ padding: '7px' }} className="update-button-col">
                        <Button
                            className="remove-focus-from-button"
                            onClick={(e) => {
                                e.preventDefault();
                                this.getSigningProcesses();
                            }}
                        >
                            <Icon type="sync" />
                            {translate('buttons.update')}
                        </Button>
                        <Divider type={'vertical'} />
                        <DeleteButton
                            disabled={selectedWorkflow === ''}
                            key={'delete'}
                            onClick={(e) => {
                                e.preventDefault();
                                confirmationModal(
                                    translate,
                                    () => this.deleteBulkSession(this.state.selectedWorkflow),
                                    translate('confirm.signingProcessDeleteConfirm')
                                );
                            }}
                            title={translate('buttons.delete.bulkSigningSession')}
                        >
                            {translate('buttons.delete.bulkSigningSession')}
                        </DeleteButton>
                    </Col>
                </Row>

                <Row>
                    <Col sm={24} md={12} xl={9} style={{ padding: '7px' }}>
                        <Search
                            onChange={this.handleInputChange}
                            onSearch={this.onSearch}
                            placeholder={this.props.translate('adminPage.common.emailSearchPlaceholder')}
                            value={this.state.searchText}
                            allowClear
                        />
                    </Col>
                </Row>
                <Row type="flex" justify="start" gutter={16}>
                    <Col xs={24} md={24} lg={24} xl={24} xxl={10}>
                        <div
                            style={{
                                backgroundColor: theme.documentListBg,
                                borderRadius: 4,
                                padding: 15,
                            }}
                        >
                            <h2>{translate('waitingtosignbyothers.containers')}</h2>
                            <Table
                                columns={containerColumns}
                                dataSource={ContainerDatas.map((d) => ({
                                    key: d.id,
                                    fileName: d.fileName,
                                    creationDate: formatDate(d.creationDate, translate('language')),
                                    id: d.id,
                                    createdBy: d.createdBy,
                                    comment: d.comment,
                                    status: d.status,
                                }))}
                                loading={this.props.isLoading}
                                size={'small'}
                                pagination={this.props.pagination}
                                rowClassName={(record) => {
                                    return record.id === selectedWorkflow ? 'table-select' : '';
                                }}
                                locale={{
                                    emptyText: translate('noData'),
                                }}
                                onRow={(record) => ({
                                    onClick: () => {
                                        this.workflowSelected(record);
                                    },
                                })}
                                onChange={this.handleChange}
                            />
                        </div>
                    </Col>
                    <Col xs={24} md={24} lg={24} xl={24} xxl={14}>
                        <div
                            style={{
                                backgroundColor: theme.documentListBg,
                                borderRadius: 4,
                                padding: 15,
                            }}
                        >
                            <h2>{translate('waitingtosignbyothers.details.title')}</h2>
                            <Table
                                columns={detailsColumns}
                                dataSource={ProcessDatas.map((d) => ({
                                    key: d.signingorder,
                                    name: d.name,
                                    status: d.status,
                                    state: d.state,
                                    message: d.message,
                                    signingorder: d.signingorder,
                                }))}
                                locale={{ emptyText: translate('noData') }}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

AdminSigningProcesses.propTypes = {
    deleteSigningProcess: PropTypes.func.isRequired,
    getSigningProcesses: PropTypes.func.isRequired,
    getSigningProcessesByGuid: PropTypes.func.isRequired,
    openMetaDataModal: PropTypes.func.isRequired,
    removeUserFromSigningProcess: PropTypes.func.isRequired,
    resendContainerToUser: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired,
    waitingToSignByOthers: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    pagination: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale),
        waitingToSignByOthers: state.containerList.waitingToSignByOthers,
        isLoading: state.containerList.isLoading,
        pagination: state.containerList.pagination
    };
}

function mapDispatchToProps(dispatch) {
    return {
        deleteSigningProcess: (guid) => {
            return dispatch(deleteSigningProcess(guid));
        },
        getSigningProcesses: (pagination, filters, sorter) => {
            return dispatch(getSigningProcesses(pagination, filters, sorter));
        },
        getSigningProcessesByGuid: (guid) => {
            return dispatch(getSigningProcessesByGuid(guid));
        },
        removeUserFromSigningProcess: (guid, signingOrder) => {
            return dispatch(removeUserFromSigningProcess(guid, signingOrder));
        },
        resendContainerToUser: (guid, signingOrder) => {
            return dispatch(resendContainerToUser(guid, signingOrder));
        },
        openMetaDataModal: (guid) => {
            dispatch(getMetadata(guid)).then((data) => {
                dispatch(toggleModal(META_DATA, data));
            });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminSigningProcesses);

import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import theme from '../../theme';
import {Icon, Spin} from 'antd';
import {Document, Page, pdfjs} from 'react-pdf';
import {createNotificationShort} from '../../helper';
import MultiPagePreviewNavigation from "./MultiPagePreviewNavigation";

pdfjs.GlobalWorkerOptions.workerSrc = `/externals/js/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function PdfPreviewDeprecated(props) {
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);

    const file = useMemo(
        () => ({
            url: props.url,
            withCredentials: true,
            httpHeaders: { Authorization: `Bearer ${localStorage.getItem('JWT')}` }
        }),
        [props.url]
    );

    return (
        <Document
            file={file}
            onLoadSuccess={({ numPages }) => setNumberOfPages(numPages)}
            loading={
                <div style={{ textAlign: 'center', margin: '16px 0', color: theme.defaultColor }}>
                    <Spin indicator={
                    <Icon type="loading" style={{ fontSize: 32 }} spin />
                    } />
                    <span style={{ display: 'block' }}>{props.translate('previewLoading')}</span>
                </div>
            }
            onLoadError={() => {
                createNotificationShort(props.translate, {
                    message: props.translate('notifications.pdfLoadingError.message'),
                    type: 'error'
                });
            }}>
            <nav
                style={{
                    textAlign: 'center',
                    padding: '16px 0 16px 0',
                    marginBottom: 16,
                    backgroundColor: '#FAFAFA',
                    borderBottom: '1px solid #e8e8e8'
                }}>
                <MultiPagePreviewNavigation
                    numberOfPages={numberOfPages}
                    pageNumber={pageNumber}
                    goToPageCallback={setPageNumber}>
                </MultiPagePreviewNavigation>
            </nav>
            <ScrollContainer
                className="scroll-container"
                nativeMobileScroll={false}
                hideScrollbars={false}>
                <Page
                    pageNumber={pageNumber}
                />
            </ScrollContainer>
        </Document>
    );
}

PdfPreviewDeprecated.propTypes = {
    translate: PropTypes.func,
    url: PropTypes.string
};

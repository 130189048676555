import AttributeCertificatesModal from './attributecertificate/AttributeCertificatesModal';
import AttributeRolesModal from './attributecertificate/AttributeRolesModal';
import AuthenticationModal from './AuthenticationModal';
import ClosureModal from './ClosureModal';
import CompanyRegModal from './CompanyRegModal';
import CreateContainerModal from './container/CreateContainerModal';
import CreateNewUser from './CreateNewUserModal';
import DeleteContainerModal from './delete/DeleteContainerModal';
import EditDeputyModal from './EditDeputyModal';
import ForgottenPasswordModal from './ForgottenPasswordModal';
import FrequentlyUsedEmailAddressModal from './FrequentlyUsedEmailAddressModal';
import MetadataModal from './MetadataModal';
import MobileLoginModal from './MobileLoginModal';
import MobileQrCodeIdentificationModal from './MobileQrCodeIdentificationModal';
import NotifyRegisteredUserModal from './NotifyRegisteredUserModal';
import NotifyUserModal from './NotifyUserModal';
import PdfEmbeddedFilesModal from './PdfEmbeddedFilesModal';
import PdfPreviewModal from '../document/PdfPreviewModal';
import PdfWithFormNotification from './PdfWithFormNotification';
import PropTypes from 'prop-types';
import React from 'react';
import RefuseSignatureModal from './sign/RefuseSignatureModal';
import RemoveConfirmationModal from './remove/RemoveConfirmationModal';
import RenameContainerModal from './container/RenameContainerModal';
import RenameDocumentModal from './document/RenameDocumentModal';
import SecondFactorModal from './SecondFactorModal';
import SelectCertificateModal from './SelectCertificateModal';
import WrappedSendForSignatureModal from './workflow/SendForSignatureModal';
import SignModal from './sign/SignModal';
import SignaturePositionModal from './SignaturePositionModal';
import SignatureSchemaModal from './SignatureSchemaModal';
import TSAModal from './tsa/TSAModal';
import UploadCegeljarasModal from './upload/UploadCegeljarasModal';
import UserProfileErrorModal from './UserProfileErrorModal';
import WrappedShareModal from './email/ShareModal';

import { connect } from 'react-redux';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { removeModal, toggleModal } from '../../actions/modal';

import {
    ADMIN_EDIT_DEPUTY,
    ATTRIBUTE_CERTIFICATES,
    ATTRIBUTE_ROLES,
    AUTHENTICATION,
    CLOSURE,
    COMPANY_REGISTRATION,
    CREATE_FREQUENTLY_USED_EMAIL_ADDRESS,
    CREATE_NEW_USER,
    CREATE_SIGNATURE_SCHEMA,
    FORGOTTEN_PASSWORD,
    META_DATA,
    MOBILE_LOGIN,
    MOBILE_QR_CODE_IDENTIFICATION,
    NEW_CONTAINER,
    NOTIFY_USER,
    NOTIFY_USER_REGISTERED,
    PDF_EMBEDDED_FILES,
    PDF_PREVIEW_DEPRECATED,
    PDF_WITH_FORM_NOTIFICATION,
    REFUSE_SIGNATURE,
    REMOVE_CONTAINER,
    REMOVE_DOCUMENT,
    REMOVE_SIGNATURE,
    RENAME_CONTAINER,
    RENAME_DOCUMENT,
    SECOND_FACTOR,
    SELECT_CERTIFICATE,
    SEND_FOR_SIGNATURE,
    SHARE_CONTAINER,
    SIGN,
    SIGNATURE_POSITION,
    TSA,
    UPDATE_FREQUENTLY_USED_EMAIL_ADDRESS,
    UPDATE_SIGNATURE_SCHEMA,
    UPLOAD_CEGELJARAS,
    USER_PROFILE_ERROR, WORKFLOW_TEMPLATE
} from '../../constants/modalNames';
import WorkflowTemplateModal from './workflow/WorkflowTemplateModal';

const ModalContainer = (props) => {
    switch (props.modalName) {
        case ADMIN_EDIT_DEPUTY:
            return <EditDeputyModal {...props} />;
        case ATTRIBUTE_CERTIFICATES:
            return <AttributeCertificatesModal {...props} />;
        case ATTRIBUTE_ROLES:
            return <AttributeRolesModal {...props} />;
        case AUTHENTICATION:
            return <AuthenticationModal {...props} />;
        case CLOSURE:
            return <ClosureModal {...props} />;
        case COMPANY_REGISTRATION:
            return <CompanyRegModal {...props} />;
        case CREATE_FREQUENTLY_USED_EMAIL_ADDRESS:
        case UPDATE_FREQUENTLY_USED_EMAIL_ADDRESS:
            return <FrequentlyUsedEmailAddressModal {...props} />;
        case CREATE_NEW_USER:
            return <CreateNewUser {...props} />;
        case CREATE_SIGNATURE_SCHEMA:
            const createProps = { update: false, ...props };
            return <SignatureSchemaModal {...createProps} />;
        case FORGOTTEN_PASSWORD:
            return <ForgottenPasswordModal {...props} />;
        case META_DATA:
            return <MetadataModal {...props} />;
        case MOBILE_LOGIN:
            return <MobileLoginModal {...props} />;
        case MOBILE_QR_CODE_IDENTIFICATION:
            return <MobileQrCodeIdentificationModal { ...props.modalData } />;
        case NEW_CONTAINER:
            return <CreateContainerModal {...props} />;
        case NOTIFY_USER:
            return <NotifyUserModal {...props} />;
        case NOTIFY_USER_REGISTERED:
            return <NotifyRegisteredUserModal {...props} />;
        case PDF_EMBEDDED_FILES:
            return <PdfEmbeddedFilesModal {...props} />;
        case PDF_PREVIEW_DEPRECATED:
            return <PdfPreviewModal {...props} />;
        case PDF_WITH_FORM_NOTIFICATION:
            return <PdfWithFormNotification {...props} />;
        case REFUSE_SIGNATURE:
            return <RefuseSignatureModal {...props} />;
        case REMOVE_CONTAINER:
            return <DeleteContainerModal {...props} />;
        case REMOVE_DOCUMENT:
        case REMOVE_SIGNATURE:
            return <RemoveConfirmationModal {...props} />;
        case RENAME_CONTAINER:
            return <RenameContainerModal {...props} />;
        case RENAME_DOCUMENT:
            return <RenameDocumentModal {...props} />;
        case SECOND_FACTOR:
            return <SecondFactorModal {...props} />;
        case SELECT_CERTIFICATE:
            return <SelectCertificateModal {...props} />;
        case SEND_FOR_SIGNATURE:
            return <WrappedSendForSignatureModal template={ props.modalData?.template } { ...props } />;
        case SHARE_CONTAINER:
            return <WrappedShareModal {...props} />;
        case SIGN:
            return <SignModal {...props} />;
        case SIGNATURE_POSITION:
            return <SignaturePositionModal {...props} />;
        case TSA:
            return <TSAModal {...props} />;
        case UPDATE_SIGNATURE_SCHEMA:
            var updateProps = { update: true, ...props };
            return <SignatureSchemaModal {...updateProps} />;
        case UPLOAD_CEGELJARAS:
            return <UploadCegeljarasModal {...props} />;
        case USER_PROFILE_ERROR:
            return <UserProfileErrorModal {...props} />;
        case WORKFLOW_TEMPLATE:
            return <WorkflowTemplateModal {...props} />;
        default:
            return null;
    }
};

ModalContainer.propTypes = {
    modalName: PropTypes.string
};

function mapStateToProps(state) {
    return {
        currentLanguage: getActiveLanguage(state.locale).code,
        modalData: state.modal.data,
        modalName: state.modal.name,
        translate: getTranslate(state.locale),
        visible: state.modal.visible
    };
}

function mapDispatchToProps(dispatch) {
    return {
        removeModal: () => {
            dispatch(removeModal());
        },
        toggleModal: (modalName, modalData) => {
            dispatch(toggleModal(modalName, modalData));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);

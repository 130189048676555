import PropTypes from "prop-types";
import SigningWorkflowComment from "./SigningWorkflowComment";
import React from "react";
import SigningWorkflowFileAttachmentList from "./SigningWorkflowFileAttachmentList";

export default function SigningWorkflowAttachments(props) {
    const {form, commentValue, handleCommentChanged, handleFileAttachmentListChanged} = props;

    return (
        <>
            <SigningWorkflowComment
                form={form}
                commentValue={commentValue}
                handleCommentChanged={handleCommentChanged}
            />
            {window.config.REACT_APP_SIGNING_WORKFLOW_FILE_ATTACHMENT_ENABLED && <SigningWorkflowFileAttachmentList
                handleFileAttachmentListChanged={handleFileAttachmentListChanged}
            />}
        </>
    );
}

SigningWorkflowAttachments.propTypes = {
    form: PropTypes.object.isRequired,
    commentValue: PropTypes.string.isRequired,
    handleCommentChanged: PropTypes.func.isRequired,
    handleFileAttachmentListChanged: PropTypes.func.isRequired
}

/* eslint-disable react-hooks/exhaustive-deps */
import ContainerList from './ContainerList';
import React, {useEffect, useState} from 'react';
import theme from '../../theme';
import {TAB_KEY_CONTAINERS_IN_SIGNATURE_PROCESS, TAB_KEY_DEFAULT_CONTAINERS} from '../../constants/strings';
import {TABLE_PAGE_SIZE_CONTAINER_LIST_DEFAULT, TIMEOUT_MILLISECONDS} from '../../constants/config';
import {Badge, Tabs} from 'antd';
import {createNotification} from '../../helper';
import {fetchDocumentTitles} from '../../actions/documentTitles';
import {
    getContainers,
    getContainersInSignatureProcess,
    setSelectedContainers,
    updateSelectedContainerList
} from '../../actions/container';
import {getTranslate} from 'react-localize-redux';
import {setCurrentTab} from '../../actions/currentTab';
import {useDispatch, useSelector} from 'react-redux';
import {useMergeState} from '../../utilities/hooks';
import {useDeepCompareEffect} from 'react-use';
import authService from "../../services/authService";
import {selectedId, clearSelectedContainerId} from "../pages/HomePage.js"

export default function ContainerTabs() {
    const dispatch = useDispatch();

    const [isMounted, setIsMounted] = useState(false);
    const [isTableLoading, setIsTableLoading] = useState(false);

    const pageLimit = authService.getContainerPageSize() || TABLE_PAGE_SIZE_CONTAINER_LIST_DEFAULT;

    const initialState = {
        count: 0,
        filter: '',
        limit: pageLimit,
        offset: 0,
        order: 'DESC',
        orderBy: 'created',
        selectedId: selectedId
    }
    // Default Container List
    const [containerRequestOptions, setContainerRequestOptions] = useMergeState(initialState);

    // In Signature Process List -> ISP
    const [containerISPRequestOptions, setContainerISPRequestOptions] = useMergeState(initialState);

    const containers = useSelector((state) => state.containerList.containers);
    const containersChangeCounter = useSelector((state) => state.containerList.containersChangeCounter);
    const filter = useSelector((state) => state.containerList.filter);
    const filterSP = useSelector((state) => state.containerList.filterSP);
    const page = useSelector((state) => state.containerList.page);
    const pageSP = useSelector((state) => state.containerList.pageSP);
    const containerTotalElements = useSelector((state) => state.containerList.totalElements);
    const containerTotalElementsSP = useSelector((state) => state.containerList.totalElementsSP);
    const containerTotalElementsWithoutFilterSP = useSelector((state) => state.containerList.totalElementsWithoutFilterSP);
    const containersInSignatureProcess = useSelector((state) => state.containerList.containersInSignatureProcess);
    const currentTab = useSelector((state) => state.tabs.current);
    const translate = useSelector((state) => getTranslate(state.locale));

    useEffect(() => {
        if (!isMounted) {
                fetchContainerLists().then(() => {
                setIsMounted(true);
            });
        }
        return () => {
            dispatch(setSelectedContainers([]));
            setIsMounted(false);
        }
    }, []);

    useEffect(() => {
        // FIXME
        // This is a not-so temporary solution to avoid infinite request loops when
        // switching fast between containers.
        isMounted && setIsTableLoading(true);
        isMounted && setTimeout(() => isMounted && setIsTableLoading(false), TIMEOUT_MILLISECONDS);
    }, [currentTab]);

    useEffect(() => {
        if (isMounted && !isTableLoading) {
            getDefaultContainers();
            containers.length > 0 && dispatch(setCurrentTab(TAB_KEY_DEFAULT_CONTAINERS));
        }
    }, [containersChangeCounter]);

    useDeepCompareEffect(() => {
        isMounted && !isTableLoading && getDefaultContainers();
    }, [containerRequestOptions]);

    useDeepCompareEffect(() => {
        if (isMounted && !isTableLoading) {
            getSignatureProcessContainers();
        }
    }, [containersInSignatureProcess, containerISPRequestOptions]);

    function handleContainersResponse(response, containerOptions, setContainerOptions) {
        if (containerOptions.selectedId) {
            dispatch(updateSelectedContainerList([{data: {id: containerOptions.selectedId}}]));
        }
        setContainerOptions({ count: response.count, offset: response.offset, selectedId: undefined });
        setIsTableLoading(false);
    }

    function fetchContainer(fetcher, requestOptions, setRequestOptions, onSuccess) {
        setIsTableLoading(true);
        return dispatch(
            fetcher(requestOptions)
        ).then((response) => {
            handleContainersResponse(response, requestOptions, setRequestOptions);
            if (onSuccess) {
                onSuccess(response);
            }
        }).catch((error) => {
            console.error(error)
            createNotification(translate, error.response.data.code, {
                message: translate('notifications.getData.failure'),
                type: 'error'
            });
        });
    }

    function getDefaultContainers() {
        return fetchContainer(getContainers, containerRequestOptions, setContainerRequestOptions);
    }

    function getSignatureProcessContainers() {
        return fetchContainer(
            getContainersInSignatureProcess,
            containerISPRequestOptions,
            setContainerISPRequestOptions,
            setSelectedTab);
    }

    function setSelectedTab(containersInSigningProcessResponse) {
        let currentTab = TAB_KEY_CONTAINERS_IN_SIGNATURE_PROCESS;
        let containersInSigningProcess = containersInSigningProcessResponse.containers;
        if (containersInSigningProcessResponse.totalReceivedCount === 0 || (selectedId && containersInSigningProcess.filter(c => c.data.id === selectedId).length === 0 && !isTableLoading)) {
            currentTab = TAB_KEY_DEFAULT_CONTAINERS;
        }
        dispatch(setCurrentTab(currentTab));
        clearSelectedContainerId();
    }

    function fetchContainerLists() {
        return getDefaultContainers(containerRequestOptions)
            .then(() => getSignatureProcessContainers())
            .finally(
                dispatch(fetchDocumentTitles()).catch((error) => {
                    createNotification(translate, error.response.data.code, {
                        message: translate('notifications.getData.failure'),
                        type: 'error'
                    });
                })
            );
    }

    function onTabChange(activeKey) {
        if (isMounted) {
            dispatch(setCurrentTab(activeKey));
        }
    }

    return (
        <Tabs
            activeKey={currentTab}
            className={'containerTabs'}
            destroyInactiveTabPane={true}
            onChange={(activeKey) => onTabChange(activeKey)}
            type={'card'}>
            <Tabs.TabPane
                key={TAB_KEY_DEFAULT_CONTAINERS}
                tab={translate(`container.list.${TAB_KEY_DEFAULT_CONTAINERS}.title`)}>
                <ContainerList
                    useSkeleton={!isMounted}
                    containerList={containers}
                    containerTotalElements = { containerTotalElements }
                    containerRequestOptions={containerRequestOptions}
                    isTableLoading={isTableLoading}
                    setContainerRequestOptions={setContainerRequestOptions}
                    setIsTableLoading={setIsTableLoading}
                    filter={filter}
                    page={page}
                />
            </Tabs.TabPane>
            <Tabs.TabPane
                key={TAB_KEY_CONTAINERS_IN_SIGNATURE_PROCESS}
                tab={
                    <Badge
                        count = { containerTotalElementsWithoutFilterSP }
                        offset={[15, -2]}
                        style={{ backgroundColor: theme.errorColor }}>
                        {translate(`container.list.${TAB_KEY_CONTAINERS_IN_SIGNATURE_PROCESS}.title`)}
                    </Badge>
                }>
                <ContainerList
                    useSkeleton={!isMounted}
                    containerList={containersInSignatureProcess}
                    containerTotalElements = { containerTotalElementsSP }
                    containerRequestOptions={containerISPRequestOptions}
                    isTableLoading={isTableLoading}
                    setContainerRequestOptions={setContainerISPRequestOptions}
                    setIsTableLoading={setIsTableLoading}
                    filter={filterSP}
                    page={pageSP}
                />
            </Tabs.TabPane>
        </Tabs>
    );
}

import React from 'react';
import authService from './services/authService';
import axios from 'axios/index';
import moment from 'moment';
import {CEGJEGYZEKSZAM_REGULAR_EXPRESSION} from './constants/config';
import {STORAGE_KEY_SIGN_MODE} from './constants/storageKey';
import {createHashHistory} from 'history';
import {notification} from 'antd';
import {browserName, osName, osVersion} from 'react-device-detect';

const history = createHashHistory();

export class PreviewContainer {
    constructor(container, height, width, isPdf) {
        this.container = container;
        this.height = height;
        this.width = width;
        this.isPdf = isPdf;
    }
}

export const getHistory = () => {
    return history;
};

export const getFriendlyName = () => {
    return `web-Szigno/${browserName}/${osName}-${osVersion}`;
}

const rejectRequest = (error) => {
    /**
     * Reject the promise with an error object that simulates backend error response
     * so we can handle errors globally.
     *
     * @param errorCode
     * @returns {Promise<object>}
     */
    const clientErrorRejection = (errorCode) => {
        return Promise.reject({
            response: {
                data: {
                    error: {
                        code: errorCode
                    }
                }
            }
        });
    };

    const serverErrorRejection = (error) => {
        const { response } = error;
        if (response.headers['content-type'] === 'application/json') {
            return Promise.reject(error);
        }
        if (response.status === 401) {
            return clientErrorRejection('USER_NOT_AUTHORIZED');
        }
        return clientErrorRejection('HTTP_INTERNAL_ERROR');
    };

    switch (error.response.status) {
        case 401:
        case 403:
        case 405:
        case 420:
        case 421:
        case 400:
        case 500:
            return serverErrorRejection(error);
        case 0:
            return clientErrorRejection('HTTP_NO_RESPONSE');
        case 404:
            return clientErrorRejection('HTTP_CLIENT_ERROR_NOT_FOUND');
        default:
            break;
    }
};

export const getCurrentURL = () => {
    return encodeURIComponent(window.location.href.replace(window.location.origin + '/#', ''));
};

export const getApiUrl = () => {
    return process.env.NODE_ENV === 'development'
        ? window.config.REACT_APP_SERVICE_BASE_URL
        : document.location.pathname + 'api/v2'
}

export const ajax = (noJWTUsage, abortController) => {
    const headers = {
        //HTTP 1.1 spec for clients and proxies
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        //HTTP 1.0 spec for prehistoric clients
        Pragma: 'no-cache',
        //HTTP 1.0 and 1.1 spec for clients and proxies
        Expires: '0'
    };

    if (noJWTUsage !== true) {
        const jwt = localStorage.getItem('JWT');
        if (jwt !== null) {
            headers[window.config.REACT_APP_AUTHORIZATION_HEADER_KEY] = 'Bearer ' + jwt;
        }
    }

    if (abortController === undefined) {
        abortController = new AbortController();
    }

    const instance = axios.create({
        baseURL: getApiUrl(),
        //eszigno automatic registration renewal needs more time
        timeout: 300000,
        //disable request cache in browsers
        headers: headers,
        paramsSerializer: (params) => {
            return JSON.stringify(params);
        },
        signal: abortController.signal
    });

    const notSoSeriousErrorCodes = ['USER_TIMESTAMP_CREDENTIALS_ERROR', 'USER_TIMESTAMP_CREDENTIALS_NOT_FOUND'];

    function refreshJwt(jwt) {
        jwt && localStorage.setItem('JWT', jwt.split(' ')[1]);
    }

    instance.interceptors.response.use(
        (response) => {
            response.headers && window.config && refreshJwt(response.headers[window.config.REACT_APP_AUTHORIZATION_HEADER_KEY.toLowerCase()]);
            return response;
        },
        (err) => {
            if (err.code === 'ERR_CANCELED') {
                return Promise.reject(err);
            }

            if (!err.response) {
                return { data: { code: 'USER_JWT_EXPIRED' } };
            }
            // In some cases (e.g. checkTsp) HTTP 500 is not a real error
            if (notSoSeriousErrorCodes.includes(err.response.code)) {
                return err.response;
            }
            process.env.NODE_ENV === 'development' &&
            console.error('Async request error occurred! Axios config: ', err.config);
            if (err.response) {
                process.env.NODE_ENV === 'development' &&
                console.error(
                    'The request was made and the server responded with a status' +
                    ' code that falls out of the range of 2xx:',
                    err.response.status,
                    err.response.data
                );
                // If logged in and got a 401 from server automatically kick user out
                if (authService.isRegistered() && err.response.status === 401) {
                    abortController.abort();
                    authService.logout();
                    history.push('/login?redirectURL=' + getCurrentURL());
                    // After redirection the component is responsible to reset the app state.
                    // This breaks promise chain and execution stops here.
                }
                return rejectRequest(err);
            } else {
                if (err.request) {
                    process.env.NODE_ENV === 'development' &&
                    console.error('The request was made but no response was received: ', err.request);
                } else {
                    process.env.NODE_ENV === 'development' &&
                    console.error(
                        'Something happened in setting up the request that triggered an Error: ',
                        err.message
                    );
                }
                return rejectRequest({ response: { status: 0 } });
            }
        }
    );

    return instance;
};

export const setSignatureSetting = (signatureSetting) => {
    localStorage.setItem('signatureSetting', signatureSetting);
};

export const getSignatureSetting = () => {
    return localStorage.getItem('signatureSetting');
};

export const getSignModeLocalStorage = () => {
    return localStorage.getItem(STORAGE_KEY_SIGN_MODE);
};

export const setSignModeLocalStorage = (signMode) => {
    localStorage.setItem(STORAGE_KEY_SIGN_MODE, signMode);
};

export const setSignModeLocalStorageFromRequest = (signModeFromRequest) => {
    let signMode;

    switch (signModeFromRequest) {
        case 'qr':
            signMode = 'signUsingQRCode';
            break;

        case 'notification':
            signMode = 'signUsingPush';
            break;

        case 'microsigner':
        default:
            signMode = 'sign';
            break;
    }

    setSignModeLocalStorage(signMode);
};

export const extensionFromContainerType = (containerType) => {
    switch (containerType) {
        case 'eacknowledgement':
            return 'et3';
        case 'edossier':
            return 'es3';
        case 'asice':
        case 'asic':
            return 'asic';
        case 'pdf':
            return 'pdf';
        default:
            break;
    }
};

export const uriTypeExtension = {
    edossier: ['.es3', '.et3', '.dosszie', '.dossier', '.edossier', '.nsack', '.eacknowledgement'],
    asic: ['.asics', '.asice', '.scs', '.sce', '.zip', '.asic'],
    pdf: ['.pdf']
};

export const notificationBadgeOffset = (type) => {
    type = '.' + type;
    if (uriTypeExtension.edossier.includes(type)) {
        return [-11, 20];
    } else if (uriTypeExtension.asic.includes(type)) {
        return [-10, 21];
    } else if (uriTypeExtension.pdf.includes(type)) {
        return [-14, 25];
    }
};

export const acceptedExtensions = () => {
    return uriTypeExtension.edossier.concat(uriTypeExtension.asic, uriTypeExtension.pdf);
};

export const uriTypeFromExtension = (extension) => {
    let extensionWithDot = extension.startsWith('.') ? extension : '.' + extension;
    if (uriTypeExtension.edossier.indexOf(extensionWithDot) > -1) {
        return 'edossier';
    }
    if (uriTypeExtension.asic.indexOf(extensionWithDot) > -1) {
        return 'asic';
    }
    if (uriTypeExtension.pdf.indexOf(extensionWithDot) > -1) {
        return 'pdf';
    }
    return null;
};

export const iconTypeFromType = (type) => {
    switch (type) {
        case 'jpg':
        case 'jpeg':
            return 'file-jpg';
        case 'txt':
            return 'file-text';
        case 'doc':
        case 'docx':
            return 'file-word';
        case 'xls':
        case 'xlsx':
            return 'file-excel';
        case 'ppt':
        case 'pptx':
            return 'file-ppt';
        case 'es3':
        case 'edossier':
            return 'folder';
        case 'eacknowledgement':
            return 'schedule';
        case 'asic':
        case 'asice':
        case 'asics':
            return 'table';
        case 'pdf':
            return 'file-pdf';
        default:
            return 'file';
    }
};

export const schemas = [
    'http://www.e-cegjegyzek.hu/2014/e-cegeljaras#',
    'http://www.e-cegjegyzek.hu/2006/ceginformacio_keres#',
    'http://www.e-cegjegyzek.hu/2014/cegtorvenyessegi#',
    'http://schema.e-szigno.hu/schema/raiffeisen_ertekbecslo2012#',
    'http://www.e-szigno.hu/2010/kozjegyzo20100101#',
    'http://www.e-cegjegyzek.hu/2021/e-cegeljaras#',
    'http://www.e-cegjegyzek.hu/2022/e-cegeljaras#',
    'http://www.e-cegjegyzek.hu/2023/e-cegeljaras#'
];

export const getSchema = (schema) => {
    switch (schema) {
        case schemas[1]:
            return 'Ceginforáció kérés';
        case schemas[2]:
            return 'Cégtörvényesség';
        case schemas[3]:
            return 'Értékbecslő';
        case schemas[4]:
            return 'Közjegyző';
        case schemas[0]:
        case schemas[5]:
        case schemas[6]:
        case schemas[7]:
            return 'Cégeljárás';
        default:
            return 'default';
    }
};

export function isCounterSignature(signatureData) {
    return signatureData.type === 'countersignature';
}

export function isTimestamp(signatureData) {
    return signatureData.type === 'timestamp' || signatureData.subType === 'ETSI.RFC3161';
}

const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };

export const formatDate = (date, locales) => {
    if (typeof date === 'undefined') {
        return '';
    }
    if (date === '1970-01-01T00:00:00Z') {
        return '-';
    }
    const isAndroid = /(android)/i.test(navigator.userAgent);
    if (isAndroid) {
        return date;
    }
    const milliseconds = Date.parse(date);
    if (isNaN(milliseconds) === true) {
        return date;
    }
    const d = new Date(milliseconds);
    return d.toLocaleString(locales, options);
};

export function compare(a, b) {
    let comparison = 0;
    if (a.data.creationDate < b.data.creationDate) {
        comparison = 1;
    }
    if (a.data.creationDate > b.data.creationDate) {
        comparison = -1;
    }
    return comparison;
}

export function getSignModeIconType() {
    switch (getSignModeLocalStorage()) {
        case 'signUsingQRCode':
            return 'qrcode';
        case 'sign':
            return 'form';
        case 'signUsingPush':
            return 'bell';
        default:
            return undefined;
    }
}

export function getSignModeForRequest() {
    switch (getSignModeLocalStorage()) {
        case 'signUsingQRCode':
            return 'qr';
        case 'sign':
            return 'microsigner';
        case 'signUsingPush':
            return 'notification';
        default:
            return 'microsigner';
    }
}

export function getDocuments(documentId, lang) {
    let publicUrl;
    if (process.env.NODE_ENV === 'development') {
        publicUrl = process.env.REACT_APP_API_URL;
    } else {
        publicUrl = '';
    }
    if (process.env.REACT_APP_NO_DOCUMENTS) {
        return undefined;
    } else {
        switch (documentId) {
            case 'terms_and_conditions':
                return window.config.REACT_APP_WEBSZIGNO_TERMS_AND_CONDITIONS_URLS[lang];
            case 'privacy_policy':
                return window.config.REACT_APP_WEBSZIGNO_PRIVACY_POLICY_URLS[lang];
            case 'price_list':
                return window.config.REACT_APP_WEBSZIGNO_PRICE_LIST_URLS[lang];
            case 'order':
                return window.config.REACT_APP_WEBSZIGNO_ORDER_URLS[lang];
            case 'manual':
                return window.config.REACT_APP_WEBSZIGNO_MANUAL_URLS[lang];
            case 'promo_for_microsigner':
                return window.config.REACT_APP_WEBSZIGNO_PROMO_FOR_MICROSIGNER_URLS[lang];
            case 'premium_services':
                return `${publicUrl}documents/${lang}/premium_services.pdf`;
            default:
                return undefined;
        }
    }
}

export function formatBytes(bytes, decimals = 2) {
    if (bytes === null) return '';
    if (bytes === 0 || bytes === '0') return '0 B';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function downloadAttachment(attachmentExternalId) {
    const url = `/workflow/attachment/${attachmentExternalId}`;
    download(url);
}

export function downloadContainer(containerId, isAdmin = false) {
    const url = isAdmin
        ? `/admin/containers/${containerId}/download`
        : `/containers/${containerId}/download`;
    download(url);
}

export function download(url) {
    ajax()
        .get(url, { responseType: 'blob' })
        .then((response) => {
            downloadFile(response);
        })
        .catch((error) => {
            console.error(error);
        });
}

export function downloadContainersInZip(containerIds) {
    ajax()
        .post(
            `/downloadMultiple`,
            { containerIds: containerIds },
            { responseType: 'blob' }
        )
        .then((response) => {
            downloadFile(response);
        })
        .catch((error) => {
            console.error(error);
        });
}

function downloadFile(response) {
    const url = window.URL.createObjectURL(
        new Blob([response.data], {
            type: `${response.headers['content-type']};charset=UTF-8`
        })
    );
    const link = document.createElement('a');
    link.href = url;
    const headerval = response.headers['content-disposition'];
    const filename = headerval.split(';')[1].split('=')[1].replace('"', '').replace('"', '');
    link.setAttribute('download', decodeURI(filename));
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
}

//We need this, because the rounding for the percent value could lose us about 6 pixels, which means the e-szigno could not sign the PDF file.
export function countPaddingPercent(position, size) {
    const realPaddingPercent = ((position * 100) / size).toFixed(0);
    if (size === 0) {
        return 0;
    }
    if (realPaddingPercent - 1 < 0) {
        return 0;
    } else {
        if (realPaddingPercent > 93) {
            return 93;
        } else {
            return realPaddingPercent;
        }
    }
}

export function validateFileSize(data) {
    const maxSize = window.config.REACT_APP_MAX_FILE_UPLOAD_SIZE;
    if (data instanceof FormData) {
        for (let file in data.values()) {
            if (file instanceof File) {
                if (file.size > maxSize) {
                    throw new Error('USER_MAX_UPLOAD_SIZE_ERROR');
                }
            }
        }
    } else if (data instanceof File) {
        if (data.size > maxSize) {
            throw new Error('USER_MAX_UPLOAD_SIZE_ERROR');
        }
    } else if (data instanceof Array) {
        let cumulateFileSize = 0;
        for (let file in data) {
            cumulateFileSize += file.size;
            if (cumulateFileSize > maxSize) {
                throw new Error('USER_MAX_UPLOAD_SIZE_ERROR');
            }
        }
    }
}

export function validateFileSignature(file, extension) {
    return new Promise((reject, resolve) => {
        const lowerExtension = extension.toLowerCase();
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file.slice(0, 4));
        fileReader.onloadend = function(e) {
            const arr = new Uint8Array(e.target.result).subarray(0, 4);
            let header = '';
            for (var i = 0; i < arr.length; i++) {
                header += arr[i].toString(16).toUpperCase();
            }
            switch (lowerExtension) {
                case 'png':
                    if (header !== '89504E47') {
                        reject({ isError: true, error: Error('Image is not a PNG!') });
                    }
                    break;
                default:
                    reject({ isError: true, error: Error('Unknown format!') });
            }
            resolve({ isError: false });
        };
    });
}

export const dateFormat = 'YYYY.MM.DD. HH:mm';

export const sourceDateFormat = 'YYYY-MM-DDTHH:mm:ssZ';

export function disabledDate(current) {
    return current.isBefore(moment().add(-1, 'minutes'));
}

export const sorter = (field) => (a, b) => a[field].localeCompare(b[field]);

export const reversedSorter = (field) => (a, b) => b[field].localeCompare(a[field]);

export const fileSizeInBytes = (size) => {
    const units = {
        B: 1,
        KB: 1024,
        MB: 1024 * 1024,
        GB: 1024 * 1024 * 1024
    };

    if (size) {
        const [value, unit] = size.split(' ');
        return parseInt(value) * units[unit];
    }
};

// search filterValue in filterFields of data
export const filterData = (data, filterValue, filterFields) => {
    if (filterValue) {
        const lowerCasedFilter = filterValue.toLowerCase();
        return data.filter((element) =>
            Object.keys(element).some((key) => {
                const property = element[key].toString();
                return filterFields.includes(key) ? property.toLowerCase().includes(lowerCasedFilter) : false;
            })
        );
    }
    return data;
};

export const translateStatus = (translate, status) => {
    switch (status) {
        case 'WAITING_TO_SIGN':
        case 'WAITING_TO_APPROVE':
            return translate('workflow.details.status.inProgress');
        case 'REFUSED':
            return translate('workflow.details.status.REFUSED');
        default:
            return translate('workflow.details.status.SIGNED');
    }
};

export const getStatusFilters = (translate) => [
    {
        text: translate('workflow.details.status.inProgress'),
        value: translate('workflow.details.status.inProgress')
    },
    {
        text: translate('workflow.details.status.REFUSED'),
        value: translate('workflow.details.status.REFUSED')
    },
    { text: translate('workflow.details.status.SIGNED'), value: translate('workflow.details.status.SIGNED') }
];

export const shouldSignAuto = (container) => {
    if (
        container &&
        container.type === 'pdf' &&
        container.pdfFormFields &&
        localStorage.getItem('signPDF') === 'true'
    ) {
        const fieldNames = container.pdfFormFields.map((ff) => ff.fieldName);
        return fieldNames.includes(authService.getUserEmail());
    }
    return false;
};

export const hasAnyExtraFeatureInPackage = () => {
    return (
        localStorage.getItem('customSignatureImage') === 'true' ||
        localStorage.getItem('signAsCompanyRepresentative') === 'true' ||
        localStorage.getItem('signContainers') === 'true' ||
        localStorage.getItem('signPDF') === 'true' ||
        localStorage.getItem('mobileScanning') === 'true' ||
        localStorage.getItem('startWorkflow') === 'true' ||
        localStorage.getItem('participateWorkflow') === 'true' ||
        localStorage.getItem('createCompanyRegisterEDossier') === 'true'
    );
};

export function validatePassword(translate, password, formCallback, ruleViolationCallback, form, confirmDirty) {
    if (password) {
        const numberOfNumbers = (password.match(/\d/g) || []).length;
        const numberOfUpperCaseCharacters = (password.match(/[A-Z]/g) || []).length;
        const numberOfSpecialCharacters = (password.match(/[ .,@%+\\!/#$^?(){}[\]~:;<>|]/g) || []).length;
        let ruleViolationMessages = [];

        if (password.length < window.config.REACT_APP_REGEX_MINLENGTH) {
            ruleViolationMessages.push(
                translate('form.password.tooShort', { length: window.config.REACT_APP_REGEX_MINLENGTH })
            );
        }
        if (numberOfNumbers < window.config.REACT_APP_REGEX_NUMBER) {
            ruleViolationMessages.push(
                translate('form.password.notEnoughNumbers', { number: window.config.REACT_APP_REGEX_NUMBER })
            );
        }
        if (numberOfUpperCaseCharacters < window.config.REACT_APP_REGEX_UPPERCASE) {
            ruleViolationMessages.push(
                translate('form.password.notEnoughUpperCaseCharacters', {
                    number: window.config.REACT_APP_REGEX_UPPERCASE
                })
            );
        }
        if (numberOfSpecialCharacters < window.config.REACT_APP_REGEX_SPECIAL) {
            ruleViolationMessages.push(
                translate('form.password.notEnoughSpecialCharacters', {
                    number: window.config.REACT_APP_REGEX_SPECIAL,
                    specialCharacters: '.,@%+\\/!#$^?: (){}[]~:;<>|'
                })
            );
        }

        if (ruleViolationMessages.length > 0) {
            formCallback(
                <div>
                    {translate('form.password.ruleViolation')}
                    <ul>
                        {ruleViolationMessages.map((message, key) => (
                            <li key={key}>{message}</li>
                        ))}
                    </ul>
                </div>
            );
            ruleViolationCallback();
        }

        if (confirmDirty) {
            form.validateFields(['confirmPassword'], { force: true });
        }
    }
    formCallback();
}


export const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

/**
 * This function will create notifications in a unified style and is able to catch
 * generic server errors as 401: Unauthorized and create notifications about instead.
 *
 * We should use this wrapper function to create notifications, especially if the
 * user encountered an error.
 *
 * @param {*} translate the translate function
 * @param {*} webszignoResponseCode the response code from the server
 * @param {*} details notification details
 * @returns
 */
export const createNotification = (translate, webszignoResponseCode, details) => {
    if (webszignoResponseCode === 'USER_JWT_EXPIRED') {
        notification.destroy();
        notification.info({
            description: translate('errorCodes.USER_JWT_EXPIRED.description'),
            duration: 10,
            key: 'jwt-expired',
            message: translate('errorCodes.USER_JWT_EXPIRED.message'),
            placement: 'topRight'
        });
        return;
    }
    switch (details.type) {
        case 'error':
            notification.error({
                description: details.description ? details.description : '',
                duration: details.duration ? details.duration : 10,
                key: details.key ? details.key : details.message,
                message: details.message ? details.message : '',
                placement: details.placement ? details.placement : 'topRight'
            });
            break;
        case 'info':
            notification.info({
                description: details.description ? details.description : '',
                duration: details.duration ? details.duration : 10,
                key: details.key ? details.key : details.message,
                message: details.message ? details.message : '',
                placement: details.placement ? details.placement : 'topRight'
            });
            break;
        case 'success':
            notification.success({
                description: details.description ? details.description : '',
                duration: details.duration ? details.duration : 10,
                key: details.key ? details.key : details.message,
                message: details.message ? details.message : '',
                placement: details.placement ? details.placement : 'topRight'
            });
            break;
        case 'warning':
            notification.warning({
                description: details.description ? details.description : '',
                duration: details.duration ? details.duration : 10,
                key: details.key ? details.key : details.message,
                message: details.message ? details.message : '',
                placement: details.placement ? details.placement : 'topRight'
            });
            break;
        default:
            break;
    }
};

/**
 * Same as createNotification, use this if there are no server error code,
 * so if the notification is not in a catch block.
 * @param {*} translate the translate function
 * @param {*} details notification details
 */
export const createNotificationShort = (translate, details) => {
    createNotification(translate, undefined, details);
};

export const generateTemplateTitle = (targets) => {
    let now = new Date();
    let year = now.getFullYear().toString();
    let month = (now.getMonth() + 1).toString();
    let day = now.getDate().toString();
    let hour = now.getHours().toString();
    let minute = now.getMinutes().toString();
    let titles = '';
    targets.forEach(t => {
        titles = titles.concat('_').concat(t.split('@')[0]);
    });

    let a = '';
    a = a.concat(year).concat('_').concat(month).concat('_').concat(day).concat('_').concat(hour).concat('_').concat(minute).concat(titles);

    return a;
};

export function calculatePageNumber(itemIndex, pageSize) {
    if (itemIndex === -1) {
        return 1;
    }

    let pageNumber = Math.floor((itemIndex + 1) / pageSize);

    if ((itemIndex + 1) % pageSize > 0) {
        pageNumber++;
    }

    return pageNumber;
}

/**
 * Guesses the extension of the file by its MIME type and filename.
 * @param {*} filename the name of the file (with extension)
 * @param {*} mimeType the MIME type of the file
 * @returns the extension of the file
 */
export function getExtension(filename, mimeType) {
    switch (mimeType) {
        case 'application/pdf':
            return 'pdf';
        case 'application/vnd.eszigno3+xml':
            return 'es3';
        case 'application/vnd.etsi.asic-e+zip':
        case 'application/vnd.etsi.asic-s+zip':
            return 'asic';
        default:
            break;
    }

    const split = filename.split('.');
    if (split.length > 1) {
        return split[split.length - 1];
    }

    return null;
}

/**
 * Determines if the container is an e-Cégeljárás dossier.
 * @param {*} container the container object
 * @returns true if the schemaname is Cégeljárás
 */
export function isCegeljaras(container) {
    return getSchema(container.esNamespace) === 'Cégeljárás';
}

/**
 * Returns the type of the container if it has a correct extension.
 * @param {*} filename the full name of the file (with extension)
 * @returns container type or undefined
 */
export function getContainerTypeFromFilename(filename) {
    const extension = filename.split('.').pop().toLowerCase();
    switch (extension) {
        case 'dossier':
        case 'dosszie':
        case 'es3':
            return 'edossier';
        case 'et3':
        case 'nsack':
            return 'eacknowledgement';
        case 'asic':
        case 'asice':
        case 'asics':
        case 'sce':
        case 'scs':
        case 'zip':
            return 'asic';
        case 'pdf':
            return 'pdf';
        default:
            return undefined;
    }
}

function isOnPage(string) {
    return getHistory().location.pathname.toLowerCase().includes(string);
}

export function isOnLoginPage() {
    return isOnPage('login');
}

export function isOnAdminPage() {
    return isOnPage('admin');
}

export function validateCegjegyzekSzam(value, callback, translate) {
    if (!CEGJEGYZEKSZAM_REGULAR_EXPRESSION.test(value)) {
        callback(translate('form.cegjegyzekszam.invalid'));
    }

    callback();
}

export function removeExtension(filename) {
   return filename.substring(0, filename.lastIndexOf('.')) || filename
}

/**
 * Removes the possible filename counter postfixes.
 * For example: <code>image(1)(1).jpg<code> will be converted to <code>image.jpg</code>.
 *
 * @param filename Filename.
 * @returns Filename without counter postfixes.
 */
export function removeFilenameCounterPostfix(filename) {
    const filenameCounterPostfixRegex = /(\(\d+\))+(\..*)?$/;

    if (filename.match(filenameCounterPostfixRegex)) {
        return filename.replace(filenameCounterPostfixRegex, '$2');
    } else {
        return filename;
    }
}

import {
    REFRESH_FREQUENTLY_USED_EMAIL,
    RESET_FREQUENTLY_USED_EMAIL,
} from './actionTypes';

export const refreshFrequentlyUsedEmailAddresses = (emailAddress) => {
    return {
        type: REFRESH_FREQUENTLY_USED_EMAIL,
        payload: emailAddress,
    };
};

export const resetFrequentlyUsedEmailAddresses = () => {
    return {
        type: RESET_FREQUENTLY_USED_EMAIL,
    };
};

import ModalWrapper from './ModalWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import eng from 'antd/lib/date-picker/locale/en_GB';
import hun from 'antd/lib/date-picker/locale/hu_HU';
import moment from 'moment';
import { Button, DatePicker, Form, Input, Typography } from 'antd';
import { connect } from 'react-redux';
import { createNotification, createNotificationShort, disabledDate, dateFormat } from '../../helper';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { removeModal } from '../../actions/modal';
import { updateDeputy, saveDeputy } from '../../actions/admin';

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss:SSZ';

class EditDeputyModal extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
    }

    state = {
        isSubmitting: false,
    };

    componentDidMount() {
        this._isMounted = true;
        const { deputy, createMode } = this.props.modalData;
        const { setFieldsValue } = this.props.form;

        if (createMode) {
            setFieldsValue({
                userEmail: '',
                deputyEmail: '',
                startDate: '',
                endDate: ''
            });
        } else {
            setFieldsValue({
                userEmail: deputy.userEmail,
                deputyEmail: deputy.deputyEmail,
                startDate: moment(deputy.startDate, DATE_FORMAT),
                endDate: moment(deputy.endDate, DATE_FORMAT)
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    submitDeputyData = async (e) => {
        e.preventDefault();
        this._isMounted && this.setState({ isSubmitting: true });
        const { modalData, form, onUpdateDeputy, onSaveDeputy, translate, closeModal } = this.props;
        const oldDeputyData = { ...modalData.deputy };
        await form.validateFieldsAndScroll(['userEmail', 'deputyEmail', 'endDate'], async (err, values) => {
            if (!err) {
                const userEmail = values.userEmail ? values.userEmail : modalData.deputy.userEmail;
                const deputyEmail = values.deputyEmail ? values.deputyEmail : modalData.deputy.deputyEmail;
                const startDate = moment();
                const endDate = values.endDate;
                if (modalData.createMode) {
                    const newDeputyData = {
                        userEmail: userEmail,
                        deputyEmail: deputyEmail,
                        startDate: startDate,
                        endDate: endDate
                    };
                    try {
                        await onSaveDeputy(newDeputyData);
                        createNotificationShort(this.props.translate, {
                            message: translate('messages.success.createDeputySuccess'),
                            type: 'success',
                        });
                        modalData.onFinish(newDeputyData);
                        closeModal();
                    } catch (e) {
                        this._isMounted && this.setState({ isSubmitting: false });
                        createNotification(this.props.translate, e.response.data.code, {
                            description: translate(`errorCodes.${e.response.data.code}`, {payload: e.response.data.detail }),
                            message: translate('messages.error.createDeputy'),
                            type: 'error',
                        });
                    }
                } else {
                    const changed = this.deputyDataChanged(oldDeputyData, {
                        userEmail: userEmail,
                        deputyEmail: deputyEmail,
                        startDate: oldDeputyData.startDate,
                        endDate: endDate
                    });
                    if (changed) {
                        const changedDeputyData = {
                            userEmail: userEmail,
                            deputyEmail: deputyEmail,
                            startDate: oldDeputyData.startDate,
                            endDate: endDate
                        };
                        const changedDeputyDataForUI = {
                            userEmail: userEmail,
                            deputyEmail: deputyEmail,
                            startDate: oldDeputyData.startDate,
                            endDate: endDate
                        };
                        try {
                            await onUpdateDeputy(changedDeputyData);
                            createNotificationShort(this.props.translate, {
                                message: translate('messages.success.editDeputySuccess'),
                                type: 'success',
                            });
                            modalData.onFinish(changedDeputyDataForUI);
                            closeModal();
                        } catch (e) {
                            this._isMounted && this.setState({ isSubmitting: false });
                            createNotification(this.props.translate, e.response.data.code, {
                                description: translate(`errorCodes.${e.response.data.code}`),
                                message: translate('messages.error.updateDeputy'),
                                type: 'error',
                            });
                        }
                    }
                }
            } else {
                this._isMounted && this.setState({ isSubmitting: false });
            }
        });
    };

    deputyDataChanged = (oldValues, newValues) => {
        return Object.keys(newValues).some((key) => {
            return oldValues[key] !== newValues[key];
        });
    };

    render() {
        const { modalData, translate, form, currentLanguage } = this.props;
        const { getFieldDecorator } = form;
        const { createMode } = modalData;
        const title = createMode
            ? translate('modals.editDeputy.addDeputyModalTitle')
            : translate('modals.editDeputy.editDeputyModalTitle');

        return (
            <ModalWrapper {...this.props} title={title}>
                <Form onSubmit={this.submitDeputyData}>
                    <Form.Item label={translate('modals.editDeputy.userEmail')}>
                        {createMode ? (
                            getFieldDecorator('userEmail', {
                                rules: [
                                    {
                                        type: 'email',
                                        message: translate('formValidationErrors.validateEmail'),
                                    },
                                    {
                                        required: true,
                                        message: translate('formValidationErrors.required'),
                                    },
                                ],
                            })(<Input disabled={!createMode} />)
                        ) : (
                            <Typography.Text style={{ fontSize: '1rem', paddingLeft: '0.25rem' }}>
                                {modalData.deputy.userEmail}
                            </Typography.Text>
                        )}
                    </Form.Item>
                    <Form.Item label={translate('modals.editDeputy.deputyEmail')}>
                        {createMode ? (getFieldDecorator('deputyEmail', {
                            rules: [
                                {
                                    type: 'email',
                                    message: translate('formValidationErrors.validateEmail'),
                                },
                                {
                                    required: true,
                                    message: translate('formValidationErrors.required'),
                                },
                            ],
                        })(<Input />)
                        ) : (
                            <Typography.Text style={{ fontSize: '1rem', paddingLeft: '0.25rem' }}>
                                {modalData.deputy.deputyEmail}
                            </Typography.Text>
                        )}
                    </Form.Item>
                    <Form.Item label={translate('modals.editDeputy.deputyTimeRange')}>
                        {getFieldDecorator('endDate', {
                            rules: [
                                {
                                    required: true,
                                    message: translate('formValidationErrors.required'),
                                },
                            ],
                        })(
                            <DatePicker
                                disabledDate={disabledDate}
                                showToday={false}
                                locale={currentLanguage === 'hu' ? hun : eng}
                                format={dateFormat}
                                showTime={{
                                    format: "HH:mm"
                                }}
                                style={{ width: '100%' }}
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button
                            disabled={this.state.isSubmitting}
                            type="primary"
                            htmlType="submit"
                            block
                            style={{ marginTop: '1rem' }}
                        >
                            {translate('modals.editDeputy.save')}
                        </Button>
                    </Form.Item>
                </Form>
            </ModalWrapper>
        );
    }
}

const WrappedEditDeputyModal = Form.create()(EditDeputyModal);

EditDeputyModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    currentLanguage: PropTypes.string,
    form: PropTypes.object.isRequired,
    modalData: PropTypes.object.isRequired,
    onSaveDeputy: PropTypes.func.isRequired,
    onUpdateDeputy: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale),
        currentLanguage: getActiveLanguage(state.locale).code,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onUpdateDeputy: (data) => {
            return dispatch(updateDeputy(data));
        },
        onSaveDeputy: (data) => {
            return dispatch(saveDeputy(data));
        },
        closeModal: () => {
            return dispatch(removeModal());
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedEditDeputyModal);

/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import TooltipButton from '../buttons/TooltipButton';
import authService from '../../services/authService';
import { Divider } from 'antd';
import { confirmationModal } from '../modals/ConfirmationModal';
import { useDispatch, useSelector } from 'react-redux';
import { createNotification, createNotificationShort } from '../../helper';
import { deleteWorkflow } from '../../actions/workflow';
import { getMetadata } from '../../actions/workflow';
import { getTranslate } from 'react-localize-redux';
import { getWaitingToSignByOthers } from '../../actions/container';
import { toggleModal } from '../../actions/modal';
import { META_DATA } from '../../constants/modalNames';

function WorkflowOperations(props) {
    const {
        containsMetadata,
        deleteDisabled,
        isMetadataEnabled,
        isSelectMultipleWorkflow,
        selectedWorkflowGuid,
        selectedWorkflowGuids,
        setSelectedWorkflowGuids,
        workflows
    } = props;

    const dispatch = useDispatch();

    const translate = useSelector((state) => getTranslate(state.locale));

    const title = (!selectedWorkflowGuid && !containsMetadata)  ? translate('workflow.list.operations.disabled') : translate('workflow.list.operations.disabledWithoutMetadata');

    function onRefresh(event) {
        event.preventDefault();
        dispatch(getWaitingToSignByOthers()).catch((error) => {
            createNotification(translate, error.response.data.code, {
                message: translate(`errorCodes.${error.response.data.code}`),
                type: 'error'
            });
        });
    }

    function showMetadata() {
        dispatch(getMetadata(selectedWorkflowGuid))
            .then((response) => {
                dispatch(toggleModal(META_DATA, response));
            })
            .catch((error) => {
                createNotification(translate, error.response.data.code, {
                    message: translate(`errorCodes.${error.response.data.code}`),
                    type: 'error'
                });
            });
    }

    function onDelete(event) {
        event.preventDefault();
        confirmationModal(
            translate,
            () => {
                dispatch(deleteWorkflow(selectedWorkflowGuid))
                    .then(() => {
                        createNotificationShort(translate, {
                            message: translate('workflow.list.operations.delete.success.message'),
                            type: 'success'
                        });
                        dispatch(getWaitingToSignByOthers()).catch((error) => {
                            createNotification(translate, error.response.data.code, {
                                message: translate(`errorCodes.${error.response.data.code}`),
                                type: 'error'
                            });
                        });
                    })
                    .catch((error) => {
                        createNotification(translate, error.response.data.code, {
                            message: translate('workflow.list.operations.delete.error.message'),
                            type: 'error'
                        });
                    });
            },
            translate('confirm.signingProcessDeleteConfirm')
        );
    }

    function onDeleteMultiple(event) {
        event.preventDefault();
        confirmationModal(
            translate,
            () => {
                selectedWorkflowGuids.forEach((guid) => {
                    dispatch(deleteWorkflow(guid))
                        .then(() => {
                            createNotificationShort(translate, {
                                message: translate('workflow.list.operations.delete.success.message'),
                                type: 'success'
                            });
                            dispatch(getWaitingToSignByOthers()).catch((error) => {
                                createNotification(translate, error.response.data.code, {
                                    message: translate(`errorCodes.${error.response.data.code}`),
                                    type: 'error'
                                });
                            });
                        })
                        .catch((error) => {
                            createNotification(translate, error.response.data.code, {
                                message: translate('workflow.list.operations.delete.error.message'),
                                type: 'error'
                            });
                        });
                });
                setSelectedWorkflowGuids([]);
            },

            translate('workflow.list.operations.deleteMultiple.confirm')
        );
    }

    function isMultipleDeleteDisabled() {
        return (
            selectedWorkflowGuids.length === 0 ||
            !workflows
                .filter((w) => selectedWorkflowGuids.includes(w.guid))
                .map((w) => w.createdBy)
                .every((w) => w === authService.getUserEmail())
        );
    }

    return (
        <div style={{ marginBottom: '8px' }}>
            <TooltipButton
                icon={'reload'}
                id={'refresh-signature-processes-button'}
                onClick={(e) => onRefresh(e)}
                title={translate('workflow.list.operations.refresh.button')}
                tooltip={translate('workflow.list.operations.refresh.tooltip')}
                type={'primary'}
            />
            {isMetadataEnabled && (
                <Fragment>
                    <Divider
                        orientation={'center'}
                        type={'vertical'}
                    />
                    <TooltipButton
                        disabled={(!selectedWorkflowGuid && !isSelectMultipleWorkflow) || !containsMetadata}
                        disabledTooltip={title}
                        icon={'database'}
                        id={'signature-process-metadata'}
                        onClick={showMetadata}
                        title={translate('workflow.list.operations.metadata.button')}
                        tooltip={translate('workflow.list.operations.metadata.tooltip')}
                        type={'primary'}
                    />
                </Fragment>
            )}
            <Divider
                orientation={'center'}
                type={'vertical'}
            />
            <TooltipButton
                disabled={deleteDisabled}
                disabledTooltip={translate('workflow.list.operations.delete.disabled')}
                icon={'delete'}
                id={'delete-signature-process-button'}
                onClick={(e) => onDelete(e)}
                title={translate('workflow.list.operations.delete.button')}
                tooltip={translate('workflow.list.operations.delete.tooltip')}
                type={'danger'}
            />
            {isSelectMultipleWorkflow && (
                <Fragment>
                    <Divider
                        orientation={'center'}
                        type={'vertical'}
                    />
                    <TooltipButton
                        disabled={isMultipleDeleteDisabled()}
                        disabledTooltip={translate('workflow.list.operations.deleteMultiple.disabled')}
                        icon={'delete'}
                        id={'delete-multiple-signature-processes-button'}
                        onClick={(e) => onDeleteMultiple(e)}
                        title={translate('workflow.list.operations.deleteMultiple.button')}
                        tooltip={translate('workflow.list.operations.deleteMultiple.tooltip')}
                        type={'danger'}
                    />
                </Fragment>
            )}
        </div>
    );
}

WorkflowOperations.propTypes = {
    containsMetadata: PropTypes.bool.isRequired,
    deleteDisabled: PropTypes.bool.isRequired,
    isMetadataEnabled: PropTypes.bool.isRequired,
    isSelectMultipleWorkflow: PropTypes.bool.isRequired,
    selectedWorkflowGuid: PropTypes.string,
    selectedWorkflowGuids: PropTypes.array,
    setSelectedWorkflowGuids: PropTypes.func.isRequired,
    workflows: PropTypes.array.isRequired
};

export default WorkflowOperations;

import {AutoComplete, Button, Col, Form, Input, Row} from "antd";
import {WORKFLOW_ROLE_APPROVER, WORKFLOW_ROLE_SIGNER} from "../../../constants/strings";
import DeleteButton from "../../buttons/DeleteButton";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {getTranslate} from "react-localize-redux";
import {searchEmails} from "../../../actions/container";

export default function SigningWorkflowParticipant(props) {
    const {
        participant,
        form,
        index,
        showDeleteButton,
        removeParticipant,
        handleParticipantChanged,
        onFrequentlyUsedEmailAddressUpdate
    } = props;
    const [role, setRole] = useState(participant.role);
    const [email, setEmail] = useState(participant.email);
    const [frequentlyUsedEmailAddresses, setFrequentlyUsedEmailAddresses] = useState(props.frequentlyUsedEmailAddresses);
    const translate = useSelector((state) => getTranslate(state.locale));
    const dispatch = useDispatch();

    useEffect(() => {
        onFrequentlyUsedEmailAddressUpdate(frequentlyUsedEmailAddresses)
    }, [frequentlyUsedEmailAddresses, onFrequentlyUsedEmailAddressUpdate]);

    function switchButtonStyle(roleToSet) {
        setRole(roleToSet);
        handleParticipantChanged(index, email, roleToSet);
    }

    function onAutoCompleteChange(event) {
        if (event.length > 2) {
            dispatch(searchEmails(event)).then((response) => {
                setFrequentlyUsedEmailAddresses(response.data);
            });
        }
        if (event.length < 3) {
            setFrequentlyUsedEmailAddresses([]);
        }
        handleEmailChange(event);
    }

    function handleEmailChange(emailToSet) {
        setEmail(emailToSet);
        handleParticipantChanged(index, emailToSet, role);
    }

    return (
        <div
            key={index}>
            <Row>
                {window.config.REACT_APP_WORKFLOW_ROLES_ENABLED &&
                    <div>
                        <Col span={2}>
                            <Button type='primary' icon='user'
                                    ghost={role === WORKFLOW_ROLE_APPROVER}
                                    onClick={() => switchButtonStyle(WORKFLOW_ROLE_SIGNER)} />
                        </Col>
                        <Col span={2}>
                            <Button type='primary' icon='user-add'
                                    ghost={role === WORKFLOW_ROLE_SIGNER}
                                    onClick={() => switchButtonStyle(WORKFLOW_ROLE_APPROVER)} />
                        </Col></div>}
                <Col span={16}>
                    <Form.Item>
                        {form.getFieldDecorator(`emailAddresses[${index}]`, {
                            initialValue: email ? email : null,
                            normalize: (input) => (input !== undefined && input !== null ? input.toLowerCase() : ''),
                            rules: [
                                { required: true, message: translate('form.required') },
                                { type: 'email', message: translate('form.email.invalid') }
                            ],
                            validateTrigger: ['onBlur', 'onChange']
                        })(
                            <AutoComplete
                                autoFocus={index === 0}
                                dataSource={
                                    frequentlyUsedEmailAddresses.length > 10
                                        ? frequentlyUsedEmailAddresses.slice(0, 10)
                                        : frequentlyUsedEmailAddresses
                                }
                                onChange={(e) => onAutoCompleteChange(e)}>
                                <Input
                                    onChange={(e) => handleEmailChange(e.target.value)}
                                    placeholder={translate('modals.sendForSignature.email.placeholder')}
                                />
                            </AutoComplete>
                        )}
                    </Form.Item>
                </Col>
                <Col offset={2} span={2}>
                    {showDeleteButton && <DeleteButton
                        id={'delete-participant-button'}
                        onClick={() => {
                            removeParticipant(index);
                        }}
                        title={translate('modals.sendForSignature.email.remove')}
                    />}
                </Col>
            </Row>
        </div>
    )
}

SigningWorkflowParticipant.propTypes = {
    index: PropTypes.number.isRequired,
    participant: PropTypes.object.isRequired,
    removeParticipant: PropTypes.func.isRequired,
    showDeleteButton: PropTypes.bool.isRequired,
    handleParticipantChanged: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    frequentlyUsedEmailAddresses: PropTypes.array.isRequired,
    onFrequentlyUsedEmailAddressUpdate: PropTypes.func.isRequired
};

import React from 'react';
import PropTypes from "prop-types";
import {getTranslate} from 'react-localize-redux';
import {useSelector} from "react-redux";
import {downloadAttachment} from "../helper";
import {Link} from "react-router-dom";

export default function WorkflowAttachments (props) {
    const translate = useSelector((state) => getTranslate(state.locale));
    const attachments = props.attachments;
    const download = props.download;
    return window.config.REACT_APP_SIGNING_WORKFLOW_FILE_ATTACHMENT_ENABLED &&  attachments && attachments.length > 0 ? (
        <div>
            <b>{translate('titles.files')} </b>
                {attachments.map((attachment, index) => {
                    let separator = attachments.length - 1 === index ? '' : ', ';
                    return download ?
                        <span key={index}><Link to="/" onClick={(e) => {
                            downloadAttachment(attachment.externalId);
                            e.stopPropagation();
                        }}>{attachment.filename}</Link>{separator}</span>
                        :
                        attachment.filename + separator
                })}
        </div>
    ) : '';

};

WorkflowAttachments.propTypes = {
    attachments: PropTypes.array,
    download: PropTypes.bool
};

import CustomAlert from './CustomAlert';
import PropTypes from 'prop-types';
import React from 'react';
import WebPage from './WebPage';
import authService from '../services/authService';
import { connect } from 'react-redux';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { ajax } from '../helper';
import { Spin } from 'antd';
import { fetchAllSignatureProfiles } from '../actions/signatureProfile';

class LoginAD extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            error: null,
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        setTimeout(() => {
            this.loginWithAD();
        }, 3000);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loginWithAD() {
        authService.logout();
        const instance = ajax(true);
        instance.defaults.timeout = 300000;

        const exchangeJwtUrl = '/saml/authenticate'
        const loginAdUrl = '/loginAD'

        function getJwtParameter() {
            return new URL(document.location.href.replace('/#/', '/')).searchParams.get('jwt');
        }

        function postLoginAd(props) {
            let jwt = getJwtParameter();
            if (window.config.REACT_APP_SAML_URL) {
                if (!jwt) {
                    throw new Error(`SAML authentication is enabled, but the 'jwt' parameter is missing from the request query of ${loginAdUrl} URL.\n\nSample URL: ${loginAdUrl}?jwt=...\n`)
                }
                return instance.post(exchangeJwtUrl, jwt, {headers: {'Content-Type': 'text/plain'}})
            } else {
                if (jwt) {
                    console.warn(`SAML authentication is disabled, but a 'jwt' parameter is included the request query of ${loginAdUrl} URL.\n`)
                }
                return instance.post(loginAdUrl, {language: props.currentLanguage})
            }
        }

        postLoginAd(this.props)
            .then((response) => {
                this._isMounted && this.setState({ isLoaded: true, error: null });
                const { data } = response;
                document.execCommand('ClearAuthenticationCache', 'false');
                localStorage.setItem('JWT', data.JWT);
                authService.registeredLogin(data.email);
                authService.setUserProfile(data.userProfile);
                authService.setSignMode(data.userSignMode);
                authService.setRegistrationType('ad');
                this.props.fetchSignatureProfiles();
                const { history } = this.props;
                history.push('/');
            })
            .catch((error) => {
                const { response } = error;
                if (response) {
                    this._isMounted && this.setState({ isLoaded: true, error: response.data.error });
                } else {
                    this._isMounted && this.setState({ isLoaded: true, error: { code: 'HTTP_NO_RESPONSE' } });
                }
            });
    }

    render() {
        const { history, translate } = this.props;
        const { isLoaded, error } = this.state;
        return (
            <WebPage location={history.location}>
                <div style={{ margin: 'auto', maxWidth: 400, textAlign: 'center' }}>
                    {!isLoaded && (
                        <React.Fragment>
                            <h3>{translate('texts.loginWithADInProgress')}</h3>
                            <Spin size={'large'} />
                        </React.Fragment>
                    )}
                    {error && <CustomAlert error={error} />}
                </div>
            </WebPage>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchSignatureProfiles: () => {
            dispatch(fetchAllSignatureProfiles());
        },
    };
};

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale),
        currentLanguage: getActiveLanguage(state.locale).code,
    };
}

LoginAD.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
    fetchSignatureProfiles: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginAD);

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Rnd } from 'react-rnd';
import moment from "moment";
import {useSelector} from "react-redux";
import {getTranslate} from "react-localize-redux";

const SignImage = ({ props, backgroundImage }) => {
    const [fontHeight, setFontHeight] = useState(0);
    const translate = useSelector((state) => getTranslate(state.locale));
    const minWidth = props.minConstraints.width;
    const maxWidth = props.maxConstraints.width;
    const minHeight = props.minConstraints.height;
    const maxHeight = props.maxConstraints.height;
    const signatureTextProps = props.signatureTextProps;

    const measuredRef = useCallback((node) => {
        if (node !== null) {
            setFontHeight(node.getBoundingClientRect().height);
        }
    }, []);

    const titleStyle = () => {
        return {
            marginTop: props.datePosition
                ? Math.round(signatureTextProps.nameWithDateTopMarginMultiplier * props.height)
                : Math.round(signatureTextProps.nameWithoutDateTopMarginMultiplier * props.height),
            marginLeft: Math.round(signatureTextProps.leftMarginMultiplier * props.width),
            fontSize: props.fontSize,
            fontFamily: 'CustomFont',
            whiteSpace: 'nowrap',
            letterSpacing: '1px',
        };
    };

    const dateStyle = () => {
        return {
            marginTop: props.namePosition ? null : Math.round(signatureTextProps.dateTextTopMarginMultiplier * props.height - fontHeight),
            marginLeft: Math.round(signatureTextProps.leftMarginMultiplier * props.width),
            fontSize: props.fontSize,
            fontFamily: 'CustomFont',
            whiteSpace: 'nowrap',
            letterSpacing: '1px',
        };
    };

    const backgroundStyle = () => {
        return {
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundImage: `url("${backgroundImage}")`,
        };
    };

    return (
        <Rnd
            onResize={props.onResize}
            onDragStop={props.onDragStop}
            enableResizing={props.isResizingAndDraggingDisabled ? false : { bottomRight: true }}
            lockAspectRatio={true}
            bounds={'parent'}
            size={{ width: props.width, height: props.height }}
            position={{ x: props.deltaPosition.x, y: props.deltaPosition.y }}
            minWidth={ minWidth }
            maxWidth={ maxWidth }
            minHeight={ minHeight }
            maxHeight={ maxHeight }
            style={backgroundStyle()}
            disableDragging={props.isResizingAndDraggingDisabled}
        >
            <div style={{ overflow: 'hidden', height: props.height }}>
                <SignImageBorder width={Math.max(minWidth, props.width)} height={Math.max(minHeight, props.height)} />
                <ResizeCorner />
                {props.namePosition ? (
                    <div style={titleStyle()} ref={measuredRef}>
                        {translate('user.name')}
                    </div>
                ) : null}
                {props.datePosition ? (
                    <div style={dateStyle()} ref={measuredRef}>
                        {dateString()}
                    </div>
                ) : null}
            </div>
        </Rnd>
    );
};

SignImage.propTypes = {
    minConstraints: PropTypes.object,
    maxConstraints: PropTypes.object,
    height: PropTypes.number,
    width: PropTypes.number,
    pdfSizeX: PropTypes.number,
    deltaPosition: PropTypes.object,
    onDragStop: PropTypes.func,
    onResize: PropTypes.func,
    backgroundImage: PropTypes.string,
    props: PropTypes.object,
    datePosition: PropTypes.number,
    fontSize: PropTypes.number,
    namePosition: PropTypes.number,
    isResizingAndDraggingDisabled: PropTypes.bool,
    signatureTextProps: PropTypes.object
};

const dateString = () => {
    const currentDate = new Date();
    return moment(currentDate).format('YYYY-MM-DD HH:mm:ss Z');
};

const SignImageBorder = ({ width, height }) => {
    const signImageBorderStyle = {
        position: 'absolute',
        width: width + 10,
        height: height + 10,
        top: '-5px',
        left: '-5px',
        border: '4px dashed #7f96ed',
    };
    return <div style={signImageBorderStyle}></div>;
};

SignImageBorder.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
};

const ResizeCorner = () => {
    return <span className="resize-corner"></span>;
};

export default SignImage;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import QRCode from 'react-qr-code';
import { Modal } from 'antd';
import { removeModal } from '../../actions/modal';
import { ajax, createNotificationShort } from '../../helper';

class MobileQrCodeIdentificationModal extends React.Component {
    constructor(props) {
        super(props);

        this._isMounted = false;
        this.abortController = undefined;
        this.pollingTimeout = undefined;
    }

    componentDidMount() {
        this._isMounted = true;

        this.schedulePolling();
    }

    componentWillUnmount() {
        this.stopPolling();

        this._isMounted = false;
    }

    schedulePolling = () => {
        const { pollingInterval } = this.props;

        this.stopPolling();

        this.pollingTimeout = setTimeout(this.onPoll, pollingInterval);
    }

    stopPolling = () => {
        if (this.abortController) {
            this.abortController.abort();

            this.abortController = undefined;
        }

        if (this.pollingTimeout) {
            clearTimeout(this.pollingTimeout);

            this.pollingTimeout = undefined;
        }
    }

    onPoll = () => {
        const { onSuccess, onError, removeModal, setUserOid, setUserRefreshToken, title, token, translate } = this.props;

        this.abortController = new AbortController();

        ajax(false, this.abortController)
            .post(`/pollMobileQrCodeIdentification/${token}`, {
                setUserOid: setUserOid,
                setUserRefreshToken: setUserRefreshToken
            })
            .then((response) => {
                if (this._isMounted) {
                    if (response.status === 202) {
                        this.schedulePolling();
                    } else {
                        removeModal();

                        if (onSuccess) {
                            onSuccess();
                        }
                    }
                }
            })
            .catch((error) => {
                if (this._isMounted && error.code !== 'ERR_CANCELED') {
                    removeModal();

                    createNotificationShort(translate, {
                        type: 'error',
                        message: title,
                        description: translate('notifications.mobileQrCodeIdentification.description')
                    });

                    if (onError) {
                        onError(error);
                    }
                }
            });
    }

    onCancel = () => {
        const { onCancel, removeModal } = this.props;

        this.stopPolling();

        removeModal();

        if (onCancel) {
            onCancel();
        }
    }

    render() {
        const { message, qrCodeContent, title, translate } = this.props;

        return (
            <Modal
                    bodyStyle = {{ textAlign: 'center' }}
                    cancelText = { translate('buttons.cancel') }
                    closable = { true }
                    destroyOnClose = { true }
                    okButtonProps = {{ style: { display: 'none' } }}
                    onCancel = { this.onCancel }
                    title = { title }
                    visible = { true }
                    width = { 700 }>
                <p>
                    <QRCode value = { qrCodeContent } size = { 150 } />
                </p>

                {message && (
                    <p>{ message }</p>
                )}
            </Modal>
        );
    }
}

MobileQrCodeIdentificationModal.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.object,
    qrCodeContent: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    pollingInterval: PropTypes.number,
    setUserOid: PropTypes.bool.isRequired,
    setUserRefreshToken: PropTypes.bool.isRequired,

    onSuccess: PropTypes.func,
    onCancel: PropTypes.func,
    onError: PropTypes.func,

    removeModal: PropTypes.func,
    translate: PropTypes.func
};

MobileQrCodeIdentificationModal.defaultProps = {
    pollingInterval: 1000
}

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        removeModal: () => dispatch(removeModal())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileQrCodeIdentificationModal);

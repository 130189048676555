import {Checkbox, Col, Form, Input, Row} from "antd";
import {WORKFLOW_METADATA_KEY_MAX_LENGTH, WORKFLOW_METADATA_VALUE_MAX_LENGTH} from "../../../constants/config";
import DeleteButton from "../../buttons/DeleteButton";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {getTranslate} from "react-localize-redux";
import PropTypes from "prop-types";
import {isBlank} from "../../../utilities/stringUtilities";

export default function SigningWorkflowMetadata(props) {
    const {
        index,
        form,
        metadata,
        removeMetadata,
        handleMetadataKeyChanged,
        handleMetadataValueChanged,
        handleMetadataRequiredChanged} = props;
    const [key, setKey] = useState(metadata.key);
    const [value, setValue] = useState(metadata.value);
    const [isMandatory, setMandatory] = useState(metadata.mandatory);
    const translate = useSelector((state) => getTranslate(state.locale));

    function metadataKeyChange(newKey) {
        setKey(newKey);
        handleMetadataKeyChanged(index, newKey);
    }

    function metadataValueChange(newValue) {
        setValue(newValue);
        handleMetadataValueChanged(index, newValue);
    }

    function metadataMandatoryChange() {
        const newMandatory = !isMandatory;
        setMandatory(newMandatory);
        handleMetadataRequiredChanged(index, newMandatory);
    }

    const validateMetadataKeyConflicts = (rule, value, _) => {
        if (!isBlank(value)) {
            const formField = rule.field;
            const message = rule.message || 'This key is already defined.';

            let otherFormValue = ''

            for (let i = 0; otherFormValue !== undefined; ++i) {
                const otherFormField = "metadataKey[" + i + "]";

                otherFormValue = form.getFieldValue(otherFormField);

                if (otherFormField !== formField && otherFormValue === value) {
                    return Promise.reject(new Error(message));
                }
            }
        }

        return Promise.resolve();
    };

    return (
        <span
            key={index}
            style={{ paddingTop: 10 }}>
            <Row style={{ paddingTop: 10 }}>
                <Col span={9}>
                    {index === 0 && <div>{translate('modals.sendForSignature.metadata.key')}</div>}
                    <Form.Item
                        style={{
                            marginRight: '16px',
                            width: '200px'
                        }}>
                    {form.getFieldDecorator(`metadataKey[${index}]`, {
                        initialValue: key ? key : null,
                        rules: [
                            {
                                required: true,
                                message: translate('form.required')
                            },
                            {
                                whitespace: true,
                                message: translate('form.whitespace')
                            },
                            {
                                max: WORKFLOW_METADATA_KEY_MAX_LENGTH,
                                message: translate('form.text.tooLong', { length: WORKFLOW_METADATA_KEY_MAX_LENGTH })
                            },
                            {
                                validator: validateMetadataKeyConflicts,
                                message: translate('form.metadataKeyConflict')
                            }
                        ]
                    })(<Input placeholder={translate('workflow.metadata.keyPlaceholder')}
                              onChange={(e) => metadataKeyChange(e.target.value)} />)}
                    </Form.Item>
                </Col>
                <Col span={9} offset={2}>
                    {index === 0 && <div>{translate('modals.sendForSignature.metadata.value')}</div>}
                    <Form.Item
                        style={{
                            marginRight: '16px',
                            width: '200px'
                        }}>
                    {form.getFieldDecorator(`metadataValue[${index}]`, {
                        initialValue: value ? value : null,
                        rules: [
                            {
                                max: WORKFLOW_METADATA_VALUE_MAX_LENGTH,
                                message: translate('form.text.tooLong', { length: WORKFLOW_METADATA_VALUE_MAX_LENGTH })
                            }
                        ]
                    })(<Input placeholder={translate('workflow.metadata.valuePlaceholder')}
                              onChange={(e) => metadataValueChange(e.target.value)} />)}
                    </Form.Item>
                </Col>
                <Col span={2} offset={2}>
                    {index === 0 && <br />}
                    <Form.Item
                        style={{
                            width: '40px'
                        }}>
                        <DeleteButton
                            id={'delete-metadata-button'}
                            onClick={() => removeMetadata(index)}
                            title={translate('modals.sendForSignature.metadata.remove')}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Checkbox onChange={() => metadataMandatoryChange()}
                          checked={isMandatory}
                          style={{ textAlign: 'left' }}>
                    {translate('workflow.metadata.mandatory')}
                </Checkbox>
            </Row>
        </span>
    );
}

SigningWorkflowMetadata.propTypes = {
    index: PropTypes.number.isRequired,
    form: PropTypes.object.isRequired,
    metadata: PropTypes.object.isRequired,
    handleMetadataKeyChanged: PropTypes.func.isRequired,
    handleMetadataValueChanged: PropTypes.func.isRequired,
    handleMetadataRequiredChanged: PropTypes.func.isRequired,
    removeMetadata: PropTypes.func.isRequired
};

import {WORKFLOW_MESSAGE_MAX_LENGTH} from "../../../constants/config";
import {Button, Col, Form, Icon, Input, Row} from "antd";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {getTranslate} from "react-localize-redux";
import {isBlank} from "../../../utilities/stringUtilities";

export default function SigningWorkflowComment(props) {
    const {form, handleCommentChanged} = props;
    const [showComment, setShowComment] = useState(false);
    const [commentValue, setCommentValue] = useState(props.commentValue);
    const translate = useSelector((state) => getTranslate(state.locale));

    useEffect(() => {
        !isBlank(commentValue) && setShowComment(true)
    }, [commentValue]);

    function showCommentElement() {
        setShowComment(!showComment);
    }

    function setComment(newCommentValue) {
        setCommentValue(newCommentValue);
        handleCommentChanged(newCommentValue);
    }

    return (
        <Form.Item>
            {form.getFieldDecorator('comment', {
                rules: [
                    {
                        max: WORKFLOW_MESSAGE_MAX_LENGTH,
                        message: translate('form.text.tooLong', {
                            length: WORKFLOW_MESSAGE_MAX_LENGTH
                        })
                    }
                ]
            })(
                <div>
                    <Row type='flex'>
                        <Col span={8}>
                            <b>{translate(`modals.sendForSignature.comment.title`)}</b>
                        </Col>
                        <Col span={8} offset={8}>
                            <Button block type='dashed' onClick={showCommentElement}>
                                <Icon type='plus'/>
                                {translate('waitingtosignbyothers.details.comment')}</Button>
                        </Col>
                    </Row>
                    {showComment && <Row style={{paddingTop: 10}}>
                        <Input.TextArea
                            id={'comment'}
                            maxLength={WORKFLOW_MESSAGE_MAX_LENGTH}
                            placeholder={translate('modals.sendForSignature.comment.placeholder')}
                            rows={4}
                            value={commentValue}
                            onChange={(e) => {
                                setComment(e.target.value);
                            }}
                        />
                        {commentValue.length >= WORKFLOW_MESSAGE_MAX_LENGTH && (
                            <p style={{color: 'red'}}>{translate('reason.length')}</p>
                        )}
                    </Row>}
                </div>
            )}
        </Form.Item>);
}

SigningWorkflowComment.propTypes = {
    form: PropTypes.object.isRequired,
    commentValue: PropTypes.string.isRequired,
    handleCommentChanged: PropTypes.func.isRequired
};

import React from 'react';
import WebPage from './WebPage';
import {getTranslate} from 'react-localize-redux/lib/index';
import {connect} from 'react-redux';
import authService from '../services/authService';
import PropTypes from 'prop-types';
import {storeFileId} from '../actions/container';
import {Icon, Spin} from 'antd';
import {getApiUrl} from "../helper";

export class DownloadFile extends React.Component {
    componentDidMount() {
        if (authService.isRegistered()) {
            if (this.props.match.params.type === 'eacknowledgement') {
                window.open(
                    getApiUrl() + `/eacknowledgement/${this.props.match.params.id}/download`,
                    '_self'
                );
            } else {
                window.open(
                    getApiUrl() + `/download/${this.props.match.params.id}`,
                    '_self'
                );
            }
            this.props.history.push('/');
        } else {
            this.props.storeFileId(this.props.match.params.id);
            this.props.history.push(`/login`);
        }
    }

    render() {
        const { history } = this.props;
        return (
            <WebPage location={history.location}>
                <div style={{ textAlign: 'center', height: 39 }}>
                    <Spin indicator={<Icon type="loading" style={{ fontSize: 32 }} spin />} />
                </div>
            </WebPage>
        );
    }
}

DownloadFile.propTypes = {
    history: PropTypes.object.isRequired,
    storeFileId: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        storeFileId: (id) => {
            return dispatch(storeFileId(id));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadFile);

import {useSelector} from "react-redux";
import {getTranslate} from "react-localize-redux";
import WebPage from "./WebPage";
import {Button, Col, Form, Icon, Row} from "antd";
import React from "react";
import {getHistory} from "../helper";
import Box from "./Box";
import DocumentIcon from "../images/documents.png";

export const InfoPage = (props) => {
    const translate = useSelector((state) => getTranslate(state.locale));
    const state = props.location.state;

    return (
        <WebPage location={props.history.location}>
            {state && <Box backgroundColor={'white'}>
                {state.image && <Row
                    type='flex'
                    justify='center'
                    align='middle'>
                    <Col>
                        <img
                            alt={''} src={state.image} width={250}
                        />
                    </Col>
                </Row>}

                {state.title && <Row
                    type='flex'
                    justify='center'
                    align='middle'>
                    <Col>
                        <h3 style={{marginTop: 10}}><b>{state.title}</b></h3>
                    </Col>
                </Row>}

                {state.message && <Row
                    type='flex'
                    justify='center'
                    align='middle'>
                    <Col>
                        <h3>{state.message}</h3>
                    </Col>
                </Row>}

                <Row
                    type='flex'
                    justify='center'
                    align='middle'
                    style={{marginTop: 30, marginBottom: 100}}>
                    <Col>
                        <Button
                            htmlType={'submit'}
                            id={'submitPasswordChange'}
                            type={'primary'}
                            onClick={() => {
                                getHistory().replace('/');
                            }}
                        >
                            <Icon component={() => (<img alt={''} style={{width: 24}} src={DocumentIcon} />)}/>
                            <b style={{verticalAlign: "bottom", margin: 5}}>{translate('home.documents.documents')}</b>
                        </Button>
                    </Col>
                </Row>
            </Box>}
        </WebPage>
    );
};

export const WrappedInfoPage = Form.create()(InfoPage);
export default WrappedInfoPage;

import React from 'react';
import PropTypes from 'prop-types';
import ModalWrapper from './ModalWrapper';
import PdfPreview from '../document/PdfPreview';
import { Button, Popover } from 'antd';

class SignaturePositionModal extends React.Component {
    render() {
        const { modalData, translate } = this.props;
        const extendedWidth = 900;
        return (
            <ModalWrapper
                {...this.props}
                width={extendedWidth}
                height={modalData.imageHeight}
                maskClosable={false}
                bodyStyle={{paddingTop: '10px'}}
                style={{top: '10px'}}
                title={
                    <Popover
                        content={<p style={{ maxWidth: '500px' }}>{translate('signaturePositionText')}</p>}
                        title={translate('help')}
                    >
                        {translate('previewTitle')}
                        <Button
                            size="small"
                            type={'primary'}
                            shape="circle"
                            icon="question"
                            style={{ marginLeft: 10 }}
                        />
                    </Popover>
                }
            >
                <PdfPreview
                    previewContainerList={modalData.previewContainerList}
                    container={modalData.container}
                    setSignaturePosition={true}
                    pdfSizeX={modalData.imageWidth}
                    pdfSizeY={modalData.imageHeight}
                    signModalData={modalData.signModalData}
                    cegjegyzekSzam={modalData.cegjegyzekSzam}
                    attributeRole={modalData.attributeRole}
                    certificate={modalData.certificate}
                    signImagePreviewMinWidth={window.config.REACT_APP_VISIBLE_SIGNATURE_CONSTRAINTS.minWidth}
                    signImagePreviewMaxWidth={window.config.REACT_APP_VISIBLE_SIGNATURE_CONSTRAINTS.maxWidth}
                    signatureTextProps={window.config.REACT_APP_VISIBLE_SIGNATURE_CONSTRAINTS.signatureTextProps}
                />
            </ModalWrapper>
        );
    }
}

SignaturePositionModal.propTypes = {
    modalData: PropTypes.object,
    translate: PropTypes.func,
};

export default SignaturePositionModal;
